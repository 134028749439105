
import {BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import './App.css';
import Home from './pages/Home';
import Account from './pages/Account';
import Error from './pages/Error';
import AppNavbar from './Components/Navbar';
import Layout from './Components/Layout';
import Login from './pages/Login';
import NewCreateStudy from "./pages/create-study/CreateStudy";
import { useState } from "react";
import ParticipantFacing from "./pages/participant-facing/ParticipantFacing";  
import { ShowMainNav, ShowLoader } from "./Components/Context";
import GroupParticipant from "./pages/participant-facing/GroupParticipant";
import ResultsDashboard from "./pages/results/Dashboard";
import UpdateScreener from "./pages/results/UpdateScreener";
import InviteStudy from "./pages/participant-facing/InviteStudy";

var apiVersion = process.env.REACT_APP_API_VERSION;
if(apiVersion===undefined || apiVersion === ""){
    apiVersion = "/api/v1/";
}
const appUrl = process.env.REACT_APP_API_URL+apiVersion+"profile";

function App () {
  const [loading, setLoading] = useState(true);
  const [showNav, setShowNav] = useState(true);
  const [loader, setLoader] = useState(false);

  const cookies = new Cookies();
  var loginUser = false;
  var acctkn = cookies.get("neurojwt");
  var timer = 30000;


  try {
    if(window.location.href.indexOf("access_token")>-1 && window.location.href.split("access_token=")[1] !== ""){
      acctkn = window.location.href.split("access_token=")[1];
      cookies.set('neurojwt', acctkn, {
        path:"/", 
        expires:  (new Date(Date.now()+ 86400*1000))
      });
      window.location.href = "/";
    }
  } catch (error) {
    console.log(error)
  }


   const verifyToken = async () => {
    if(acctkn !== undefined){
      if(cookies.get("neurojwt")!==undefined){
        acctkn = cookies.get("neurojwt");
      }
      try {
        const response = await fetch(appUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${acctkn}`
          }
        });
        const data = await response.json();
        if(data.status === false){
          cookies.remove("neurojwt");
          window.location.href = "/login?token_error=Invalid-Access-Token";
        }else{
          if(loading){
            setLoading(false);
          }
          setTimeout(() => {
            verifyToken();
          }, timer);
          timer = timer + 30000;
          if(timer>=240000){
            timer = 30000;
          }
        }
      } catch (error) {
        console.log("Home Api Fetch Error "+error);
      }
    }else{
      if(loading){
        setLoading(false);
      }
    }
  }

  if(loading){
    if(window.location.pathname.indexOf("/study/")>-1){
      setLoading(false);
    }else{
      verifyToken();
    }
  }

  if(cookies.get("neurojwt") !== undefined){
    loginUser = true;
  }
 


  return (
    <ShowMainNav.Provider value={{showNav,setShowNav}}>
      <ShowLoader.Provider value={{loader,setLoader}} >
        {loading?<div className="loading"></div>:
        <div className='main_app_cstm'>
          <Router>
            <AppNavbar />
            {loader?
            <div className="cstm_loader">
              <div className="lds-dual-ring"></div>
            </div>:
            <Layout className='layout_top_main_cstm'>
              <Routes>
                <Route exact path="/" element={(loginUser )?<Home />:<Navigate to="/login" />} />
                {/* <Route exact path="/account" element={(loginUser)?<Account />:<Navigate to="/login" />} /> */}
                {/* <Route exact path="/studies" element={(loginUser)?<NewCreateStudy />:<Navigate to="/login" />} /> */}
                <Route exact path="/studies" element={(loginUser)?<ResultsDashboard />:<Navigate to="/login" />} />
                <Route exact path="/studies/:urlStudyID" element={(loginUser)?<ResultsDashboard />:<Navigate to="/login" />} />
                <Route exact path="/create-study" element={(loginUser)?<NewCreateStudy />:<Navigate to="/login" />} />
                {/* <Route exact path="/create-study?studyId&screenerId&surveyId" element={(loginUser)?<NewCreateStudy />:<Navigate to="/login" />} /> */}
                {/* <Route exact path="/study/:studyID/:userID" element={<ParticipantFacing />} /> */}
                <Route exact path="/study/:studyID/" element={<ParticipantFacing />} />
                {/* <Route exact path="/study/:studyID" element={<InviteStudy />} /> */}
                <Route exact path="/thirdparty/:groupID" element={<GroupParticipant />} />
                {/* <Route exact path="/results" element={(loginUser)?<ResultsDashboard />:<Navigate to="/login" />} /> */}
                <Route exact path="/screener/:studyID" element={(loginUser)?<UpdateScreener />:<Navigate to="/login" />} />
                <Route exact path="/login" element={<Login />} />
                <Route path="*" element={<Error />} />
              </Routes>
            </Layout>
            }
          </Router>
        </div>
        }
      </ShowLoader.Provider>
    </ShowMainNav.Provider>
  )
}


export default App;
