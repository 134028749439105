
import nextBtn from '../assets/nextBtn.png';
import { Container, Button, Image, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import Cookies from 'universal-cookie';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var appUrl = process.env.REACT_APP_API_URL + apiVersion + "homepage/studies";

export default function Home() {

    const [studiesData, setStdiesData] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [errmsg, setErrMsg] = useState('Error in gettings studies');


    useEffect(() => {
        const cookies = new Cookies();
        Axios.get(
            appUrl,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${cookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    setStdiesData(response.data.data);
                } else {
                    setErrMsg(response.data.message);
                    setShowToast(true);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/login";
                }
                setErrMsg(error.response.data.message);
                setShowToast(true);
            });
    }, [])

    let navigate = useNavigate();

    const errAlert = <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
        <p>{errmsg}</p>
    </Alert>;

    return (
        <div className="home_cstm">
            {errAlert}
            <Container>
                <Row className='cstm_home_App'>
                    <Col className="left-text-con">
                        <h1 className="left-text">nPLICIT <span className="left-text-tm">TM</span></h1>
                        <p className="left-text1">Project Manager</p>
                        <p className="below-text">{"Welcome to your resource for building, monitoring, and managing your Neuro-Insight Implicit Association Tests — nPlicit™."}</p>
                    </Col>
                    <Col>
                        {studiesData && studiesData?.map((item, index) =>
                            <Container className="left-study" key={item._id} >
                                <button onClick={() => navigate("/studies/" + item._id)} className='left-study-main-btn' style={{ display: "flex", flexDirection: "row", alignItems: "center", borderWidth: 0, backgroundColor: "transparent", width: "100%", paddingLeft: 18, paddingRight: 18, }}>
                                    <div className='study-text-dot-con'>
                                        <p className="study-text">{item?.studyName?.split(" ").length > 2 ? (item?.studyName?.split(" ").splice(0, 3).join(" ")) + "..." : (item?.studyName)}</p>
                                        <div className={(item.timeLeft.toLowerCase()) === "finished" ? "green-dot" : "yellow-dot"} />
                                    </div>
                                    <p className="complete-response">{"Completed: "}<span className="states">{item.completedparticipant + "/" + item.totalParticipant}</span></p>
                                    <p className={(item.timeLeft.toLowerCase()) === "finished" ? "finished-style" : "time-style"}>{item.timeLeft}</p>
                                    <div className="img-div">
                                        <button onClick={() => navigate("/studies/" + item._id)} href={"/studies/" + item._id} ><Image src={nextBtn} className="next-img" alt="logo" /></button>
                                    </div>
                                </button>
                            </Container>
                        )}
                    </Col>
                </Row>
                <Container className="line-style" />
                <Container className="below-con">
                    <p className="new-study">{"New Study"}</p>
                </Container>
                <Container className="below-con1">
                    <p className="below-text-short">Click here to begin designing a new nPlicit™ study. </p>
                </Container>
                <Container className="btn-con">
                    <Button onClick={() => {
                        localStorage.removeItem("createStudyId");
                        localStorage.removeItem("createScreenerId");
                        localStorage.removeItem("createSurvayId");
                        localStorage.removeItem("studyTab");
                        navigate("/create-study")
                    }}>
                        <p className="btn-text-style">CREATE STUDY</p>
                    </Button>
                </Container>
            </Container>
        </div>
    )
}