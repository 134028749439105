
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts"

const ScreenerOption = {
  title: "User dropout screener question data",
  legend: { position: "none" },
  hAxis: {
    title: 'Questions',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  },
  vAxis: {
    title: 'Left Users',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  }
};

const StudyOption = {
  title: "User dropout study question data",
  legend: { position: "none" },
  hAxis: {
    title: 'Tab Index',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  },
  vAxis: {
    title: 'Left Users',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  }
};

const SurveyOption = {
  title: "User dropout survey question data",
  legend: { position: "none" },
  hAxis: {
    title: 'Questions',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  },
  vAxis: {
    title: 'Left Users',
    textStyle: {
      fontSize: 12 // Adjust the font size as needed
    }
  }
};


function Report({ screenerLeftUsers, studyLeftUsers, surveyLeftUsers, studyDetails }) {
  
  return (
    <>
      <div className='resultstudyDataMainCon result_lft_usrs'>

        <div className='resultstudyDataRightCon'>
          <div className='resultScreenerCompletionStatus'>
            <p className='ScreenerCompletionTxtStyResult'>Screener</p>
            <div className='resultScreenerCompletionGraphStatsCon'>
              <Chart
                chartType="ColumnChart"
                data={screenerLeftUsers}
                options={ScreenerOption}
                width="100%"
                height="400px"
              />
            </div>
          </div>
        </div>

        <div className='resultstudyDataRightCon'>
          <div className='resultScreenerCompletionStatus'>
            <p className='ScreenerCompletionTxtStyResult'>Study</p>
            <div className='resultScreenerCompletionGraphStatsCon'>
              <Chart
                chartType="ColumnChart"
                data={studyLeftUsers}
                options={StudyOption}
                width="100%"
                height="400px"
              />
            </div>
          </div>
        </div>

        {studyDetails.isIncludePostSurvey?
        <div className='resultstudyDataRightCon'>
          <div className='resultScreenerCompletionStatus'>
            <p className='ScreenerCompletionTxtStyResult'>Survey</p>
            <div className='resultScreenerCompletionGraphStatsCon'>
              <Chart
                chartType="ColumnChart"
                data={surveyLeftUsers}
                options={SurveyOption}
                width="100%"
                height="400px"
              />
            </div>
          </div>
        </div>
        :""}

      </div>

    </>
  )
}
export default Report

