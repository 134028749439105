
import React, { useState, useEffect, useRef } from 'react';

import pataImg from '../../assets/pataImg.png'
import threePati from '../../assets/threePati.png'
import tickIcon from '../../assets/tickIcon.png'
import crossIcon from '../../assets/crossIcon.png'
import waitingIcon from '../../assets/waitingIcon.png'
import Image from 'react-bootstrap/Image'

const practicingData = [
  { id: 1, type: "image", thirdImg: pataImg, answer: 's' },
  { id: 2, type: "image", thirdImg: threePati, answer: 'k' },
  { id: 3, type: "image", thirdImg: threePati, answer: 'k' },
  { id: 4, type: "image", thirdImg: pataImg, answer: 's' },
  { id: 5, type: "image", thirdImg: threePati, answer: 'k' },
  { id: 6, type: "image", thirdImg: pataImg, answer: 's' },
  { id: 7, type: "image", thirdImg: pataImg, answer: 's' },
  { id: 8, type: "image", thirdImg: threePati, answer: 'k' },
  { id: 9, type: "image", thirdImg: threePati, answer: 'k' },
  { id: 10, type: "image", thirdImg: pataImg, answer: 's' },
  { id: 11, type: "image", thirdImg: pataImg, answer: 's' },
]

const Switchtime = 500;
const Switchtime1 = 800;

var selectedIdx = 0
var studyCount = 1

var currentIndex = 0
let timer;


function PracticeStudy2({ setCurrentStudyTab }) {

  const [thirdShow, setThirdShow] = useState(false)
  const [targetShow, setTargetShow] = useState(false)
  const [isUserAnswer, setIsUserAnswer] = useState(false)
  const [UserAnswer, setUserAnswer] = useState('')
  const [showAnsers, setShowAnsers] = useState(false)
  const [userPressedKey, setUserPressedKey] = useState('')
  const [showTopImgs, setShowTopImgs] = useState(true)

  // const [studyCount, setStudyCount] = useState(0);

  const dataFetchedRef1 = useRef(false);
  const userPressedKeyRef = useRef(userPressedKey);

  const _setUserPressedKey = data => {
    userPressedKeyRef.current = data;
    setUserPressedKey(data);
  };


  const handleKeyDown = (event) => {
    _setUserPressedKey(event.key)
  }

  useEffect(() => {
    if (userPressedKey === "s") {
      if (targetShow && !showAnsers) {
        setIsUserAnswer(true)
        setUserAnswer("s")

        clearTimeout(timer)
        setShowAnsers(true)

         setTimeout(() => {
          setShowTopImgs(false)
          setTargetShow(false)
          setShowAnsers(false)
        }, 1000)

        if (currentIndex === (practicingData.length - 1)) {
          setCurrentStudyTab(5)
        } else {
          timer = setTimeout(() => {
            currentIndex = currentIndex + 1
            timeFunction()
          }, 2000)
        }

      }
    } else if (userPressedKey === "k") {
      if (targetShow && !showAnsers) {
        setIsUserAnswer(true)
        setUserAnswer("k")

        clearTimeout(timer)
        setShowAnsers(true)

         setTimeout(() => {
          setShowTopImgs(false)
          setTargetShow(false)
          setShowAnsers(false)
        }, 1000)

        if (currentIndex === (practicingData.length - 1)) {
          setCurrentStudyTab(5)
        } else {
          timer = setTimeout(() => {
            currentIndex = currentIndex + 1
            timeFunction()
          }, 2000)
        }

      }
    }
    setUserPressedKey("")
  }, [userPressedKey])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
  }, [])

  useEffect(() => {
    if (dataFetchedRef1.current) return;
    dataFetchedRef1.current = true;
    timeFunction()
  }, [])


  const timeFunction = () => {

    setIsUserAnswer(false)
    setShowTopImgs(true)

    timer = setTimeout(() => {
      setTargetShow(true)
    }, 1000)

    timer = setTimeout(() => {
      setShowAnsers(true)
    }, 1800)

    timer = setTimeout(() => {
      setShowTopImgs(false)
      setTargetShow(false)
      setShowAnsers(false)
    }, 2800)

    if (currentIndex === (practicingData.length - 1)) {
      setCurrentStudyTab(5)
    } else {
      timer = setTimeout(() => {
        currentIndex = currentIndex + 1
        timeFunction()
      }, 3800)

    }

  }


  return (
    <div className='study2MainContainer'>
      <div className='topBarPhase1Con'>
        <div className='topBarPhase1'>
          <div className='topBarActivePhase1' style={{ width: (currentIndex * 11) + "%" }} />
          {/* <div className='topBarActivePhase1' style={{ width: (studyCount * 10) + "%" }} /> */}
          {/* <div className='topBarActivePhase1' style={{ width: selectedIdx === 0 ? "50%" : "100%" }} /> */}
        </div>
      </div>

      <div className='study2BoxConPhase1'>
        <div className='study2RightConPhase1'>
          <div className='study2upperImgCon'>
            <div className='study2SandImgCon'>
              {showTopImgs ? <Image src={pataImg} className="study2leftSideImg" alt="logo" /> : null}
            </div>
            <div className='study2SandImgCon'>
              {showTopImgs ? <Image src={threePati} className="study2RightSideImg" alt="logo" /> : null}
            </div>
          </div>
          <div className='study2MidImgCon'>
            <div className='study2MidImgTickCon'>
              {showAnsers ?
                isUserAnswer ?
                  <Image src={UserAnswer === practicingData[currentIndex]?.answer ? tickIcon : crossIcon} className="study2tickIconImg" alt="logo" />
                  :
                  <Image src={waitingIcon} className="study2tickIconImg" alt="logo" /> : null}

              {targetShow ? <Image src={practicingData[currentIndex]?.thirdImg} className="study2leftSideImg" alt="logo" /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export default PracticeStudy2

