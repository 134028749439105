import {Link, useMatch, useResolvedPath,useLocation } from 'react-router-dom';
import {Container,Navbar,Nav} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { ShowMainNav } from './Context';
import { useContext } from 'react';

function AppNavbar() {

    const {showNav,setShowNav} = useContext(ShowMainNav);
    
    var loginUser = false;
    const cookies = new Cookies();
    const location = useLocation();
    var pgName = "pg"+location.pathname.replaceAll("/","_");
    if(cookies.get("neurojwt") !== undefined){
      loginUser = true;
    }
    

  const handleLogout = (event) => {
    event.preventDefault();
    const cookies = new Cookies();
    cookies.remove("neurojwt");
    window.location.href = "/";
  }

  return (
    <Navbar className="navbar_cstm" collapseOnSelect expand="lg" bg="black" variant="dark">
      {(showNav && loginUser)?
      <Container>
        <Navbar.Brand href="/">
          <img
              src={require('../assets/logo.png')}
              width="176"
              height="59"
              className="d-inline-block align-top"
              alt="Neuro Insight logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {loginUser && <LinkComponent href="/" >Home</LinkComponent>}
              {loginUser && <LinkComponent href="/studies" >Studies</LinkComponent> }
              {/* {loginUser && <LinkComponent href="/account" >My Account</LinkComponent> } */}
            </Nav>
            <Nav>
              {!loginUser && <LinkComponent href="/login" >SIGNIN</LinkComponent>}
              {/* {loginUser && <Nav.Link href="#deets" onClick={handleLogout}>SIGN OUT</Nav.Link>} */}
            </Nav>
          </Navbar.Collapse>
      </Container>
      :""}
    </Navbar>
  );
}


function LinkComponent ({href,children,...props}){
  const resolvePathName = useResolvedPath(href);
  const isActive = useMatch({path:resolvePathName.pathname, end:true});
  return (
    <Link className={"nav-link "+(isActive ?"active":"")} to={href} >{children}</Link>
  )
}

export default AppNavbar;