
import React, { useState, useEffect, useRef } from 'react';

import pataImg from '../../assets/pataImg.png'
import threePati from '../../assets/threePati.png'
import tickIcon from '../../assets/tickIcon.png'
import crossIcon from '../../assets/crossIcon.png'
import waitingIcon from '../../assets/waitingIcon.png'
import keyboardImg from '../../assets/keyboardImg.png'


import Image from 'react-bootstrap/Image'


const practicingData2 = [
  { id: 1, thirdTxt: "Quality", thirdImg: threePati, answer: 'k' },
  { id: 2, thirdTxt: "Clever", thirdImg: pataImg, answer: 's' },

  { id: 3, thirdTxt: "Clever", thirdImg: pataImg, answer: 's' },
  { id: 4, thirdTxt: "Quality", thirdImg: threePati, answer: 'k' },

  { id: 5, thirdTxt: "Clever", thirdImg: threePati, answer: 'k' },
  { id: 6, thirdTxt: "Quality", thirdImg: pataImg, answer: 's' },

  { id: 7, thirdTxt: "Quality", thirdImg: threePati, answer: 'k' },
  { id: 8, thirdTxt: "Clever", thirdImg: pataImg, answer: 's' },

  { id: 9, thirdTxt: "Quality", thirdImg: threePati, answer: 'k' },
  { id: 10, thirdTxt: "Quality", thirdImg: pataImg, answer: 's' },
  { id: 11, thirdTxt: "Quality", thirdImg: pataImg, answer: 's' },
]

// Quality

const Switchtime = 500;
const Switchtime1 = 1000;
const primeTime = 1000;

var currentIndex = 0

var functionCount = 0
var functionCount1 = 0

let timer;

function PracticeStudy3({ setCurrentStudyTab }) {

  const [thirdShow, setThirdShow] = useState(false)

  const [primeShow, setPrimeShow] = useState(false)
  const [targetShow, setTargetShow] = useState(false)


  const [isUserAnswer, setIsUserAnswer] = useState(false)
  const [UserAnswer, setUserAnswer] = useState('')
  const [showAnsers, setShowAnsers] = useState(false)
  const [count, setCount] = useState(5);
  const [showCount, setShowCount] = useState(false);
  const [showPractice, setShowPractice] = useState(false);
  const [userPressedKey, setUserPressedKey] = useState('');
  const [isKeyboardShowing, setIsKeyboardShowing] = useState(false);
  const [isFirstTerminate, setIsFirstTerminate] = useState(false);
  const [showTopImgs, setShowTopImgs] = useState(true)

  const dataFetchedRef = useRef(false);
  const userPressedKeyRef = useRef(userPressedKey);

  const _setUserPressedKey = data => {
    userPressedKeyRef.current = data;
    setUserPressedKey(data);
  }

  const handleKeyDown = (event) => {
    _setUserPressedKey(event.key)
  }

  useEffect(() => {

    if (isKeyboardShowing) {
      if (userPressedKey === "s") {
        if (isFirstTerminate) {
          keyboardShowFun1()
        } else {
          keyboardShowFun()
        }
      } else if (userPressedKey === "k") {
        if (isFirstTerminate) {
          keyboardShowFun1()
        } else {
          keyboardShowFun()
        }
      }
    }
    else {
      if (userPressedKey === "s") {
        if (targetShow && !showAnsers) {
          setIsUserAnswer(true)
          setUserAnswer("s")
          clearTimeout(timer)

          setShowAnsers(true)

          setTimeout(() => {
            setShowTopImgs(false)
            setPrimeShow(false)
            setTargetShow(false)
            setShowAnsers(false)
          }, 1000)

          if (currentIndex === (practicingData2.length - 1)) {
            console.log("terminate:::::::")
            // keyboardShowFun1()
            setIsFirstTerminate(true)
            setIsKeyboardShowing(true)
            setShowPractice(false)
            setShowCount(false)
            setCount(5)
          } else {

            setTimeout(() => {
              currentIndex = currentIndex + 1
              timeFunction1()
            }, 2000)
          }



        }
      } else if (userPressedKey === "k") {
        if (targetShow && !showAnsers) {
          setIsUserAnswer(true)
          setUserAnswer("k")
          clearTimeout(timer)

          setShowAnsers(true)

          setTimeout(() => {
            setShowTopImgs(false)
            setPrimeShow(false)
            setTargetShow(false)
            setShowAnsers(false)
          }, 1000)

          if (currentIndex === (practicingData2.length - 1)) {
            console.log("terminate:::::::")
            // keyboardShowFun1()
            setIsFirstTerminate(true)
            setIsKeyboardShowing(true)
            setShowPractice(false)
            setShowCount(false)
            setCount(5)
          } else {

            setTimeout(() => {
              currentIndex = currentIndex + 1
              timeFunction1()
            }, 2000)
          }

        }
      }
    }
    setUserPressedKey("")
  }, [userPressedKey])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
  }, [])

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    keyboardShowtrue()
  }, [])

  const keyboardShowtrue = () => {
    setIsKeyboardShowing(true)
  }
  const keyboardShowFun = () => {
    setIsKeyboardShowing(false)

    setTimeout(() => {
      setShowCount(true)
      let practice3Interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 1) {
            return prevCount - 1
          } else {
            clearInterval(practice3Interval);
            if (functionCount === 0) {
              setShowPractice(true)
              timeFunction1()
              functionCount++
            }
            return prevCount;
          }
        });
      }, 1000);

    }, 100);
  }

  const keyboardShowFun1 = () => {
    setIsKeyboardShowing(false)
    setTimeout(() => {
      setShowCount(true)
      let intervalIdd = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 1) {
            return prevCount - 1
          } else {
            clearInterval(intervalIdd);
            if (functionCount1 === 0) {
              changeTabStudy()
              functionCount1++
            }
            return prevCount;
          }
        });
      }, 1000);
    }, 100);
  }

  const changeTabStudy = () => {
    setTimeout(() => {
      setCurrentStudyTab(7)
    }, 1100);
  }

  const timeFunction1 = () => {
    setIsUserAnswer(false)
    setShowTopImgs(true)

    timer = setTimeout(() => {
      setPrimeShow(true)
    }, 1000)

    timer = setTimeout(() => {
      setPrimeShow(false)
      setTargetShow(true)
    }, 2000)

    timer = setTimeout(() => {
      setShowAnsers(true)
    }, 2800)

    timer = setTimeout(() => {
      setShowTopImgs(false)
      setPrimeShow(false)
      setTargetShow(false)
      setShowAnsers(false)
    }, 3800)

    if (currentIndex === (practicingData2.length - 1)) {
      console.log("terminate:::::::")
      // keyboardShowFun1()
      setIsFirstTerminate(true)
      setIsKeyboardShowing(true)
      setShowPractice(false)
      setShowCount(false)
      setCount(5)
    } else {

      timer = setTimeout(() => {
        currentIndex = currentIndex + 1
        timeFunction1()
      }, 4800)

    }


  }


  return (
    <div>
      {showPractice &&
        <div className='topBarPhase1Con'>
          <div className='topBarPhase1'>
            <div className='topBarActivePhase1' style={{ width: ((currentIndex + 1) * 10) + "%" }} />
          </div>
        </div>}

      {showPractice ?
        <div className='study2BoxConPhase1'>
          <div className='study2RightConPhase1'>
            <div className='study2upperImgCon'>
              <div className='study2SandImgCon'>
                {showTopImgs ? <Image src={pataImg} className="study2leftSideImg" alt="logo" /> : null}
              </div>
              <div className='study2SandImgCon'>
                {showTopImgs ? <Image src={threePati} className="study2RightSideImg" alt="logo" /> : null}
              </div>
            </div>
            <div className='study2MidImgCon'>
              <div className='study2MidImgTickCon'>
                {targetShow ?
                  showAnsers ?
                    isUserAnswer ?
                      <Image src={UserAnswer === practicingData2[currentIndex]?.answer ? tickIcon : crossIcon} className="study2tickIconImg" alt="logo" />
                      :
                      <Image src={waitingIcon} className="study2tickIconImg" alt="logo" /> : null
                  : null}

                {primeShow ?
                  <p>{practicingData2[currentIndex]?.thirdTxt}</p> : null}
                {targetShow ?
                  <Image src={practicingData2[currentIndex]?.thirdImg} className="study2leftSideImg" alt="logo" />
                  : null}

                {/* {thirdShow ?
                  imageOrTxt === "text" ?
                    <p>{practicingData2[currentIndex]?.thirdTxt}</p>
                    :
                    <Image src={practicingData2[currentIndex]?.thirdImg} className="study2leftSideImg" alt="logo" />
                  : null
                } */}

              </div>
            </div>
          </div>
        </div>
        :

        showCount ?
          <div className='cstm_box_counters'>
            <div className='leftConPhase1'>
              <div className='countdown_stdy_inner'>
                <span className='auto_comp_component'>{count}</span>
              </div>
            </div>
          </div>
          :
          isFirstTerminate ?
            //before actual study wali keyboard
            <div className='BoxConPhase1'>
              <div className='leftConPhase1'>
                <p className='leftTextUpper'>Now that you have practiced, you are ready to begin the actual study. <br></br> <br></br> Note that the objects will no longer be spades and clubs. Stimuli at the top and the bottom of the page may be <span className='leftTextBold'>picture and / or words.</span><br></br> <br></br> Once you’re ready to start the study, press either key.</p>
              </div>
              <div className='RightConPhase1'>
                <Image src={keyboardImg} className="keyboardImgsty" alt="logo" />
              </div>
            </div>
            :
            //starting keyboard
            <div className='BoxConPhase1'>
              <div className='leftConPhase1'>
                <p className='leftTextUpper'>Let’s practice again! <br></br> <br></br><span className='leftTextBold'>Remember, go as fast as you can!</span><br></br> <br></br> Once you’re ready to start the study, press either key.</p>
              </div>
              <div className='RightConPhase1'>
                <Image src={keyboardImg} className="keyboardImgsty" alt="logo" />
              </div>
            </div>
      }

    </div>
  )

}
export default PracticeStudy3

