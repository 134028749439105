import { useContext, useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Container, Col, Row, Tabs, Stack, Button, Tab, Alert } from 'react-bootstrap';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import ParticipantScreener from './ParticipantScreener';
import { ShowMainNav } from '../../Components/Context';
import ParticpantStudy from './ParticipantStudy';
import ParticpantSurvey from './ParticipantSurvey';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var saveRidUrl = process.env.REACT_APP_API_URL + apiVersion + "study-rid/";
var getStudyUrl = process.env.REACT_APP_API_URL + apiVersion + "take/studies/";
var saveAnswerUrl = process.env.REACT_APP_API_URL + apiVersion + "study/answer/"
var exitButtonApiUrl = process.env.REACT_APP_API_URL + apiVersion + "study/trackbutton/";
// api/v1/study/trackbutton/{studyid}/userid
var ValidateUserAndStdy = false;

export default function ParticipantFacing() {

    const [currentTab, setCurrentTab] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [errmsg, setErrMsg] = useState('Study Not Saved');
    const [surveyIncluded, setSurveyIncluded] = useState(true);
    const [fullStudyData, setFullStudyData] = useState("");
    const [studyId, setStudyId] = useState("");
    const [userId, setUserId] = useState("");
    const [isDevicePC, setIsDevicePC] = useState(true);

    const { setShowNav } = useContext(ShowMainNav);
    const initialized = useRef(false)
    var nextBtnGo = false;
    let navigate = useNavigate();
    // const { studyID, userID } = useParams();
    const { studyID } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const RID = queryParams.get("Rid");
    const ridExists = queryParams.has("rid");
    const ridVal = ridExists ? queryParams.get("rid") : null;

    const userID = ridVal && ridVal.includes('&') ? ridVal.split('&')[0] : ridVal;

    useEffect(() => {
        if (isMobile && window.screen.width < 769) {
            setIsDevicePC(false)
        } else {
            setIsDevicePC(true)
        }
    }, [])

    useEffect(() => {
        // getStudyData()
        if (!initialized.current) {
            initialized.current = true
            SendRidApi()
        }
        // getStudyData()
        getSavedAnswers("onLoadPage")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const SendRidApi = async () => {
        setStudyId(studyID)
        // setUserId(userID)
        setUserId(userID)

        if (userID === null) {
            setErrMsg("RID NOT FOUND"); setShowToast(true)

        } else {

            const newCookies = new Cookies();
            await Axios.get(
                saveRidUrl + studyID + "/" + userID,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                    }
                }).then(response => {
                    if (response && response.data && response.data.status) {
                        // setCurrentTab((prev) => prev + 6)
                        getStudyData()
                        //    console.log("response.data0===>", response.data);
                    } else {
                        setErrMsg(response.data.message);
                        setShowToast(true);
                    }
                }).catch(error => {
                    // if (error.response.status === 422) {
                    //     getStudyData()
                    //     console.log("error 422===>", error.response);
                    // } else {
                    setErrMsg(error.response.data.message);
                    setShowToast(true);
                    //     console.log("error00===>", error.response);
                    // }
                });
        }

        // setShowNav(false)
    }

    const getStudyData = async () => {
        setStudyId(studyID)
        setUserId(userID)
        const newCookies = new Cookies();
        await Axios.get(
            getStudyUrl + studyID + "/" + userID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    setFullStudyData(response.data.data)
                    console.log("StudyData===>", response.data.data.screenerDetail.screenerConditionsBox);
                    setSurveyIncluded(response.data?.data?.isIncludePostSurvey)
                    ValidateUserAndStdy = true;
                } else {
                    setErrMsg(response.data.message);
                    setShowToast(true);
                }
            }).catch(error => {
                console.log("error111===>", error);
                if (error.response.status === 500) {
                    setErrMsg("Invalid Study ID");
                } else {
                    setErrMsg(error.response.data.message); //invalid userid
                }
                setShowToast(true);
            });

        setShowNav(false)
    }

    const getSavedAnswers = async (typeOfparam) => {
        const newCookies = new Cookies();
        await Axios.get(
            saveAnswerUrl + studyID + "/" + userID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                if (typeOfparam === "onLoadPage") {
                    if (response?.data?.data?.tabScreen && typeOfparam === "onLoadPage") {
                        // setCurrentTab(Number(response?.data?.data?.tabScreen?.split("_")[0]))   
                    }
                } else if (typeOfparam === "generateExternalLink") {
                    let screenerAnsData = response?.data?.data.screener;
                    let extUrl = fullStudyData?.externalLink.includes('?') ? "&RID=" + userID : "?RID=" + userID;
                    fullStudyData?.screenerDetail?.screenerQuestionBox.map((dta, ida) => {
                        if (dta.qualtrics) {
                            let reqIndex = screenerAnsData.findIndex((ctd, dpd) => ctd.question.toLowerCase() === dta.question.toLowerCase())
                            if (reqIndex > -1) {
                                extUrl = extUrl + "&" + screenerAnsData[reqIndex].question + "=" + screenerAnsData[reqIndex].answers[0];
                            }
                        }
                    })
                    let externalLinkUrl = fullStudyData?.externalLink
                    if (!externalLinkUrl.includes('://')) {
                        externalLinkUrl = 'https://' + externalLinkUrl;
                    }
                    window.location.href = externalLinkUrl + extUrl;

                } else if (typeOfparam === "screenoutLink") {

                    let screenerAnsData = response?.data?.data.screener;
                    let extUrl = fullStudyData?.redirectLink.includes('?') ? "&RID=" + userID : "?RID=" + userID;
                    fullStudyData?.screenerDetail?.screenerQuestionBox.map((dta, ida) => {
                        if (dta.qualtrics) {
                            let reqIndex = screenerAnsData.findIndex((ctd, dpd) => ctd.question.toLowerCase() === dta.question.toLowerCase())
                            if (reqIndex > -1) {
                                extUrl = extUrl + "&" + screenerAnsData[reqIndex].question + "=" + screenerAnsData[reqIndex].answers[0];
                            }
                        }
                    })
                    let redirectLinkUrl = fullStudyData?.redirectLink
                    if (!redirectLinkUrl.includes('://')) {
                        redirectLinkUrl = 'https://' + redirectLinkUrl;
                    }
                    window.location.href = redirectLinkUrl + extUrl;

                    // redirectLink
                }

            }).catch(error => {
                // console.log("get save answer api error==>", error);
            });
    }

    const handleNextBtn = async () => {
        if (currentTab === 4 && fullStudyData.isIncludePostSurvey === false) {
            setCurrentTab((prev) => prev + 3);
        } else {
            setCurrentTab((prev) => prev + 1);
        }
    }

    const exitButtonApi = async () => {
        const newCookies = new Cookies();
        await Axios.post(
            exitButtonApiUrl + studyID + "/" + userID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                // console.log("exit button response", response);
            }).catch(error => {
                console.log("exit button error", error);
            });
    }

    const closeWindow = () => {
        exitButtonApi()
        if (window.location.href.indexOf(fullStudyData.previewCode) > -1) {
            window.location.href = window.location.origin
        }
        else if (fullStudyData.externalLink) {
            getSavedAnswers("generateExternalLink")
            // window.location.href = fullStudyData.externalLink + "/" + userID
        } else {
            window.open('', '_self').close();
            window.close()
            window.location.reload()
        }
    }

    const screenoutExit = () => {
        exitButtonApi()
        if (window.location.href.indexOf(fullStudyData.previewCode) > -1) {
            window.location.href = window.location.origin
        }
        else if (fullStudyData.redirectLink) {
            getSavedAnswers("screenoutLink")
            // window.location.href = fullStudyData.externalLink + "/" + userID
        } else {
            window.open('', '_self').close();
            window.close()
            window.location.reload()
        }
    }

    const errAlert = <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
        <p>{errmsg}</p>
    </Alert>;
    return (
        <Container className='participant_fac_main_content'>
            {(showToast && ValidateUserAndStdy) && errAlert}
            {(fullStudyData && ValidateUserAndStdy) ?
                isDevicePC ?
                    <div className='pf_cs_mn'>
                        <Row>
                            <Col>
                                <Tabs activeKey={currentTab} id="fac_firstTab_cstm">
                                    {currentTab === 0 &&
                                        <Tab eventKey={0} title="" disabled={currentTab !== 0} tabClassName="study_cstm_first" >
                                            <div className='participant_fac_main_cstm'>
                                                <p className='welcomeText'>Welcome to the Study!</p>
                                                <p className='welcomeDesp'>Welcome to nPlicit™ — an online survey methodology that uses timed responses. Please find a quiet place without distractions to complete the study.</p>
                                                <button className="button" onClick={() => [setCurrentTab((prev) => prev + 1)]}>CLICK TO BEGIN</button>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 1 &&
                                        <Tab eventKey={1} title="" disabled={currentTab !== 1} tabClassName="pf_cstm_second" >
                                            <div className='pf_cstm_second_main'>
                                                <div className='upperCon'>
                                                    <div className="column">
                                                        <div className='fst_sss_pf'>
                                                            <p className='ScreenerTxt Screener' >Screener</p>
                                                            <p className='ScreenerTxt Study' style={{ color: "#E1E1E6" }}>Study</p>
                                                            <p className='ScreenerTxt Survey' style={{ color: "#E1E1E6" }}>Survey</p>
                                                        </div>
                                                    </div>
                                                    <div className="lineStyle"></div>
                                                    <div className="column1">
                                                        <div>
                                                            <div className='pf_oneCircle'>
                                                                <p className='pf_noCirle'>1</p>
                                                            </div>
                                                            <p className='takingScreener'>First step is the screener.</p>
                                                            <p className='takingScreenerDesp'>We will begin by asking a few questions.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 2 &&
                                        <Tab eventKey={2} title="" disabled={currentTab !== 2} tabClassName="pf_cstm_screener" >
                                            <div className='pf_cstm_screener_main'>
                                                <ParticipantScreener fullStudyData={fullStudyData.screenerDetail} currentTab={currentTab} setCurrentTab={setCurrentTab} studyID={studyId} userID={userId}
                                                     screenoutExit={screenoutExit} />
                                            </div>
                                        </Tab>
                                    }
                                    {(currentTab === 3) &&
                                        <Tab eventKey={3} title="" disabled={currentTab !== 3} tabClassName="pf_cstm_study_fst pf_cstm_second" >
                                            <div className='pf_cstm_second_main pf_cstm_study_f'>
                                                <div className='upperCon'>
                                                    <div className="column">
                                                        <div className='second_sss_pf'>
                                                            <p className='ScreenerTxt Screener' style={{ color: "#E1E1E6" }} >Screener</p>
                                                            <p className='ScreenerTxt Study' >Study</p>
                                                            <p className='ScreenerTxt Survey' style={{ color: "#E1E1E6" }}>Survey</p>
                                                        </div>
                                                    </div>
                                                    <div className="lineStyle"></div>
                                                    <div className="column1">
                                                        <div>
                                                            <div className='pf_oneCircle'>
                                                                <p className='pf_noCirle'>2</p>
                                                            </div>
                                                            <p className='takingScreener'>You qualify for the study!</p>
                                                            {/* <p className='takingScreenerDesp'>The next step will take at least <strong>10 minutes</strong>, but likely more.</p> */}
                                                            <p className='takingScreenerDesp'>The next step will take between <strong>10 and 15 minutes</strong>.</p>
                                                            <p className='takingScreenerDesp'>Please ensure you have enough time, or come back later to complete the study.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 4 &&
                                        <Tab eventKey={4} title="" disabled={currentTab !== 4} tabClassName="pf_stdy_start" >
                                            <ParticpantStudy fullStudyData={fullStudyData} currentTab={currentTab} setCurrentTab={setCurrentTab} surveyIncluded={surveyIncluded} setErrMsg={setErrMsg} setShowToast={setShowToast} studyID={studyId} userID={userId} />
                                        </Tab>
                                    }
                                    {currentTab === 5 &&
                                        <Tab eventKey={5} title="" disabled={currentTab !== 5} tabClassName="pf_comp_stdy" >
                                            <div className='pf_comp_stdy_main'>
                                                <div className='BoxCon'>
                                                    <div className='BoxComp'>
                                                        <div className='textConComp'>
                                                            <p className='congTextComp'>Almost there!</p>
                                                            <button className="Exitbutton" onClick={() => setCurrentTab((prev) => prev + 1)}>
                                                                NEXT
                                                            </button>
                                                        </div>
                                                        <p className='lorumTextComp'>Click next to answer a few last questions, submit your results, and receive your incentive.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {(currentTab === 6 && fullStudyData.isIncludePostSurvey) &&
                                        <Tab eventKey={6} title="" disabled={currentTab !== 6} tabClassName="pf_survey_start" >
                                            <ParticpantSurvey fullStudyData={fullStudyData.surveyDetail} currentTab={currentTab} setCurrentTab={setCurrentTab} studyID={studyId} userID={userId} />
                                        </Tab>
                                    }
                                    {currentTab === 7 &&
                                        <Tab eventKey={7} title="" disabled={currentTab !== 7} tabClassName="pf_complete_study_all" >
                                            <div className='pf_full_complete_main pf_comp_stdy_main'>
                                                <div className='BoxCon'>
                                                    <div className='BoxComp'>
                                                        <div className='textConComps'>
                                                            <h2 className='congTextComp'>Thank you for completing the study!</h2>
                                                            <p>Click exit to share your results and receive your incentive.</p>
                                                            <button onClick={() => closeWindow()} className="Exitbutton">EXIT</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                </Tabs>
                            </Col>
                        </Row>
                        {(currentTab === 0 || currentTab === 2 || currentTab === 4 || currentTab === 5 || currentTab === 6 || currentTab === 7) ? "" :
                            <Stack gap={3} direction="horizontal" className="mt-3">
                                {currentTab === 3 || currentTab === 1 ? ""
                                    :
                                    <Button
                                        className={"cstm_stdy_tb cstm_stdy_prev" + (currentTab === 0 ? " start_active" : "success next_active")}
                                        onClick={() => {
                                            if (currentTab > 0) {
                                                if (currentTab === 7 && fullStudyData.isIncludePostSurvey === false) {
                                                    setCurrentTab((prev) => prev - 3)
                                                } else {
                                                    setCurrentTab((prev) => prev - 1)
                                                }
                                            }
                                        }}
                                    >
                                        {currentTab === 1 ? "EXIT" : "PREVIOUS"}
                                    </Button>}
                                {
                                    <Button
                                        className="success ms-auto cstm_stdy_tb cstm_stdy_next"
                                        disabled={nextBtnGo}
                                        onClick={handleNextBtn}
                                    >
                                        {currentTab === 1 ? "TAKE SCREENER" : "NEXT"}
                                    </Button>
                                }
                            </Stack>
                        }
                    </div>
                    :
                    <div className='participant_fac_main_cstm'>
                        <p className='welcomeText'>Welcome to {fullStudyData.projectTitle} Study</p>
                        <p className='welcomeDesp'>Please access this link on a PC</p>
                    </div>
                :
                showToast ?
                    <div className='participant_fac_main_cstm'>
                        <p className='welcomeDesp'>{errmsg}</p>
                    </div>
                    :
                    <div className='cstm_loader'>
                        <div className='lds-dual-ring'></div>
                    </div>
            }
        </Container>

    )
}
