import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { Stack } from 'react-bootstrap';
import { Chart } from "react-google-charts"

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var downloadScreenerResult = process.env.REACT_APP_API_URL + apiVersion + "export/screener-results/";

export const data = [
  ["Task", "stats"],
  ["Incomplete", 1],
  ["Completed", 1],
  ["Not Started", 9],
]
export const options = {
  'width': '80%',
  'height': 275,
  'chartArea': { 'width': '100%', 'height': '80%' },
  // 'legend': { position: 'none', alignment: 'center' },
  pieSliceText: 'value',
  legend: {
    position: 'labeled',
    labeledValueText: 'both',
    textStyle: {
      color: '#1D1C1D',
      fontSize: 10
    }
  },
  // colors: ['#E8E9F3', '#ACB9FF', '#4273F0'],
  colors: ['#4273F0', '#ACB9FF', '#E8E9F3'],
  sliceVisibilityThreshold: 0.00005
}
export const data1 = [
  ['Screener custom', 'custom links'],
  ['0', 200],
  ['1', 180],
  ['2', 170],
  ['3', 160],
  ['4', 80],
  ['5', 80],
  ['6', 70],
  ['7', 60],
  ['8', 50],
]
export const options1 = {
  // title: 'House Prices vs Size',
  hAxis: { title: 'Screener Question' },
  'legend': { position: 'none' },
  vAxis: {
    viewWindow: {
      min: 0 // This sets the minimum value of the y-axis to 0
    },
    title: 'Number Of Participants'
  }
  // legend: 'none'
}

var checkDataValue = false

function ScreenerData({ stdyId, screenerData, updateScreenerPress, setErrMsg, setShowToast, screenerDataUpdated }) {

  // const calculateSharedLinks = () => {
  //   var sharedLinks = screenerData?.rightChartData[1][1];
  //   var sharedLinks = screenerData?.rightChartData[1][1];
  //   // for (var i = 1; i < screenerData?.rightChartData?.length; i++) {
  //   //   sharedLinks += screenerData?.rightChartData[i][1];
  //   // }
  //   return sharedLinks
  // }

  const screenerResults = async () => {
    let newCookies = new Cookies();
    await Axios.get(
      downloadScreenerResult + stdyId,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        const csvContent = response?.data;
        const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }).catch(error => {
        setErrMsg(error?.response?.data?.message)
        setShowToast(true)
      })
  }

  // if (screenerDataUpdated == true && screenerData?.rightChartData[1][1] == 0 && screenerData?.rightChartData[2][1] == 0 && screenerData?.rightChartData[3][1] == 0 ) {
  //   checkDataValue = true
  // }

  // screenerDataUpdated = false


  return (

    <>
      <div className='resultStudyInfoDataCon'>
        <div className='resultStudyStatsCon'>
          <div className='resultScreenerCompletionStatus'>
            <p className='ScreenerCompletionTxtStyResult'>Screener Completion Status</p>
            <div className='resultScreenerCompletionGraphStatsCon'>
              {screenerDataUpdated ?
                ( screenerData.leftChartData[1][1] === 0 && screenerData.leftChartData[2][1] === 0 && screenerData.leftChartData[3][1] === 0 ) ?
                  <div className='noGraphTextCon'>
                    <p className='ScreenerCompletionTxtStyResult'>No link generated against this study</p>
                  </div>
                  :
                  <Chart
                    chartType="PieChart"
                    data={screenerData.leftChartData}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                  />
                : null}
            </div>
            {/* <div className='staticDetailsCon'>
              {screenerData.leftChartData?.slice(1)?.map((item, index) => (
                <p key={index} className='StaticTextSty'>{item[0]} : {item[1]}</p>
              ))}
            </div> */}
          </div>
        </div>
        <div className='resultStudyAttributeCon'>
          <div className='resultScreenerDataLineGraphCon'>
            <p className='ScreenerCompletionTxtStyResult'>Screener Drop-Off</p>
            {/* <p className='linkedSharedTxtStyResult'>{`${calculateSharedLinks()}${" Total Screener Started"}`}</p> */}
            {screenerDataUpdated ?
              <p className='linkedSharedTxtStyResult'>{`${screenerData?.rightChartData[1][1]}${" Total Participants Started Screener"}`}</p> : null}
            <Chart
              chartType="LineChart"
              width="100%"
              height="85%"
              data={screenerData.rightChartData}
              options={options1}
            />

          </div>
        </div>
      </div>

      <div className="resultUpdateScreenerBtnCon">
        <Stack direction="horizontal" gap={3}>
          <button onClick={updateScreenerPress} className="UpdateScreenerBtnStyResult">
            UPDATE SCREENER
          </button>
          <button onClick={screenerResults} className="RawResultsStudyBtnSty screenerResults">
            SCREENER RESULTS
          </button>
        </Stack>
      </div>
    </>

  )
}
export default ScreenerData

