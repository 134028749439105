import React, { useState, useEffect, useRef } from 'react'

import Axios from 'axios'
import Cookies from 'universal-cookie';
import { Tab, Tabs, Image } from 'react-bootstrap'


var domainUrl = process.env.REACT_APP_API_URL;

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var saveAnswerUrl = process.env.REACT_APP_API_URL + apiVersion + "study/answer/"
var exitButtonApiUrl = process.env.REACT_APP_API_URL + apiVersion + "study/trackbutton/";


function ParticipantScreener({ fullStudyData, currentTab, setCurrentTab, studyID, userID, screenoutExit }) {
  const [screenerAnswers, setScreenerAnswers] = useState([])
  const [currentQuestionTab, setCurrentQuestionTab] = useState(0)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [isNotQualify, setIsNotQualify] = useState(false)
  const [showDontQualifyScreen, setShowDontQualifyScreen] = useState(false)

  const currentQuestionTabRef = useRef(currentQuestionTab);

  const _setCurrentQuestionTab = data => {
    currentQuestionTabRef.current = data;
    setCurrentQuestionTab(data);
  };

  useEffect(() => {
    getSavedAnswers()
  }, [])

  const getSavedAnswers = async () => {
    const newCookies = new Cookies();
    await Axios.get(
      saveAnswerUrl + studyID + "/" + userID,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setScreenerAnswers(response?.data?.data?.screener)
        if (response?.data?.data?.screener?.length > 0) {
          setIsBtnDisabled(false)
        } else {
          setIsBtnDisabled(true)
        }
        if (response?.data?.data?.tabScreen) {
          if (Number(response?.data?.data?.tabScreen?.split("_")[2]) < fullStudyData?.screenerQuestionBox?.length - 1) {
            _setCurrentQuestionTab(Number(response?.data?.data?.tabScreen?.split("_")[2]) + 1)
          }
        }
        // setScreenerAnswers(response?.data?.screener)
      }).catch(error => {
        console.log("get save answer api error==>", error);
      });
  }

  useEffect(() => {
    if (screenerAnswers[currentQuestionTabRef.current]?.answers?.length > 0) {
      // setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [currentQuestionTabRef.current, screenerAnswers])

  useEffect(() => {
    if (showDontQualifyScreen) {
      saveUserAnswers()
    }
  }, [showDontQualifyScreen])


  const saveUserAnswers = async () => {
    if (screenerAnswers?.length > 0) {
      var screenerAns = {
        screener: screenerAnswers,
        userId: userID,
      }
      if (showDontQualifyScreen) {
        screenerAns["status"] = "notqualify"
      }
      if (currentQuestionTabRef.current === fullStudyData?.screenerQuestionBox?.length - 1) {
        screenerAns["screenerStatus"] = "Completed"
      }
      if (currentQuestionTabRef.current === fullStudyData?.screenerQuestionBox?.length - 1) {
        screenerAns["tabScreen"] = 3 + "_study_" + 0
      } else {
        screenerAns["tabScreen"] = currentTab + "_screener_" + currentQuestionTabRef.current
      }


      const newCookies = new Cookies();
      await Axios.post(
        saveAnswerUrl + studyID,
        screenerAns,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
        }).catch(error => {
          console.log("api error==>", error)
        })
    }
  }

  const handleGetAnserwer = (event, index, type, inx, matrixQuestion, forceRanking, isSingleRow) => {
    let newScreenerAnswer = [...screenerAnswers]
    if (screenerAnswers && screenerAnswers[index] === undefined) {
      newScreenerAnswer.push({ "question": "", "type": "", "answers": [] });
    }
    if (type === "check_boxes") {
      newScreenerAnswer[index].question = event.target.name;
      newScreenerAnswer[index].type = type;
      let ansIdx = newScreenerAnswer[index]?.answers?.findIndex(ans => ans === event.target.value)
      if (ansIdx !== -1) {
        newScreenerAnswer[index]?.answers?.splice(ansIdx, 1);
      } else {
        if (event.target.value.toLowerCase() === 'none of the above' || event.target.value.toLowerCase() === 'none') {
          newScreenerAnswer[index].answers = [];
          newScreenerAnswer[index].answers.push(event.target.value);
        } else {
          let answers = [...newScreenerAnswer[index].answers]
          let newarr = answers.filter(a => a.toLowerCase() !== 'none of the above')
          newarr = newarr.filter(a => a.toLowerCase() !== 'none')
          newScreenerAnswer[index].answers = newarr
          newScreenerAnswer[index].answers.push(event.target.value);
        }
      }
    } else if (type === "matrix_rating_scale") {
      newScreenerAnswer[index].question = matrixQuestion;
      newScreenerAnswer[index].type = type
      if (newScreenerAnswer[index] && newScreenerAnswer[index]?.answers?.length > 0) {
        if (isSingleRow) {
          let ansIdx = newScreenerAnswer[index]?.answers?.findIndex(ans => ans === event.target.value)
          if (ansIdx !== -1) {
            newScreenerAnswer[index]?.answers?.splice(ansIdx, 1)
          } else {
            let ansIdx = newScreenerAnswer[index]?.answers?.findIndex(ans => ans.split("_")[0] === event.target.value.split("_")[0])
            if (ansIdx !== -1) {
              newScreenerAnswer[index].answers[ansIdx] = event.target.value;
            } else {
              newScreenerAnswer[index].answers.push(event.target.value);
            }
          }
        } else {
          let ansIdx = newScreenerAnswer[index]?.answers?.findIndex(ans => ans.split("_")[0] === event.target.value.split("_")[0])
          if (ansIdx !== -1) {
            newScreenerAnswer[index].answers[ansIdx] = event.target.value;
          } else {
            newScreenerAnswer[index].answers.push(event.target.value);
          }
        }
      } else {
        newScreenerAnswer[index].answers.push(event.target.value);
      }
    } else {
      newScreenerAnswer[index].question = event.target.name;
      newScreenerAnswer[index].type = type;
      newScreenerAnswer[index].answers[0] = event.target.value;
    }
    setScreenerAnswers(newScreenerAnswer);
    validateNextBtn(forceRanking, newScreenerAnswer)
  }

  const checkTerminateCondition = (screenerAnswer) => {
    // console.log(fullStudyData?.screenerConditionsBox, "Answers==>", screenerAnswer );
    let check = false
    fullStudyData?.screenerConditionsBox?.map((conditionObj, condIdx) => {
      let QuestionIndex = screenerAnswer.findIndex((ansObj) => ansObj.question === conditionObj.question)
      if (QuestionIndex > -1) {
        if (condIdx > 0) {
          let result = checkCondition(screenerAnswer[QuestionIndex]?.answers, conditionObj.condition, conditionObj.answer)
          check = checkOperatorFun(check, result, 'or')
        } else {
          check = checkCondition(screenerAnswer[QuestionIndex]?.answers, conditionObj.condition, conditionObj.answer)
        }
        conditionObj?.otherCondition?.map((OthCondObj, OthCondIdx) => {
          let QueIndex = screenerAnswer.findIndex((ansObj) => ansObj.question === OthCondObj.question)
          if (QueIndex > -1) {
            let result = checkCondition(screenerAnswer[QueIndex]?.answers, OthCondObj.condition, OthCondObj.answer)
            let condResult = checkOperatorFun(check, result, OthCondObj.operator)
            check = condResult
            console.log("PreValue", check, "currentValue", result, "result", condResult);
          }
        })
      }
      if (conditionObj.acceptanceScreenerCondition === true) {
        check = !check
      }
    })
    return check;
  }

  const NextQuestion = () => {
    if (currentQuestionTabRef.current >= (fullStudyData?.screenerQuestionBox?.length - 1)) {
      let res = checkTerminateCondition(screenerAnswers)
      if (res) {
        saveUserAnswers()
        if (currentQuestionTabRef.current >= (fullStudyData?.screenerQuestionBox?.length - 1)) {
          setShowDontQualifyScreen(true)
  
        } else {
          setIsNotQualify(true)
          _setCurrentQuestionTab(currentQuestionTabRef.current + 1);
        }
      } else {
        saveUserAnswers()
        if (currentQuestionTabRef.current >= (fullStudyData?.screenerQuestionBox?.length - 1)) {
          if (isNotQualify) {
            setShowDontQualifyScreen(true)
          } else {
            setCurrentTab((prev) => prev + 1)
          }
        } else {
          _setCurrentQuestionTab(currentQuestionTabRef.current + 1);
        }
      }
    } else {
      saveUserAnswers()
      _setCurrentQuestionTab(currentQuestionTabRef.current + 1);
    }

  }

  const checkOperatorFun = (PreValue, CurrentValue, operator) => {
    if (operator === 'or') {
      return PreValue || CurrentValue;
    } else if (operator === 'and') {
      return PreValue && CurrentValue;
    } else {
      return null;
    }
  }

  const checkCondition = (answers, condition, CondAnswer) => {
    switch (condition) {
      case "euqal":
        return answers.includes(CondAnswer);
      case "not_equal":
        return !answers.includes(CondAnswer);
      default:
        return false;
    }
  }

  const validateNextBtn = (forceRanking, newScreenerAnswer) => {
    if (newScreenerAnswer[currentQuestionTabRef.current]?.type === "matrix_rating_scale" && forceRanking) {
      if (newScreenerAnswer[currentQuestionTabRef.current]?.answers?.length === fullStudyData?.screenerQuestionBox[currentQuestionTabRef.current]?.rows?.length) {
        setIsBtnDisabled(false) // if true then make button false;
      } else {
        setIsBtnDisabled(true)
      }
    }
    else if (newScreenerAnswer[currentQuestionTabRef.current]?.answers?.length > 0) {
      if (isBtnDisabled) {
        setIsBtnDisabled(false) // if true then make button false;
      }
    } else {
      setIsBtnDisabled(true)
    }
  }

  const PreviousQuestion = () => {
    setIsBtnDisabled(false)
    if (currentQuestionTabRef.current > 0) {
      _setCurrentQuestionTab(currentQuestionTabRef.current - 1);
    } else {
      setCurrentTab((prev) => prev - 1);
    }
    saveUserAnswers()
  }

  const closeWindow = () => {
    screenoutExit()

    // window.location.href = "/";
    // window.open('', '_self').close();
    // window.close()
    // window.location.reload()
  }

  return (
    <div>
      {!showDontQualifyScreen ?
        <>
          <div className='topBarCon'>
            {fullStudyData?.screenerQuestionBox?.map((item, index) =>
              <div key={"topBar" + index} className={(index === currentQuestionTabRef.current) ? "topBarQuestionActive" : 'topBarQuestion'} />
            )}
          </div>

          <div className='BoxCon pf_screener_ques'>
            <Tabs activeKey={currentQuestionTabRef.current} id="pf_sq_cstm">
              {fullStudyData?.screenerQuestionBox?.map((data, indx) =>
                <Tab eventKey={indx} title="" disabled={currentQuestionTabRef.current !== indx} key={"idxg" + indx} tabClassName="pf_sq" >
                  <div className='participant_question_image_Con'>
                    {/* {data?.quesMediaImg && <Image src={`${domainUrl}${data.quesMediaImg}`} className='participant_question_image' alt="Image" />} */}
                    {data?.quesMediaImg && <Image src={`${data.quesMediaImg}`} className='participant_question_image' alt="Image" />}
                    <div className='BoxScreenerQuestion'>
                      <div className='questionCon'>
                        <p className='questionStyle'>{indx + 1}. {data.question}</p>
                      </div>
                      <div className={'answerCon ' + (data.questionType)}>
                        {data.questionType === "multiple_choices" || data.questionType === "multiple_choice" ?
                          data.options?.map((opt, inx) =>
                            <div className='radioCon1' key={"inxs" + inx}>
                              <input
                                className={screenerAnswers[indx]?.answers?.includes(opt.value) ? 'active' : undefined}
                                type="radio"
                                name={data.question}
                                id={indx + "qesId" + inx}
                                value={opt.value}
                                defaultChecked={screenerAnswers[indx]?.answers?.includes(opt.value) || ""}
                                onChange={(event) => handleGetAnserwer(event, indx, data.questionType)} />
                              <label htmlFor={indx + "qesId" + inx} className='answerTxt'>{opt.value}</label>
                            </div>
                          )
                          : ""
                        }
                        {data.questionType === "check_boxes" ?
                          data.options?.map((opt, inx) =>
                            <div className='radioCon1' key={"inxs" + inx}>
                              <input
                                className={screenerAnswers[indx]?.answers?.includes(opt.value) ? 'active' : undefined}
                                type="checkbox" name={data.question} id={indx + "qesId" + inx}
                                value={opt.value}
                                checked={screenerAnswers[indx]?.answers?.includes(opt.value) || ""}
                                // defaultChecked={getChecked(opt.value, indx, inx)}
                                onChange={(event) => handleGetAnserwer(event, indx, data.questionType, inx)} />
                              <label htmlFor={indx + "qesId" + inx} className='answerTxt'>{opt.value}</label>
                            </div>
                          )
                          : ""
                        }
                        {data.questionType === "text_box" ?
                          <div className='radioCon1' key={"inxs" + indx}>
                            <input type="text" name={data.question} id={indx + "qesId"}
                              defaultValue={screenerAnswers[indx]?.answers[0] || ""}
                              onChange={(event) => handleGetAnserwer(event, indx, data.questionType)} />
                          </div>
                          : ""
                        }
                        {data.questionType === "matrix_rating_scale" ?
                          <div className='rows_colmns_screnr_pf'>
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  {data.columns?.map((col, colInd) =>
                                    <th key={"colInd" + colInd}>{col.value}</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {data.rows?.map((opt, inx) =>
                                  <tr key={"optS" + inx}>
                                    <th>{opt.value}</th>
                                    {data.columns?.map((clm, dhm) =>
                                      <td key={"clm" + dhm}>
                                        <input
                                          className={screenerAnswers[indx]?.answers?.includes(opt.value + "_" + clm.value) ? 'active' : ""}
                                          type="radio"
                                          name={`matrix-${inx}`}
                                          value={opt.value + "_" + clm.value}
                                          checked={screenerAnswers[indx]?.answers?.includes(opt.value + "_" + clm.value) || ''}
                                          onClick={(event) => handleGetAnserwer(event, indx, data.questionType, inx, data.question, data.forceRanking, data.singleRowRating)}
                                          onChange={e => { }}
                                        // onChange={(event) => handleGetAnserwer(event, indx, data.questionType, inx, data.question, data.forceRanking)} 
                                        />
                                      </td>
                                    )}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          : ""
                        }
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>

          <div className='bottomConScreenerQuestion'>
            {/* {currentQuestionTab === 0 ? null : */}
            <button onClick={() => PreviousQuestion()} style={{ opacity: currentQuestionTabRef.current === 0 ? 0 : 1 }} className="Exitbutton">{"BACK"}</button>

            <button onClick={() => NextQuestion()}
              className={"NextbuttonActive" + (isBtnDisabled === true ? " disabled" : "")}
              disabled={isBtnDisabled}>NEXT</button>

          </div>
        </>
        :
        <div className='pf_comp_dont_qualify'>
          <div className='BoxCon'>
            <div className='BoxComp'>
              <p className='congTextComp'>You do not qualify for this study</p>
              <p className='lorumTextComp'>Please exit the app</p>
              <button onClick={() => closeWindow()} className="Exitbutton">
                EXIT
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
export default ParticipantScreener
// http://localhost:3000/study/64341caaa40bc15ca56957db
