
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts"

const tableData = {
    attributeNames: ['Attribute 1', 'Attribute 2', 'Attribute 3', 'Attribute 4', 'Attribute 5', 'Attribute 6'],
    rows: [
        {
            rowHeader: 'Row 1',
            attributes: ['113', '113', '113', '113', '113', '113'],
        },
        {
            rowHeader: 'Row 2',
            attributes: ['113', '113', '113', '113', '113', '113'],
        },
    ],
};

const data2 = [
    ['Attribute', 'ABC', 'XYZ'],
    ['Attribute 1', 113, 144],
    ['Attribute 2', 115, 122],
    ['Attribute 3', 108, 84],
    ['Attribute 4', 154, 103],
    ['Attribute 5', 140, 111],
    ['Attribute 6', 117, 147],
]

const options2 = {
    title: 'nPLICIT Results',
    // hAxis: { title: 'Year', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 },
    colors: ['#283182', '#4273F0'],
    'legend': { position: 'right', alignment: 'center', itemMargin: 30 },
    chartArea: { left: "6%", width: "78%" },
    annotations: {
        textStyle: {
            fontName: 'Arial',
            fontSize: 12,
            bold: true,
            color: 'green',
            auraColor: 'none',
        },
        alwaysOutside: true,
    },
}

function ImplicitResult({ implicitData }) {
    const { attributeNames, rows } = tableData;

    // console.log("implicitData?.graph", implicitData?.graph);

    return (
        <div className='resultImplicitResultsMainCon'>
            <div className='resultImplicitResultsSummaryCon'>
                <div className='resultImplicitResultsSummaryInnerCon'>
                    <p className='summaryTxtStyResult'>Summary</p>
                    <table className="table">
                        <thead className='tableHeadingSty'>
                            <tr>
                                <th></th>
                                {implicitData?.table?.attributeNames?.map((attributeName, index) => (
                                    <th key={index}>{attributeName}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {implicitData?.table?.rows?.map((rowData, index) => (
                                <tr key={index}>
                                    <th className="header">{rowData.rowHeader}</th>
                                    {rowData.attributes.map((attribute, index) => (
                                        <td key={index}>{attribute}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='resultImplicitResultsHistogramCon'>
                <Chart
                    width={'92%'}
                    height={'99%'}
                    chartType="ColumnChart"
                    data={implicitData?.graph}
                    options={options2}
                />
                
            </div>

        </div>
    )
}
export default ImplicitResult

