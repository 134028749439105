
import React, { useEffect, useState, useRef } from 'react'
import { Stack, Tab, Tabs } from 'react-bootstrap'
import pataImg from '../../assets/pataImg.png'
import threePati from '../../assets/threePati.png'
import tickIcon from '../../assets/tickIcon.png'
import crossIcon from '../../assets/crossIcon.png'
import waitingIcon from '../../assets/waitingIcon.png'
import keyboardImg from '../../assets/keyboardImg.png'
import Image from 'react-bootstrap/Image'
import Cookies from 'universal-cookie'
import Axios from 'axios';
import PracticeStudy2 from './PracticeStudy2'
// import BeforePractice3 from './BeforePractice3'
import PracticeStudy3 from './PracticeStudy3'
import ImageLoader from '../../Components/ImageLoader'

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}

var domainUrl = process.env.REACT_APP_API_URL;
var studySaveUrl = process.env.REACT_APP_API_URL + apiVersion + "study/answer/";
var getStudyPairing = process.env.REACT_APP_API_URL + apiVersion + "pairingscreen/studies/";
var isUserHaveKeyboard = true;

var startTime = null
var endTime = null

var userAnswer = false;
var correctAnswers = [];
var lengthMaxWrong = 0;
var firstTimeStudy = true;
var showBrands = true;

const totalImagesCount = 3;

var loadedImagesCount = 0;

function ParticpantStudy({ fullStudyData, currentTab, setCurrentTab, surveyIncluded, setErrMsg, setShowToast, studyID, userID }) {

    const [currentStudyTab, setCurrentStudyTab] = useState(0);
    const [practiseStudyTab, setPracticeStudyTab] = useState(0);
    const [realStudyTab, setRealStudyTab] = useState(0);
    const [studyPairing, setStudyPairing] = useState([]);
    const [practiseAnswerKey, setPractiseAnswerKey] = useState('');
    const [count, setCount] = useState(5);
    const [showTimeOutIcon, setShowTimeoutIcon] = useState(false);
    const [wrongAnsArr, setWrongAnsArr] = useState([]);

    const [studyAnswer, setStudyAnswer] = useState([]);
    const [showTarget, setShowTarget] = useState(false);
    const [showPrime, setShowPrime] = useState(false);
    const [userPressedKey, setUserPressedKey] = useState('');
    const [before3Tab, setBefore3Tab] = useState(0);
    const [before3Prime, setBefore3Prime] = useState(false);
    const [before3Third, setBefore3Third] = useState(false);

    const userPressedKeyRef = useRef(userPressedKey);
    const practiseStudyTabRef = useRef(practiseStudyTab);
    const currentStudyTabRef = useRef(currentStudyTab);
    const showTargetRef = useRef(showTarget);
    const realStudyTabRef = useRef(realStudyTab);


    // console.log("fullStudyData.studyTime", fullStudyData.studyTime);

    const _setRealStudyTab = data => {
        realStudyTabRef.current = data;
        setRealStudyTab(data);
    };

    const _setUserPressedKey = data => {
        userPressedKeyRef.current = data;
        setUserPressedKey(data);
    };

    const _setPracticeStudyTab = data => {
        practiseStudyTabRef.current = data;
        setPracticeStudyTab(data);
    };

    const _setCurrentStudyTab = data => {
        currentStudyTabRef.current = data;
        setCurrentStudyTab(data);
    };

    const _setShowTarget = data => {
        showTargetRef.current = data;
        setShowTarget(data);
    };

    const newCookies = new Cookies();


    const calculateUserResponseTime = (typeOfAnswer) => {
        let responseTime
        if (typeOfAnswer === "systemAnswer") {
            responseTime = 1000 * fullStudyData.studyTime.delayBeforeNextPairing
        } else {
            if (startTime && endTime) {
                responseTime = endTime - startTime; // Difference in milliseconds
            }
        }
        return responseTime
    }

    //console.log("fullStudyData.studyTime=>", fullStudyData.studyTime);

    const calculateTime = (name) => {
        let newTime = '';
        if (name === "primeAttribute") {
            newTime = (1000 * fullStudyData.studyTime.primeAttribute);
        } else if (name === "timeBetweenAttribute") {
            newTime = ((1000 * fullStudyData.studyTime.timeBetweenAttribute) + (1000 * fullStudyData.studyTime.primeAttribute));
        } else if (name === "target") {
            // let prevTime = ((1000 * fullStudyData.studyTime.timeBetweenAttribute) + (1000 * fullStudyData.studyTime.primeAttribute));
            // newTime = ((1000 * fullStudyData.studyTime.target) + prevTime);
            newTime = (1000 * fullStudyData.studyTime.target);
        } else if (name === "delayBeforeNextPairing") {
            // let trgAtr = ((1000 * fullStudyData.studyTime.primeAttribute) + (1000 * fullStudyData.studyTime.target) + (1000 * fullStudyData.studyTime.timeBetweenAttribute));
            // newTime = ((1000 * fullStudyData.studyTime.delayBeforeNextPairing) + trgAtr);
            newTime = (1000 * fullStudyData.studyTime.delayBeforeNextPairing);
        } else if (name === "numberOfExposures") {
            newTime = (1000 * fullStudyData.studyTime.numberOfExposures);
        }
        return newTime;
    }

    useEffect(() => {
        getPairs()
    }, [])

    const getPairs = async () => {
        await Axios.get(
            getStudyPairing + studyID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    setStudyPairing(response?.data?.data);
                    // console.log("Study Pairing length===>", response?.data?.data.length);
                } else {
                    setErrMsg(response.data.message);
                    setShowToast(true);
                }
            }).catch(error => {
                setErrMsg(error.response.data.message);
                setShowToast(true);
            });

        getSavedAnswers()
    }

    const getSavedAnswers = async () => {
        const newCookies = new Cookies();
        await Axios.get(
            studySaveUrl + studyID + "/" + userID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                // setStudyPairing(response?.data?.studypairing)
            }).catch(error => {
                // console.log("get save answer api error==>", error);
            });
    }

    const saveUserAnswers = async (studyAnswer, isStudyCompleted) => {
        if (studyAnswer?.length > 0) {
            var studyAnswers = {
                study: studyAnswer,
                userId: userID
            }

            // console.log("studyAnswers===>", studyAnswers);

            // if (realStudyTabRef.current === studyPairing.length - 1) {
            //     if (!surveyIncluded) {
            //         studyAnswers["status"] = "completed"
            //     }
            //     studyAnswers["studyStatus"] = "Completed"
            // }

            if (isStudyCompleted) {
                if (!surveyIncluded) {
                    studyAnswers["status"] = "completed"
                }
                studyAnswers["studyStatus"] = "Completed"
            }

            const newCookies = new Cookies();
            await Axios.post(
                studySaveUrl + studyID,
                studyAnswers,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                    }
                }).then(response => {
                    // console.log("api response===>", response)
                }).catch(error => {
                    console.log("api error==>", error)
                })
            startTime = null
            endTime = null
        }
    }

    if (count === 0 && currentStudyTab === 3) {
        _setCurrentStudyTab(currentStudyTab + 1);
    }

    if (currentTab === 4 && currentStudyTab === 8) {
        setTimeout(() => {
            setCurrentTab((prev) => prev + 1);
        }, 1500)
    }

    useEffect(() => {
        let intervalId;
        // if (currentStudyTab === 3) {
        //     if (practiseStudyTab >= 0 && practiseStudyTab <= 5) {
        //         // intervalId = setInterval(() => {
        //         //     _setPracticeStudyTab(practiseStudyTab + 1);
        //         // }, 1000);
        //     }
        // }

        // if (practiseStudyTab === 3 && practiseAnswerKey === '') {
        //     // clearInterval(intervalId);
        // }

        if (practiseStudyTab === 6) {
            intervalId = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount > 0) {
                        return prevCount - 1
                    } else {
                        clearInterval(intervalId);
                        return prevCount;
                    }
                });
            }, 1000);
        }

        let primeShow, primeHide, targetShow, showTimeOut, saveAnswerTime;
        if (currentStudyTab === 7) {
            if (realStudyTabRef.current <= (studyPairing.length - 1)) {
                // yhn py show brands

                primeShow = setTimeout(() => {
                    setShowPrime(true);
                    // }, 1000)
                }, calculateTime("primeAttribute"))

                targetShow = setTimeout(() => {
                    setShowPrime(false);
                    _setShowTarget(true);
                    startTime = new Date()
                    startTargetTimeoutFun()
                }, calculateTime("timeBetweenAttribute"));
                // }, 2000);

                // showTimeOut = setTimeout(() => {
                //     if (!userAnswer) {
                //         if (studyPairing && studyPairing[realStudyTabRef.current] && studyPairing[realStudyTabRef.current].leftPair === studyPairing[realStudyTabRef.current].target) {
                //             handleSavedAnswer("k", "systemAnswer");
                //         } else {
                //             handleSavedAnswer("s", "systemAnswer");
                //         }
                //     }
                //     // }, 2800);
                // }, calculateTime("target"));
                // anas ny 0.8 set krna target

            } else {
                if (firstTimeStudy) {
                    const newArray = studyAnswer.filter(item => item.isCorrect === false)
                    let percentage = (newArray?.length / studyPairing.length) * 100
                    lengthMaxWrong = (studyPairing.length / 100) * 25
                    if (percentage > 25) {
                        saveUserAnswers(studyAnswer, true)
                        if (surveyIncluded) {
                            setCurrentTab((prev) => prev + 1);
                        } else {
                            setCurrentTab((prev) => prev + 3);
                        }
                    } else {
                        const correct = studyAnswer.filter(item => item.isCorrect === true)
                        correctAnswers = correct
                        setStudyPairing(newArray)
                        setStudyAnswer([])
                        _setRealStudyTab(0)
                        setCurrentStudyTab(7);
                    }
                    firstTimeStudy = false
                    lengthMaxWrong = lengthMaxWrong - newArray.length
                } else {
                    const correct = studyAnswer.filter(item => item.isCorrect === true)
                    const mergedArray = correctAnswers.concat(correct);
                    correctAnswers = mergedArray
                    const wrongAns = studyAnswer.filter(item => item.isCorrect === false)
                    if (wrongAns.length > 0 && lengthMaxWrong > 0) {
                        if (lengthMaxWrong >= wrongAns.length) {
                            setStudyPairing(wrongAns)
                            setStudyAnswer([])
                            _setRealStudyTab(0)
                            setCurrentStudyTab(7);
                            lengthMaxWrong = lengthMaxWrong - wrongAns.length
                        } else {
                            let wrongAnsArr = wrongAns
                            const mergedArray = correctAnswers.concat(wrongAnsArr.slice(lengthMaxWrong));
                            correctAnswers = mergedArray
                            setStudyPairing(wrongAnsArr.slice(0, lengthMaxWrong))
                            setStudyAnswer([])
                            _setRealStudyTab(0)
                            setCurrentStudyTab(7)
                            lengthMaxWrong = lengthMaxWrong - lengthMaxWrong
                        }
                    } else {
                        const wrongAns = studyAnswer.filter(item => item.isCorrect === false)
                        const mergedArray = correctAnswers.concat(wrongAns);
                        correctAnswers = mergedArray
                        setStudyAnswer(mergedArray)
                        saveUserAnswers(mergedArray, true)
                        if (surveyIncluded) {
                            setCurrentTab((prev) => prev + 1);
                        } else {
                            setCurrentTab((prev) => prev + 3);
                        }
                    }
                }
            }
        }

        return () => {
            clearInterval(intervalId);
            clearTimeout(primeShow);
            clearTimeout(primeHide);
            clearTimeout(targetShow);
            //clearTimeout(showTimeOut);
            clearTimeout(saveAnswerTime);
        }

    }, [currentStudyTab, practiseStudyTab, practiseAnswerKey, realStudyTab]);


    const startTargetTimeoutFun = () => {

        if (studyPairing[realStudyTabRef.current].isTargetImage) {
            if (loadedImagesCount === 3) {
                setTimeout(() => {
                    if (!userAnswer) {
                        if (studyPairing && studyPairing[realStudyTabRef.current] && studyPairing[realStudyTabRef.current].leftPair === studyPairing[realStudyTabRef.current].target) {
                            handleSavedAnswer("k", "systemAnswer");
                        } else {
                            handleSavedAnswer("s", "systemAnswer");
                        }
                    }
                }, calculateTime("target"));
            }

        } else {

            setTimeout(() => {
                if (!userAnswer) {
                    if (studyPairing && studyPairing[realStudyTabRef.current] && studyPairing[realStudyTabRef.current].leftPair === studyPairing[realStudyTabRef.current].target) {
                        handleSavedAnswer("k", "systemAnswer");
                    } else {
                        handleSavedAnswer("s", "systemAnswer");
                    }
                }
                //  }, 800);
            }, calculateTime("target"));
        }
    }

    const handleAllImagesLoaded = () => {
        loadedImagesCount = loadedImagesCount + 1
        if (loadedImagesCount === 3) {
            startTargetTimeoutFun()
        }
    };


    const NextQuestion = () => {
        if (currentStudyTab === 2) {
            if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
                isUserHaveKeyboard = false;
            }
        }
        if (currentStudyTab <= 7) {
            if (isUserHaveKeyboard) {
                _setCurrentStudyTab(currentStudyTab + 1);
            } else {
                _setCurrentStudyTab(currentStudyTab + 3);
            }
        }
    }

    const PreviousQuestion = () => {
        if (currentStudyTab > 0) {
            if (currentStudyTab === 1 || currentStudyTab === 2) {
                setCurrentTab((prev) => prev - 1);
            } else {
                _setCurrentStudyTab(currentStudyTab - 1);
            }
        } else {
            setCurrentTab((prev) => prev - 1);
        }
    }

    const handleSavedAnswer = (keyPressed, typeOfAnswer) => {
        if (realStudyTabRef.current <= (studyPairing.length - 1)) {
            if (currentStudyTabRef.current === 7 && studyAnswer[realStudyTabRef.current] === undefined) {
                var stdyAns = studyAnswer;
                endTime = new Date()
                if (keyPressed === "s") {
                    if (studyPairing[realStudyTabRef.current].leftPair === studyPairing[realStudyTabRef.current].target) {
                        stdyAns.push(studyPairing[realStudyTabRef.current]);
                        stdyAns[realStudyTabRef.current].isCorrect = true;
                        stdyAns[realStudyTabRef.current].direction = keyPressed;
                        stdyAns[realStudyTabRef.current].reactionTime = calculateUserResponseTime(typeOfAnswer);
                        setStudyAnswer(stdyAns);
                    } else {
                        stdyAns.push(studyPairing[realStudyTabRef.current]);
                        stdyAns[realStudyTabRef.current].isCorrect = false;
                        stdyAns[realStudyTabRef.current].direction = keyPressed;
                        stdyAns[realStudyTabRef.current].reactionTime = calculateUserResponseTime(typeOfAnswer);
                        setStudyAnswer(stdyAns);
                    }
                } else if (keyPressed === "k") {
                    if (studyPairing[realStudyTabRef.current].rightPair === studyPairing[realStudyTabRef.current].target) {
                        stdyAns.push(studyPairing[realStudyTabRef.current]);
                        stdyAns[realStudyTabRef.current].isCorrect = true;
                        stdyAns[realStudyTabRef.current].direction = keyPressed;
                        stdyAns[realStudyTabRef.current].reactionTime = calculateUserResponseTime(typeOfAnswer);
                        setStudyAnswer(stdyAns);
                    } else {
                        stdyAns.push(studyPairing[realStudyTabRef.current]);
                        stdyAns[realStudyTabRef.current].isCorrect = false;
                        stdyAns[realStudyTabRef.current].direction = keyPressed;
                        stdyAns[realStudyTabRef.current].reactionTime = calculateUserResponseTime(typeOfAnswer);
                        setStudyAnswer(stdyAns);
                    }
                }
                saveUserAnswers(studyAnswer, false);
                if (typeOfAnswer === "systemAnswer") {
                    setShowTimeoutIcon(true)
                    userAnswer = false
                } else {
                    setShowTimeoutIcon(false)
                    userAnswer = true
                }

                setTimeout(() => {
                    setShowTimeoutIcon(false);
                    _setShowTarget(false)
                    showBrands = false
                    userAnswer = false
                    loadedImagesCount = 0
                    setTimeout(() => {
                        _setRealStudyTab(realStudyTabRef.current + 1);
                        showBrands = true
                        // }, 1000);
                    }, calculateTime("delayBeforeNextPairing"))

                    // }, 1000);
                }, calculateTime("delayBeforeNextPairing"))


            }
        } else {
            console.log("else 222");
            saveUserAnswers(studyAnswer, false);
            // if (surveyIncluded) {
            //     setCurrentTab((prev) => prev + 1);
            // } else {
            //     setCurrentTab((prev) => prev + 3);
            // }
        }
    }

    useEffect(() => {
        if (currentStudyTabRef.current === 7 && currentTab === 4) {
            if (showTargetRef.current) {
                if (userPressedKey === "s") {
                    handleSavedAnswer("s", "userAnswer");
                } else if (userPressedKey === "k") {
                    handleSavedAnswer("k", "userAnswer");
                }
            }
        }

        if (currentStudyTabRef.current === 0 && userPressedKey === "ArrowLeft") {
            setCurrentTab(prev => prev - 1);
        } else if (currentStudyTabRef.current > 0 && currentStudyTabRef.current < 3 && userPressedKey === "ArrowLeft") {
            _setCurrentStudyTab(prev => prev - 1);
        }

        if (currentStudyTabRef.current === 3) {
            if (userPressedKey === "s") {
                practiceNext('s')
            } else if (userPressedKey === "k") {
                practiceNext('k')
            } else if (userPressedKey === " ") {
                practiceNext('space')
            }
        }
        if (currentStudyTabRef.current === 5) {
            if (userPressedKey === " ") {
                practiceBefore3('space')
            } else if (userPressedKey === "ArrowLeft") {
                practiceBefore3('ArrowLeft')
            }
        }

        setUserPressedKey("")
    }, [userPressedKey])

    const practiceBefore3 = (key) => {
        if (key === "space") {
            if (before3Tab === 0) {
                setBefore3Tab(1)
                setBefore3Prime(true)
            }
            if (before3Tab === 1 && before3Prime === true) {
                setBefore3Prime(false)
                setBefore3Third(true)
                setBefore3Tab(2)
            }
            if (before3Tab === 2 && before3Third === true) {
                _setCurrentStudyTab(currentStudyTab + 1);
            }
        } else if (key === "ArrowLeft") {
            if (before3Tab === 1 && before3Prime === true) {
                setBefore3Prime(false)
                setBefore3Third(false)
                setBefore3Tab(0)
            }
            if (before3Tab === 2 && before3Third === true) {
                setBefore3Prime(true)
                setBefore3Third(false)
                setBefore3Tab(1)

            }
        }
    }

    const practiceNext = (key) => {
        if ((practiseStudyTabRef.current === 0 || practiseStudyTabRef.current === 5) && key !== 'space') {
            _setPracticeStudyTab(practiseStudyTab + 1);
        }
        if ((practiseStudyTabRef.current === 1 || practiseStudyTabRef.current === 2 || practiseStudyTabRef.current === 4) && key === 'space') {
            _setPracticeStudyTab(practiseStudyTab + 1);
        }
        if (practiseStudyTabRef.current === 3) {
            if (practiseAnswerKey === '' && key === 's') {
                setPractiseAnswerKey('s')
                setTimeout(() => {
                    setPractiseAnswerKey("tickHide")
                }, 1000);
            } else if ((practiseAnswerKey === 's' || practiseAnswerKey === 'tickHide') && key === 'k') {
                setPractiseAnswerKey('k')
            } else if (practiseAnswerKey === 'k' && key === 'space') {
                _setPracticeStudyTab(practiseStudyTab + 1);
            }
        }
    }


    const handleKeyDown = (event) => {
        _setUserPressedKey(event.key)
    }


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, []);


 

    return (
        <div className='pf_study_inner_cstm pf_cstm_screener_main'>
            {(currentStudyTab === 3 && practiseStudyTab <= 4) &&
                <div className='topBarCon'>
                    <div key={"topBar" + 0} className="topBarQuestion" />
                    <div key={"topBar" + 1} className={(1 === practiseStudyTab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                    <div key={"topBar" + 2} className={(2 === practiseStudyTab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                    <div key={"topBar" + 3} className={(3 === practiseStudyTab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                    <div key={"topBar" + 4} className={(4 === practiseStudyTab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                </div>
            }
            {(currentStudyTab === 5) &&
                <div className='topBarCon'>
                    <div key={"topBar" + 0} className={(0 === before3Tab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                    <div key={"topBar" + 1} className={(1 === before3Tab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                    <div key={"topBar" + 1} className={(2 === before3Tab) ? "topBarQuestionActive" : 'topBarQuestion'} />
                </div>
            }
            {(currentStudyTab === 7) &&
                <div className='stdy_tb_5 cstm_line_top' >
                    <div className='cstm_Tp_ln' style={{ width: ((realStudyTab / studyPairing.length) * 100) + "%" }}></div>
                </div>
            }
            <div className='BoxCon pf_screener_ques'>
                <Tabs activeKey={currentStudyTab} id="pf_sq_cstm">
                    {currentStudyTab === 0 &&
                        <Tab eventKey={0} title="" disabled={currentStudyTab !== 0} tabClassName="pf_st_first_tb" >
                            <div className='pf_st_first'>
                                <div className='BoxCon'>
                                    <div className='Box'>
                                        <p className='beforeStartText'>Before you start...</p>
                                        <p className='taskDesignTxt'>Read each page carefully. Use the left arrow key if you need to go back or miss an instruction. <strong>Do not use the back button on your browser.</strong> </p>
                                        <div className='bottomConbtn'>
                                            <Stack gap={2} className="col-md-5 mx-auto">
                                                <button className="Exitbutton text-center" onClick={() => NextQuestion()}>Next Instruction</button>
                                                {/* <button className="Nextbutton text-center" onClick={() => PreviousQuestion()}>Click Here to Exit</button> */}
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 1 &&
                        <Tab eventKey={1} title="" disabled={currentStudyTab !== 1} tabClassName="pf_st_second" >
                            <div className='pf_st_first'>
                                <div className='BoxCon'>
                                    <div className='Box'>
                                        <p className='beforeStartText'>Before you start...</p>
                                        <p className='taskDesignTxt'>This task is designed for devices with a physical keyboard. <span style={{ fontWeight: 700, fontSize: 20 }}>If your device does not have a keyboard attached, attach one now.</span></p>
                                        <div className='bottomConbtn'>
                                            <Stack gap={2} className="col-md-5 mx-auto">
                                                <button className="Exitbutton text-center" onClick={() => NextQuestion()}>Next Instruction</button>
                                                {/* <button className="Nextbutton text-center" onClick={() => PreviousQuestion()}>Click Here to Exit</button> */}
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 2 &&
                        <Tab eventKey={2} title="" disabled={currentStudyTab !== 2} tabClassName="pf_st_third" >
                            <div className='pf_st_first'>
                                <div className='BoxCon'>
                                    <div className='Box'>
                                        <p className='beforeStartText'>Before you start...</p>
                                        <p className='taskDesignTxt'>There are 3 brief sets of instructions. After each set, you will get a chance to practice the task. <span style={{ fontWeight: 700, fontSize: 20 }}>Please read the instructions carefully.</span> Once you’re ready, click begin.</p>
                                        <div className='bottomConbtn'>
                                            <Stack gap={2} className="col-md-5 mx-auto">
                                                <button className="Exitbutton text-center" onClick={() => NextQuestion()}>Click here to begin</button>
                                                {/* <button className="Nextbutton text-center" onClick={() => PreviousQuestion()}>Click Here to Exit</button> */}
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 3 &&
                        <Tab eventKey={3} title="" disabled={currentStudyTab !== 3} tabClassName="pf_st_practise_tab" >
                            <div className='pf_st_prtc_tb'>
                                {practiseStudyTab === 0 ?
                                    <div className='BoxConPhase1'>
                                        <div className='leftConPhase1'>
                                            <p className='leftTextUpper'>To begin, place your fingers on <span className='leftTextBold'>S</span> and <span className='leftTextBold'>K.</span> <br></br> <br></br> Click either key to begin the instructions.</p>
                                        </div>
                                        <div className='RightConPhase1'>
                                            <Image src={keyboardImg} className="keyboardImgsty" alt="logo" />
                                        </div>
                                    </div>
                                    :
                                    practiseStudyTab === 1 ?
                                        <div className='BoxConPhase1'>
                                            <div className='leftConPhase1'>
                                                <p className='leftTextUpper'>You will see two objects at the top of the screen. <br></br> <br></br> The objects may be <span className='leftTextBold'>pictures</span> or <span className='leftTextBold'>words.</span> <br></br> <br></br> Click the spacebar to advance the instructions.</p>
                                            </div>
                                            <div className='RightConPhase1'>
                                                <div className='upperImgCon'>
                                                    <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                    <Image src={threePati} className="RightSideImg" alt="logo" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        practiseStudyTab === 2 ?
                                            <div className='BoxConPhase1'>
                                                <div className='leftConPhase1'>
                                                    {/* <p className='leftTextUpper'>Another object will appear in the middle of the screen. <br></br> <br></br> You have to <span className='leftTextBold'>match this object with the correct one above.</span> <br></br> <br></br> Click spacebar to begin the instructions. </p> */}
                                                    <p className='leftTextUpper'>Another object will appear in the middle of the screen. <br></br> <br></br> Please match the object in the middle with the identical one in either corner by pressing the <span className='leftTextBold'>S</span> key to match the one on the <span className='leftTextBold'>LEFT</span>, or <span className='leftTextBold'>K</span> to match the one on the <span className='leftTextBold'>RIGHT.</span> <br></br> <br></br> Click the spacebar to advance the instructions. </p>
                                                </div>
                                                <div className='RightConPhase1'>
                                                    <div className='upperImgCon'>
                                                        <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                        <Image src={threePati} className="RightSideImg" alt="logo" />
                                                    </div>
                                                    <div className='MidImgCon'>
                                                        <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            practiseStudyTab === 3 ?
                                                <div className='BoxConPhase1'>
                                                    <div className='leftConPhase1'>
                                                        {practiseAnswerKey === '' ?
                                                            <p className='leftTextUpper'>In this instance, the correct match is the left object. Press <span className='leftTextBold'>S</span> to try it out.</p>
                                                            : practiseAnswerKey === 's' || practiseAnswerKey === 'tickHide' ?
                                                                <p className='leftTextUpper'> Perfect! <br></br> <br></br> If you are to press <span className='leftTextBold'>K</span>, you will notice a red X that indicates an incorrect response. Try pressing <span className='leftTextBold'>K</span> now.</p>
                                                                :
                                                                <p className='leftTextUpper3rd'><br></br> Click the spacebar to advance the instructions. </p>}
                                                    </div>
                                                    <div className='RightConPhase1'>
                                                        <div className='upperImgCon'>
                                                            <div className='SandImgCon'>
                                                                <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                                <div className='StxtBoxStyleLight' style={{ backgroundColor: practiseAnswerKey === 's' ? "#C7D4F2" : "#4273F0" }}>
                                                                    <p className='STextStyle'>S</p>
                                                                </div>
                                                            </div>
                                                            <div className='SandImgCon'>
                                                                <Image src={threePati} className="RightSideImg" alt="logo" />
                                                                <div className='StxtBoxStyleLight' style={{ backgroundColor: practiseAnswerKey === 'k' ? "#C7D4F2" : "#4273F0" }}>
                                                                    <p className='STextStyle'>K</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='MidImgCon'>
                                                            <div className='MidImgTickCon'>
                                                                {practiseAnswerKey === "k" ?
                                                                    <Image src={crossIcon} className="tickIconImg" alt="logo" />
                                                                    : practiseAnswerKey === "s" ?
                                                                        <Image src={tickIcon} className="tickIconImg" alt="logo" />
                                                                        : null
                                                                }
                                                                <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                                {/* <Image src={practiseAnswerKey === 's' ? pataImg : practiseAnswerKey === 'tickHide' ? pataImg : threePati} className="leftSideImg" alt="logo" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                practiseStudyTab === 4 ?
                                                    <div className='BoxConPhase1'>
                                                        <div className='leftConPhase1'>
                                                            <p className='leftTextUpper3rd'><span className='leftTextBold3rd'>Go as fast as you can! </span> <br></br> <br></br> If your response is too slow, you will see this red icon signifying an incorrect response. <br></br> <br></br> Click the spacebar to advance the instructions. </p>
                                                        </div>
                                                        <div className='RightConPhase1'>
                                                            <div className='upperImgCon'>
                                                                <div className='SandImgCon'>
                                                                    <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                                    <div className='StxtBoxStyle'>
                                                                        <p className='STextStyle'>S</p>
                                                                    </div>
                                                                </div>
                                                                <div className='SandImgCon'>
                                                                    <Image src={threePati} className="RightSideImg" alt="logo" />
                                                                    <div className='StxtBoxStyle'>
                                                                        <p className='STextStyle'>K</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='MidImgCon'>
                                                                <div className='MidImgTickCon'>
                                                                    <Image src={waitingIcon} className="tickIconImgWaiting" alt="logo" />
                                                                    <Image src={pataImg} className="leftSideImg" alt="logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    practiseStudyTab === 5 ?
                                                        <div className='BoxConPhase1'>
                                                            <div className='leftConPhase1'>
                                                                <p className='leftTextUpper'>Let’s practice! <br></br> <br></br> Remember, keep your fingers on <span className='leftTextBold'>S</span> and <span className='leftTextBold'>K.</span> <br></br> <br></br> Press either key to start the coundown.</p>
                                                            </div>
                                                            <div className='RightConPhase1'>
                                                                <Image src={keyboardImg} className="keyboardImgsty" alt="logo" />
                                                            </div>
                                                        </div>
                                                        :
                                                        practiseStudyTab === 6 ?
                                                            <div className='cstm_box_counters'>
                                                                <div className='leftConPhase1'>
                                                                    <div className='countdown_stdy_inner'>
                                                                        <span className='auto_comp_component'>{count}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ""
                                }
                            </div>
                        </Tab>
                    }

                    {currentStudyTab === 4 &&
                        <Tab eventKey={4} title="" disabled={currentStudyTab !== 4} tabClassName="pf_st_practise_tab_target" >
                            <div className='pf_st_practise_tab_target_main'>
                                <PracticeStudy2 setCurrentStudyTab={_setCurrentStudyTab} />
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 5 &&
                        <Tab eventKey={5} title="" disabled={currentStudyTab !== 5} tabClassName="pf_st_practise_tab_prime" style={{ marginTop: -70 }} >
                            <div className='pf_st_prtc_tb'>
                                <div className='BoxConPhase1'>
                                    <div className='leftConPhase1'>
                                        {before3Tab === 0 ?
                                            <p className='leftTextUpper3rd'><span className='leftTextBold3rd'>Great job! </span> <br></br> <br></br>Now, we are going to add one more layer to the task. <br></br> <br></br> Again, you will see two objects at the top of the screen. <br></br> <br></br> The objects may be <span className='leftTextBold3rd'>pictures</span> or <span className='leftTextBold3rd'>words.</span> <br></br> <br></br>  Click the spacebar to advance the instructions.</p>
                                            : before3Tab === 1 && before3Prime ?
                                                <p className='leftTextUpper3rd'>This time, before the main object appears in the middle of the screen, <span className='leftTextBold3rd'>a different picture or word will appear. </span> <br></br> <br></br> Pay attention to that word or picture, <span className='leftTextBold3rd'>but don’t click anything yet. </span> <br></br> <br></br> Click the spacebar to advance the instructions. </p>
                                                : before3Tab === 2 && before3Third &&
                                                <p className='leftTextUpper3rd'>An object will appear shortly after, replacing the word on the screen. <br></br> <br></br> Your task is the same. <span className='leftTextBold3rd'>Match the main object with the correct one above. </span> <br></br> <br></br> Click the spacebar to advance the instructions. </p>}
                                        {/* <p className='leftTextUpper3rd'>An object will pop up milliseconds later, replacing the word on the screen. <br></br> <br></br> Your task is the same. <span className='leftTextBold3rd'>Match the main object with the correct one above. </span> <br></br> <br></br> Click the spacebar to advance the instructions. </p>} */}
                                    </div>
                                    <div className='RightConPhase1'>
                                        <div className='upperImgCon'>
                                            <div className='SandImgCon'>
                                                <Image src={pataImg} className="leftSideImg" alt="logo" />
                                            </div>
                                            <div className='SandImgCon'>
                                                <Image src={threePati} className="RightSideImg" alt="logo" />
                                            </div>
                                        </div>
                                        <div className='MidImgCon'>
                                            <div className='MidImgTickCon'>
                                                {before3Prime && <p className='target_txt_pairing'>{"Clever"}</p>}
                                                {before3Third && <Image src={pataImg} className="leftSideImg" alt="logo" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 6 &&
                        <Tab eventKey={6} title="" disabled={currentStudyTab !== 6} tabClassName="pf_st_practise_tab_prime" >
                            <div className='pf_st_practise_tab_prime_main'>
                                <PracticeStudy3 setCurrentStudyTab={_setCurrentStudyTab} />
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 7 &&
                        <Tab eventKey={7} title="" disabled={currentStudyTab !== 7} tabClassName="pf_st_rel_tab" >
                            <div className='study_rel pf_st_rel_tab_main'>
                                {studyPairing && studyPairing?.map((pairingData, ingx) => {
                                    if (realStudyTab === ingx) {
                                        return (
                                            <div className='BoxConPhase1' key={ingx + "gfxs"}>
                                                <div className='RightConPhase1'>
                                                    {showBrands ?
                                                        <div className='upperImgCon'>
                                                            {pairingData.isLeftPairImage ?
                                                                // <Image src={pairingData.leftPair} className="leftSideImg lft_img_pairing" alt="logo" />
                                                                <ImageLoader
                                                                    src={pairingData.leftPair}
                                                                    className="leftSideImg lft_img_pairing"
                                                                    alt="logo"
                                                                    onImageLoad={handleAllImagesLoaded}
                                                                />
                                                                :
                                                                <p className='lft_txt_pairing'>{pairingData.leftPair}</p>
                                                            }
                                                            {pairingData.isRightPairImage ?
                                                                // <Image src={pairingData.rightPair} className="RightSideImg right_img_pairing" alt="logo" />
                                                                <ImageLoader
                                                                    src={pairingData.rightPair}
                                                                    className="RightSideImg right_img_pairing"
                                                                    alt="logo"
                                                                    onImageLoad={handleAllImagesLoaded}
                                                                />
                                                                :
                                                                <p className='right_txt_pairing'>{pairingData.rightPair}</p>
                                                            }
                                                        </div> : null}
                                                    <div className='MidImgCon'>
                                                        {showTimeOutIcon === true ?
                                                            <Image src={waitingIcon} className="tickIconImgWaiting" alt="logo" />
                                                            :
                                                            userAnswer && <Image src={((studyAnswer[ingx]?.isCorrect)) ? tickIcon : crossIcon} className="tickIconImg" alt="logo" />
                                                        }
                                                        {pairingData.isPrimeImage ?
                                                            showPrime && <Image src={pairingData.prime} className="leftSideImg target_img_pairing" alt="logo" />
                                                            :
                                                            showPrime && <p className='target_txt_pairing'>{pairingData.prime}</p>
                                                        }
                                                        {pairingData.isTargetImage ?
                                                            showTarget &&
                                                            // <Image src={pairingData.target} className="leftSideImg target_img_pairing" alt="logo" />
                                                            <ImageLoader
                                                                src={pairingData.target}
                                                                //src={"https://plus.unsplash.com/premium_photo-1679929943646-2ff1991285db?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                                                                className="leftSideImg target_img_pairing"
                                                                alt="logo"
                                                                onImageLoad={handleAllImagesLoaded}
                                                            />
                                                            :
                                                            showTarget && <p className='target_txt_pairing'>{pairingData.target}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </Tab>
                    }
                    {currentStudyTab === 8 &&
                        <Tab eventKey={8} title="" disabled={currentStudyTab !== 8} tabClassName="pf_st_noKeybord_tab" >
                            <div className='BoxConPhase1 pf_st_noKeybord_tab_main'>
                                <div className='leftConPhase1'>
                                    <p className='Phase1TxtStyle'>PLEASE CONNECT A KEYBOARD</p>
                                    <p className='leftTextUpper'>To begin, place your fingers on <span className='leftTextBold'>S</span> and <span className='leftTextBold'>K.</span> <br></br> <br></br> <span className='leftTextBold'>Click either key to begin the instructions.</span></p>
                                </div>
                                <div className='RightConPhase1'>
                                    <Image src={keyboardImg} className="keyboardImgsty" alt="logo" />
                                </div>
                            </div>
                        </Tab>
                    }
                </Tabs>
            </div>
            {(currentStudyTab === 0 || currentStudyTab === 1 || currentStudyTab === 2 || currentStudyTab >= 3) ? "" :
                <div className='bottomConScreenerQuestion'>
                    <button onClick={() => PreviousQuestion()} className="Exitbutton">PREVIOUS</button>
                    <button onClick={() => NextQuestion()} className="NextbuttonActive" style={{ backgroundColor: "#4273F0" }}>NEXT</button>
                </div>
            }
        </div>
    )
}



export default ParticpantStudy
