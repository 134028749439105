import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Container, Stack } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ShowMainNav } from '../../Components/Context';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var generateGroupLinksUrl = process.env.REACT_APP_API_URL + apiVersion + "third-party-link/";
var verifyIdUrl = process.env.REACT_APP_API_URL + apiVersion + "verify/group/";

function GroupParticipant() {
    const [verifyId, setVerifyId] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [showToast, setShowToast] = useState("");
    const [numOfParticipant, setNumOfParticipant] = useState(1);

    const {groupID} = useParams();
    const { setShowNav } = useContext(ShowMainNav);

    useEffect(()=>{
        setShowNav(false);
        const newCookies = new Cookies();
        Axios.get(
        verifyIdUrl + groupID,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${newCookies.get("neurojwt")}`
            }
        }).then(response => {
            if (response && response.data.status) {
                setVerifyId(true);
            } else {
                setErrMsg(response.data.message);
            }
        }).catch(error => {
            setErrMsg(error.response.data.message);
        });
    },[]);

    const generateGroupLinksApi = async () => {
        const newCookies = new Cookies();
        var dataObj = {
            number_of_participants_to_create: numOfParticipant
        }
        if(numOfParticipant>0){
            await Axios.post(
                generateGroupLinksUrl+groupID,
                dataObj,
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`,
                        'Content-type': 'application/json'
                    }
                }
            ).then(response => {
                const downloadUrl = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'file.csv'); // Set the desired file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(error => {
                setShowToast(true);
                if(error.response.status === 422){
                    setErrMsg("Please provide number of participants to create");
                }else{
                    setErrMsg("Error "+error.response.status+": There is issue with request");
                }
            })
        }else{
            setShowToast(true);
            setErrMsg("Number of participants must be greater then 0");
        }
    }
    
  return (
    <Container className='participant_fac_main_content'>
        {verifyId?
        <div className='pf_gpID'>
            <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
                <p>{errMsg}</p>
            </Alert>
            <div className='pf_gropID_pg'>
                <p>“Individual links” are links to the study with a unique participant ID. Generate individual links if you are planning to disseminate your nPLICIT study to a team of individuals by emailing each participant a unique link. Each link can only be used once. Specify the number of participants / links you need and click “generate individual links.</p>
                <Stack direction="vertical" gap={3}>
                    <input type="number"
                        className='num_of_participant_input'
                        // placeholder='Number of Participant'
                        value={numOfParticipant}
                        min="1"
                        onChange={(event) => setNumOfParticipant(event.target.value)} 
                    />
                    <Button className={"generate_Link_Btn mt-3"} variant="outline-secondary"
                        onClick={() => generateGroupLinksApi()}
                    >
                        GENERATE INDIVIDUAL LINKS
                    </Button>
                </Stack>
            </div>
        </div>
        :
        errMsg===""?
            <div className='cstm_loader'>
                <div className='lds-dual-ring'></div>
            </div>
            :
            <div className='participant_fac_main_cstm'>
                <p className='welcomeDesp'>{errMsg}</p>
            </div>
        }
    </Container>
  )
}

export default GroupParticipant