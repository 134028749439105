import { useEffect, useRef } from "react";
import { Image, Form, Stack } from 'react-bootstrap';
import cross from '../../assets/cross.png'
import copy from '../../assets/copy.png'
import deleteIcon from '../../assets/delete.png'
import Axios from 'axios';
import Cookies from 'universal-cookie';
import study_media from '../../assets/study_media.png';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var getSurvayApi = process.env.REACT_APP_API_URL + apiVersion + "studies";
var appImgUrl = process.env.REACT_APP_API_URL + apiVersion + "upload/file";
var domainUrl = process.env.REACT_APP_API_URL;
var delteImgUrl = process.env.REACT_APP_API_URL + apiVersion + "file/delete";

export default function Survay({ currentTab, createSurvay, setSurvay, setErrMsg, setShowToast, enblAddCond, tabName }) {


  const bottomEl = useRef(null);
  const cookies = new Cookies();

  useEffect(() => {

    if (localStorage.getItem("createStudyId") && localStorage.getItem("createSurvayId")) {
      Axios.get(
        getSurvayApi + "/" + localStorage.getItem("createStudyId") + "/survey",
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${cookies.get("neurojwt")}`
          }
        }).then(response => {
          if (response && response.data && response.data.status) {
            setSurvay(response.data.data);
          } else {
            setErrMsg(response.data.message);
            setShowToast(true);
          }
        }).catch(error => {
          if (error.response.status === 401) {
            window.location.href = "/login";
          }
          setErrMsg(error.response.data.message);
          setShowToast(true);
        });
    }

  }, []);


  const CloneSurvayBox = (index) => {
    var lstBox = createSurvay.surveyQuestionBox[index];
    var cloneBox = JSON.parse(JSON.stringify(lstBox));
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox.push(cloneBox);
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const RemoveSurvayBox = (name, index) => {
    if (name === "question") {
      var newcreateSurvayBox = createSurvay.surveyQuestionBox;
      if (newcreateSurvayBox.length > 1) {
        newcreateSurvayBox.splice(index, 1);
        setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox });
      } else {
        setErrMsg("You cannot delete the default Question");
        setShowToast(true);
      }
    } else {
      var newSurveyConditionsBox = createSurvay.surveyConditionsBox;
      if (newSurveyConditionsBox.length > 1) {
        newSurveyConditionsBox.splice(index, 1);
        setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyConditionsBox });
      } else {
        newSurveyConditionsBox[0].question = "";
        newSurveyConditionsBox[0].answer = "";
        newSurveyConditionsBox[0].condition = "";
        newSurveyConditionsBox[0].hideQuestion = "";
        newSurveyConditionsBox[0].otherCondition = [];
        // let lgt = newSurveyConditionsBox[0].otherCondition.length;
        // if(lgt>0){
        //   var gts = 0;
        //   for(gts; gts<lgt; gts++){
        //     newSurveyConditionsBox[0].otherCondition[gts].operator="";
        //     newSurveyConditionsBox[0].otherCondition[gts].question="";
        //     newSurveyConditionsBox[0].otherCondition[gts].condition="";
        //     newSurveyConditionsBox[0].otherCondition[gts].answer="";
        //   }
        // }
        setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyConditionsBox });
        // setErrMsg("You cannot delete the default Condition");
        // setShowToast(true);
      }
    }
  }

  const AddSurvayBox = () => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox.push({ question: "", quesMediaImg: "", questionType: "multiple_choice", forceRanking: false, singleRowRating: false, options: [{ value: "" }, { value: "" }], rows: [{ value: "" }], columns: [{ value: "" }] });
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
    setTimeout(() => {
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  const handleSurvayBoxQues = (event, index) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[index].question = event.target.value;
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const handleSurvayBoxMultiChoice = (event, index) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[index].questionType = event.target.value;
    // var lngth = newcreateSurvayBox[index].options.length;
    // if (lngth > 1) {
    //   newcreateSurvayBox[index].options.splice(1, lngth);
    // }
    // newcreateSurvayBox[index].options[0].value = "";
    // if (newcreateSurvayBox[index].questionType === "multiple_choice") {
    //   newcreateSurvayBox[index].options.push({ value: "" });
    // }

    var rlngth = newcreateSurvayBox[index].rows.length;
    var clngth = newcreateSurvayBox[index].columns.length;
    if (rlngth > 1) {
      newcreateSurvayBox[index].rows.splice(1, rlngth);
    }
    if (clngth > 1) {
      newcreateSurvayBox[index].columns.splice(1, clngth);
    }
    newcreateSurvayBox[index].rows[0].value = "";
    newcreateSurvayBox[index].columns[0].value = "";
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const handleSurvayBoxOptions = (event, boxIndx, optIndx) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[boxIndx].options[optIndx].value = event.target.value;
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const AddSurvayBoxQueOpt = (index) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[index].options.push({ value: "" });
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const RemoveSurvayBoxOption = (boxInd, optInd) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    if (newcreateSurvayBox[boxInd] && newcreateSurvayBox[boxInd].options.length > 1) {
      newcreateSurvayBox[boxInd].options.splice(optInd, 1);
      setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox });
    } else {
      setErrMsg("You cannot delete the default options");
      setShowToast(true);
    }
  }

  const handleSurvayBoxRow = (event, boxIndx, optIndx) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[boxIndx].rows[optIndx].value = event.target.value;
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }
  const handleSurvayBoxCol = (event, boxIndx, optIndx) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[boxIndx].columns[optIndx].value = event.target.value;
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const RemoveSurvayBoxRow = (boxInd, optInd) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    if (newcreateSurvayBox[boxInd] && newcreateSurvayBox[boxInd].rows.length > 1) {
      newcreateSurvayBox[boxInd].rows.splice(optInd, 1);
      setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox });
    } else {
      setErrMsg("You cannot delete the default Row");
      setShowToast(true);
    }
  }

  const RemoveSurvayBoxCol = (boxInd, optInd) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    if (newcreateSurvayBox[boxInd] && newcreateSurvayBox[boxInd].columns.length > 1) {
      newcreateSurvayBox[boxInd].columns.splice(optInd, 1);
      setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox });
    } else {
      setErrMsg("You cannot delete the default column");
      setShowToast(true);
    }
  }

  const AddSurvayBoxQueRow = (boxInd) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[boxInd].rows.push({ value: "" });
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const AddSurvayBoxQueCol = (boxInd) => {
    var newcreateSurvayBox = createSurvay.surveyQuestionBox;
    newcreateSurvayBox[boxInd].columns.push({ value: "" });
    setSurvay({ ...createSurvay, surveyQuestionBox: newcreateSurvayBox })
  }

  const AddNewConditionLogicOpt = (index) => {
    var newcreateSurvayBoxCond = createSurvay.surveyConditionsBox;
    newcreateSurvayBoxCond[index].otherCondition.push({ "operator": "and", "question": "", "condition": "", "answer": "" });
    setSurvay({ ...createSurvay, surveyConditionsBox: newcreateSurvayBoxCond })
  }


  const handleChangeLogicCond = (event, index) => {
    var newSurveyBoxCondition = createSurvay.surveyConditionsBox;
    if (event.target.name === "condQuestion") {
      newSurveyBoxCondition[index].question = event.target.value;
    } else if (event.target.name === "condQuestionCondition") {
      newSurveyBoxCondition[index].condition = event.target.value;
    } else if (event.target.name === "condAnswer") {
      newSurveyBoxCondition[index].answer = event.target.value;
    } else if (event.target.name === "hideCondQuestion") {
      newSurveyBoxCondition[index].hideQuestion = event.target.value;
    }
    setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyBoxCondition })
  }

  const handleOtherCondLogicData = (event, boxIndex, othrCondIndx) => {
    var newSurveyBoxCondition = createSurvay.surveyConditionsBox;
    if (event.target.name === "otherCondOperator") {
      newSurveyBoxCondition[boxIndex].otherCondition[othrCondIndx].operator = event.target.value;
    } else if (event.target.name === "otherCondQuestion") {
      newSurveyBoxCondition[boxIndex].otherCondition[othrCondIndx].question = event.target.value;
    } else if (event.target.name === "otherCondCondition") {
      newSurveyBoxCondition[boxIndex].otherCondition[othrCondIndx].condition = event.target.value;
    } else if (event.target.name === "otherCondAnswer") {
      newSurveyBoxCondition[boxIndex].otherCondition[othrCondIndx].answer = event.target.value;
    }
    setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyBoxCondition })
  }


  const cloneLogicCondTermBox = (boxIndex) => {
    var lstBox = createSurvay.surveyConditionsBox[boxIndex];
    var cloneBox = JSON.parse(JSON.stringify(lstBox));
    var newSurveyBoxCondition = createSurvay.surveyConditionsBox;
    newSurveyBoxCondition.push(cloneBox);
    setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyBoxCondition })
  }

  const AddNewLogicCondBox = () => {
    var newSurveyBoxCondition = createSurvay.surveyConditionsBox;
    newSurveyBoxCondition.push({
      "question": "",
      "condition": "",
      "answer": "",
      "otherCondition": []
    })
    setSurvay({ ...createSurvay, surveyConditionsBox: newSurveyBoxCondition })
  }

  const handleBtnChange = (event, index) => {
    var newSurveyQuestionBox = createSurvay.surveyQuestionBox;
    if (event.target.name === "forceRanking") {
      newSurveyQuestionBox[index].forceRanking = event.target.checked;
    } else if (event.target.name === "singleRowRating") {
      newSurveyQuestionBox[index].singleRowRating = event.target.checked;
    } else if (event.target.name === "quesMediaChk") {
      newSurveyQuestionBox[index].quesMediaChk = event.target.checked;
    }
    setSurvay({ ...createSurvay, surveyQuestionBox: newSurveyQuestionBox })
  }

  const updateSurveyData = async () => {
    var screenerData = {
      surveyQuesTitle: createSurvay.surveyQuesTitle,
      surveyQuestionBox: createSurvay.surveyQuestionBox,
      surveyConditionsBox: createSurvay.surveyConditionsBox,
      steper: tabName ? tabName : "",
    }
    setShowToast(false);
    var stdID = localStorage.getItem("createStudyId");
    if (stdID) {
      await Axios.post(
        process.env.REACT_APP_API_URL + apiVersion + "studies" + "/" + stdID + "/survey",
        screenerData,
        {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${cookies.get("neurojwt")}`
          }
        }).then(response => {
          if (response && response.data && response.data.status) {

          } else {
            setErrMsg(response.data.message);
            setShowToast(true);
          }
        }).catch(error => {
          if (error.response.status === 401) {
            window.location.href = "/login"
          }
          setErrMsg(error.response.data.message);
          setShowToast(true);
        });
    } else {
      setErrMsg("Study id not available create new study");
      setShowToast(true);
    }
  }

  const handleImage = async (event, index) => {
    const cookies = new Cookies();
    await Axios.post(
      appImgUrl,
      {
        file: event.target.files[0]
      },
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${cookies.get("neurojwt")}`
        }
      }).then(response => {
        if (response && response.data && response.data.status) {
          var newSurveyQuestionBox = createSurvay.surveyQuestionBox;
          newSurveyQuestionBox[index].quesMediaImg = response.data.filePath;
          setSurvay({ ...createSurvay, surveyQuestionBox: newSurveyQuestionBox });
          updateSurveyData();
        } else {
          setErrMsg(response.data.message);
          setShowToast(true);
        }
      }).catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
        setErrMsg(error.response.data.message);
        setShowToast(true);
      });

  }

  const handleRemoveImage = async (event, imagePath, index) => {
    await Axios.post(
      delteImgUrl,
      { filePath: imagePath },
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${cookies.get("neurojwt")}`
        }
      }).then(response => {
        if (response && response.data && response.data.status) {
          var newSurveyQuestionBox = createSurvay.surveyQuestionBox;
          newSurveyQuestionBox[index].quesMediaImg = "";
          setSurvay({ ...createSurvay, surveyQuestionBox: newSurveyQuestionBox });
          updateSurveyData();
        } else {
          setErrMsg(response.data.message);
          setShowToast(true);
        }
      }).catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
        setErrMsg(error.response.data.message);
        setShowToast(true);
      });
  }

  var survayData = '';
  if (currentTab === 8) {
    var scqAge = [];
    for (var k = 15; k <= 100; k++) {
      scqAge.push(k)
    }
    survayData = <div className="main_screener_v2">
      <div className={"wrapper " + ((createSurvay.surveyQuestionBox.length > 1 || createSurvay.surveyQuestionBox[0].options.length > 2 || createSurvay.surveyQuestionBox[0].rows.length > 2 || createSurvay.surveyQuestionBox[0].columns.length > 2) ? "screner_scroll_active" : "")}>
        {/* <div className='titleBox'>
          <input className='titleInput' value={createSurvay.surveyQuesTitle} onChange={(event) =>
            setSurvay({ ...createSurvay, surveyQuesTitle: event.target.value })
          } type="text" placeholder='Title...' />
        </div> */}
        {createSurvay.surveyQuestionBox.map((item, index) =>
          <div className='QuestionBox' key={"inx_" + index}>
            <div className="cstm_screener_media_op">
              <div className='cstm_witch_scenr_mdia'>
                <Stack direction="horizontal" gap={3}>
                  <label>Add Media</label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={item.quesMediaChk}
                    name="quesMediaChk"
                    label=""
                    onChange={(event) => handleBtnChange(event, index)}
                  />
                </Stack>
              </div>
              <div className='media_cstm_imges_study screnrMedia'>
                {item.quesMediaChk ?
                  <div className='stdy_md_img'>
                    {item.quesMediaImg ?
                      <div className='arr_img_media_brnd'>
                        <div className='cross_img_std_med'>
                          <Image src={cross} name="quesMediaImg" thumbnail={true} className='cross_sign_std'
                            onClick={(event) => handleRemoveImage(event, item.quesMediaImg, index)}
                          />
                        </div>
                        <img src={item.quesMediaImg} className="std_med_img_brnd" alt='med_image_1' />
                      </div> :
                      <div className="cstm_std_img_wrpr">
                        <div className={'media_study_cstm_img ' + (item.quesMediaImg ? "active" : "")}>
                          <label htmlFor={'std_media_v' + index}>
                            <Image src={study_media} className="study_med_img" />
                          </label>
                          <input type="file" accept="image/*" id={'std_media_v' + index} name="quesMediaImg"
                            onChange={(event) => handleImage(event, index)}
                            className='study_med_file_v1 hide_me' />
                        </div>
                      </div>
                    }
                  </div>
                  : ""}
              </div>
            </div>
            <div className='bottom-inner-con'>
              <input className='titleInput' type="text" name="question" value={item.question}
                onChange={(event) => handleSurvayBoxQues(event, index)} placeholder='Question...' />
              <select className='dropdown' name="questionType" value={item.questionType}
                onChange={(event) => handleSurvayBoxMultiChoice(event, index)}
              >
                <option value="multiple_choice">Multiple Choice</option>
                <option value="check_boxes">Check Boxes</option>
                <option value="matrix_rating_scale">Matrix/Rating Scale</option>
                <option value="text_box">Text Box</option>
              </select>
            </div>
            {item.questionType !== "text_box" ?
              <div className='ansDiv'>
                <div className='lineStyle'></div>
                {item.questionType === "matrix_rating_scale" ?
                  <div className="mtxrx_hder">
                    <h3 className="matr_row">Row</h3>
                  </div> : ""}
                <div className={"screener_bx_option " + item.questionType}>
                  {item.questionType === "matrix_rating_scale" ?
                    item.rows ? item.rows.map((option, idx) => (
                      <div className='radioCon' key={"id_" + idx}>
                        <div className='radioCon1'>
                          <input type="text" value={option.value} onChange={(event) => handleSurvayBoxRow(event, index, idx)} />
                          {idx > 0 ?
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveSurvayBoxRow(index, idx)}
                            />
                            : ""}
                        </div>
                      </div>
                    )) : "" :
                    item.options ? item.options.map((option, idx) => (
                      <div className='radioCon' key={"id_" + idx}>
                        <div className='radioCon1'>
                          <input type="text" value={option.value} onChange={(event) => handleSurvayBoxOptions(event, index, idx)} />
                          {idx > 0 && item.questionType !== "multiple_choice" ?
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveSurvayBoxOption(index, idx)}
                            />
                            : idx > 1 && item.questionType === "multiple_choice" &&
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveSurvayBoxOption(index, idx)}
                            />
                          }
                        </div>
                      </div>
                    )) : ""}
                  <Stack direction="horizontal" gap={3}>
                    <button className="add_screener_bx_option" type="btn" onClick={item.questionType === "matrix_rating_scale" ? () => AddSurvayBoxQueRow(index) : () => AddSurvayBoxQueOpt(index)}>{item.questionType === "matrix_rating_scale" ? "+ Add Row" : "+ Add Answer Choice"}</button>
                    {item.questionType === "matrix_rating_scale" ? "" :
                      <div className={'copyDeleteCon ms-auto ' + (item.questionType)}>
                        <div className="cp_dlt">
                          <Image src={copy} className="copy-img" alt="logo"
                            onClick={() => CloneSurvayBox(index)} />
                          <Image src={deleteIcon} className="copy-img" alt="logo"
                            onClick={() => RemoveSurvayBox("question", index)} />
                        </div>
                      </div>
                    }
                  </Stack>
                </div>
                {item.questionType === "matrix_rating_scale" ?
                  <div className="mtx_col">
                    <div className="mtxrx_hder">
                      <h3 className="matr_col">Column</h3>
                    </div>
                    {item.columns ? item.columns.map((option, idx) => (
                      <div className='radioCon' key={"id_" + idx}>
                        <div className='radioCon1'>
                          <input type="text" value={option.value} onChange={(event) => handleSurvayBoxCol(event, index, idx)} />
                          {idx > 0 ?
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveSurvayBoxCol(index, idx)}
                            />
                            : ""}
                        </div>
                      </div>
                    ))
                      : ""
                    }
                    <button className="add_screener_bx_option" type="btn" onClick={() => AddSurvayBoxQueCol(index)} >{item.questionType === "matrix_rating_scale" ? "+ Add Column" : "+ Add Answer Choice"}</button>
                  </div> : ""
                }
              </div>
              : ""}
            <div className={'copyDeleteCon ' + (item.questionType) + (item.questionType === "text_box" ? " mt-5" : "")}>
              {item.questionType === "matrix_rating_scale" ?
                <div className="btn_st">
                  <Stack direction="vertical" gap={3}>
                    {item.singleRowRating === false &&
                      <Form.Check
                        type="switch"
                        id="forceRanking"
                        checked={item.forceRanking}
                        name="forceRanking"
                        label="Force Response for Each Row"
                        onChange={(event) => handleBtnChange(event, index)}
                      />
                    }
                    {item.forceRanking === false &&
                      <Form.Check
                        type="switch"
                        id="singleRowRating"
                        checked={item.singleRowRating}
                        name="singleRowRating"
                        label="Make This a Single-Row Rating Scale"
                        onChange={(event) => handleBtnChange(event, index)}
                      />
                    }
                  </Stack>
                </div>
                : ""
              }
              {item.questionType === "matrix_rating_scale" || item.questionType === "text_box" ?
                <div className="cp_dlt ">
                  <Image src={copy} className={"copy-img"} alt="logo"
                    onClick={() => CloneSurvayBox(index)} />
                  <Image src={deleteIcon} className="copy-img" alt="logo"
                    onClick={() => RemoveSurvayBox("question", index)} />
                </div>
                : ""}
            </div>
          </div>
        )}

        <div className="buttonCon">
          <button onClick={AddSurvayBox} className={"button " + (enblAddCond ? " disable_active_btn " : "")} disabled={enblAddCond}>
            ADD QUESTION
          </button>
        </div>
        <div ref={bottomEl}></div>
      </div>
    </div>
  } else if (currentTab === 9) {
    survayData = <div className="main_screener_v3">
      <div className={"wrapper " + ((createSurvay.surveyConditionsBox.length > 1 || createSurvay.surveyConditionsBox[0].otherCondition.length > 0) ? "screner_scroll_active" : "")}>
        {createSurvay.surveyConditionsBox.map((item, index) =>
          <div className='QuestionBox' key={"ixsd_" + index}>
            <p className='hideText' style={{ marginTop: '4%' }}>Hide</p>
            <select className='dropdown1' name="hideCondQuestion" value={item.hideQuestion} onChange={(event) => handleChangeLogicCond(event, index)} style={{ marginBottom: '40px' }}>
              <option value="" style={{ display: "none" }}>Question</option>
              {createSurvay?.surveyQuestionBox?.length > 1 ?
                createSurvay?.surveyQuestionBox?.map((data, indx) =>
                  <option value={data.question} data_index={indx} key={"ixdds_" + indx}>{data.question}</option>
                )
                : null}
            </select>
            <p className='hideText' style={{ marginTop: '4%' }}>When</p>
            <div className='whenCon'>
              <select className='dropdown1' name="condQuestion" value={item.question} onChange={(event) => handleChangeLogicCond(event, index)} >
                <option value="" style={{ display: "none" }}>Question</option>
                {createSurvay.surveyQuestionBox ? createSurvay.surveyQuestionBox.map((data, indx) => {
                  if (data.questionType !== "text_box") {
                    return <option value={data.question} data_index={indx} key={"ixdsd_" + indx}>{data.question}</option>
                  } else {
                    return null;
                  }
                }
                ) : ""
                }
              </select>
              <select className='dropdown1' name="condQuestionCondition" value={item.condition} onChange={(event) => handleChangeLogicCond(event, index)}>
                <option value="">Condition</option>
                <option value="euqal">Equal</option>
                <option value="not_equal">Does Not Equal</option>
              </select>
              <select className='dropdown1' name="condAnswer" value={item.answer} onChange={(event) => handleChangeLogicCond(event, index)}>
                <option value="" style={{ display: "none" }}>Answer</option>
                {item.question ?
                  createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === item.question)] ?
                    createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === item.question)].questionType === "matrix_rating_scale" ?
                      createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === item.question)].rows.map((rowData, indst) => {
                        return (createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === item.question)].columns.map((innerData, indstu) =>
                          <option value={rowData.value + "_" + innerData.value} key={"qsped_" + indstu}>{rowData.value + "_" + innerData.value}</option>
                        ))
                      })
                      :
                      createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === item.question)].options.map((data, ind) =>
                        <option value={data.value} key={"ipd_" + ind}>{data.value}</option>
                      )
                    : ""
                  : ""}
              </select>
            </div>
            {item.otherCondition ? item.otherCondition.map((data, ind) =>
              <div className='whenCon' ds={item.otherCondition.length} style={{ marginTop: '3%' }} key={"dxs_" + ind}>
                <select className='dropdown1' style={{ width: '81px' }} name="otherCondOperator" value={data.operator} onChange={event => handleOtherCondLogicData(event, index, ind)}>
                  <option value="and">AND</option>
                  <option value="or">OR</option>
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondQuestion" value={data.question} onChange={event => handleOtherCondLogicData(event, index, ind)}>
                  <option value="" style={{ display: "none" }}>Question</option>
                  {createSurvay.surveyQuestionBox.map((ques, idp) => {
                    if (ques.questionType !== "text_box") {
                      return <option value={ques.question} key={data.condition + "ispdqg_" + (ind + 1)}>{ques.question}</option>
                    } else {
                      return null;
                    }
                  }
                  )}
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondCondition" value={data.condition} onChange={event => handleOtherCondLogicData(event, index, ind)}>
                  <option value="">Condition</option>
                  <option value="euqal">Equal</option>
                  <option value="not_equal">Does Not Equal</option>
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondAnswer" value={data.answer} onChange={event => handleOtherCondLogicData(event, index, ind)}>
                  <option value="" style={{ display: "none" }}>Answer</option>
                  {data.question ?
                    createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === data.question)] ?
                      createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === data.question)].questionType === "matrix_rating_scale" ?
                        createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === data.question)].rows.map((rowData, indst) => {
                          return (createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === data.question)].columns.map((innerData, indstu) =>
                            <option value={rowData.value + "_" + innerData.value} key={"qsdr_" + indstu}>{rowData.value + "_" + innerData.value}</option>
                          ))
                        })
                        :
                        createSurvay.surveyQuestionBox[createSurvay.surveyQuestionBox.findIndex((x) => x.question === data.question)].options.map((ans, ind) =>
                          <option value={ans.value} key={"ipdd_" + ind}>{ans.value}</option>
                        )
                      : ""
                    : ""}
                </select>
              </div>
            ) : ""}

            <div className="answer_del_flex">
              <label onClick={() => AddNewConditionLogicOpt(index)} className='addAnswer'>+ Add Statement</label>
              <div className='copyDeleteCon'>
                <Image src={copy} className="copy-img" alt="logo" onClick={() => cloneLogicCondTermBox(index)} />
                <Image src={deleteIcon} className="copy-img" alt="logo"
                  onClick={() => RemoveSurvayBox("condition", index)} />
              </div>
            </div>
          </div>
        )}
        <div className="buttonCon">
          <button onClick={AddNewLogicCondBox} className={"button " + (enblAddCond ? " disable_active_btn " : "")} disabled={enblAddCond} >
            ADD CONDITION
          </button>
        </div>

      </div>
    </div>
  }

  return (
    <div className="studies_cstm_main">
      {survayData}
    </div>
  );

}