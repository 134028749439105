import line_study from '../../assets/line_study.png';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container, Col, Form, Row, Tabs, Stack, Button, Tab, Image, Alert } from 'react-bootstrap';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import AddMedia from './AddMedia';
import Screener from './Screener';
import Survay from './Survay';
import { ShowMainNav } from '../../Components/Context';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var appUrl = process.env.REACT_APP_API_URL + apiVersion + "studies";
var appUrlStdyType = process.env.REACT_APP_API_URL + apiVersion + "study-types";
var appMediaUrl = process.env.REACT_APP_API_URL + apiVersion + "media/studies";
var cUrl = window.location.origin;

var generateLinksUrl = process.env.REACT_APP_API_URL + apiVersion + "participants/generate"
var generateGroupLinksUrl = process.env.REACT_APP_API_URL + apiVersion + "group/studies/"
var groupAppUrl = process.env.REACT_APP_CLIENT_URL + "/thirdparty/"

export default function NewCreateStudy() {
    let [searchParams] = useSearchParams();
    // console.log("urlStudyID", searchParams.get("studyId"), "screenerId", searchParams.get("screenerId"), "surveyId", searchParams.get("surveyId"));

    const [currentTab, setCurrentTab] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [errmsg, setErrMsg] = useState('Study Not Saved');
    const [typeStudy, setTypeStudy] = useState([]);
    const [numOfParticipant, setNumOfParticipant] = useState(1)
    const [numOfParticipantGroup, setNumOfParticipantGroup] = useState(1)
    const [advanceConfigMin, setAdvanceConfigMin] = useState("")
    const [createStudy, setCreateStudy] = useState({
        projectTitle: "",
        client: "",
        isIncludePostSurvey: false,
        redirectLink: "",
        externalLink: "",
        studyTypeId: "",
        mediaTextEnable: false,
        mediaBrand: [
            { text: "", image: "" },
            { text: "", image: "" }
        ],
        mediaAttr: [
            { text: "", image: "" },
            { text: "", image: "" },
            { text: "", image: "" },
            { text: "", image: "" },
            { text: "", image: "" },
            { text: "", image: "" }
        ],
        mediaImgEnable: false,
        previewCode: "",
        expireDate: "",
    })
    const [createScreener, setScreener] = useState({
        screenerCustomText: '',
        screenerQuesTitle: "",
        screenerQuesEnableAGR: false,
        screenerQuesAge: "",
        screenerQuesGender: "",
        screenerQuesRace: "",
        screenerQuestionBox: [
            { quesMediaChk: false, qualtrics: false, quesMediaImg: "", question: "", questionType: "multiple_choice", forceRanking: false, singleRowRating: false, options: [{ value: "" }, { value: "" }], rows: [{ value: "" }], columns: [{ value: "" }] }
        ],
        screenerConditionsBox: [
            { acceptanceScreenerCondition: false, question: "", condition: "", answer: "", otherCondition: [] }
        ]
    })
    const [createSurvay, setSurvay] = useState({
        surveyQuesTitle: " ",
        surveyQuestionBox: [
            { quesMediaChk: false, quesMediaImg: "", forceRanking: false, singleRowRating: false, question: "", questionType: "multiple_choice", options: [{ value: "" }, { value: "" }], rows: [{ value: "" }], columns: [{ value: "" }] }
        ],
        surveyConditionsBox: [
            { hideQuestion: "", question: "", condition: "", answer: "", otherCondition: [] }
        ]
    })
    const { setShowNav } = useContext(ShowMainNav);

    let navigate = useNavigate();
    const cookies = new Cookies();
    var nextBtnGo = true;

    useEffect(() => {
        setIdsValues()
        const newCookies = new Cookies();
        Axios.get(
            appUrlStdyType,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    if (response.data.data.screenerId) {
                        localStorage.setItem("createScreenerId", response.data.data.screenerId)
                    }
                    setTypeStudy(response.data.data);
                } else {
                    setErrMsg(response.data.message);
                    setShowToast(true);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/login";
                }
                setErrMsg(error.response.data.message);
                setShowToast(true);
            });

        if (localStorage.getItem("createStudyId")) {
            Axios.get(
                `${appUrl}${"/"}${localStorage.getItem("createStudyId")}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                    }
                }).then(response => {
                    if (response && response.data && response.data.status) {
                        setCreateStudy(response.data.data);
                        console.log("setCreateStudy===>", response.data.data);
                        if (localStorage.getItem("studyTab") && parseInt(localStorage.getItem("studyTab")) <= 10) {
                            if (response.data.data.isIncludePostSurvey === false && parseInt(localStorage.getItem("studyTab")) >= 7 && parseInt(localStorage.getItem("studyTab")) <= 9) {
                                localStorage.setItem("studyTab", currentTab);
                            } else {
                                setCurrentTab(parseInt(localStorage.getItem("studyTab")));
                            }
                        } else {
                            localStorage.setItem("studyTab", currentTab);
                        }
                    } else {
                        setErrMsg(response.data.message);
                        setShowToast(true);
                    }
                }).catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/login";
                    } else if (error.response.status === 500) {
                        // localStorage.removeItem("createStudyId");
                        // localStorage.removeItem("createScreenerId");
                        // localStorage.removeItem("createSurvayId");
                        // localStorage.removeItem("studyTab");
                        // window.location.href = "/create-study";
                        setErrMsg("Study Id is not Valid");
                    } else {
                        setErrMsg(error.response.data.message);
                        setShowToast(true);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIdsValues = () => {
        if (searchParams.get("studyId")) {
            localStorage.setItem("createStudyId", searchParams.get("studyId"));
        }
        if (searchParams.get("screenerId")) {
            localStorage.setItem("createScreenerId", searchParams.get("screenerId"));
        }
        if (searchParams.get("surveyId")) {
            localStorage.setItem("createSurvayId", searchParams.get("surveyId"));
        }
    }

    useEffect(() => {
        if ((currentTab >= 4 && currentTab <= 6) || (currentTab >= 8 && currentTab <= 9)) {
            setShowNav(false);
        } else {
            setShowNav(true);
        }
        if (currentTab === 10) {
            completeStudyApi()
        }
    }, [currentTab])

    const completeStudyApi = async () => {
        var authTocken = cookies.get("neurojwt")
        var tabName = getTabName(currentTab)
        var storageID = localStorage.getItem("createStudyId");
        var putData = {
            projectTitle: createStudy.projectTitle,
            client: createStudy.client,
            isIncludePostSurvey: createStudy.isIncludePostSurvey,
            redirectLink: createStudy.redirectLink,
            externalLink: createStudy.externalLink,
            studyTypeId: createStudy.studyTypeId,
            steper: tabName ? tabName : "",
            isCreatedStudyCompleted: true,
        }
        await Axios.put(
            appUrl + "/" + storageID,
            putData,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${authTocken}`
                }
            }).then(response => {
                console.log("response===>", response);
            }).catch(error => {
                console.log("Error: ", error);
            });
    }

    const handleNextPrevBtn = async (value) => {
        var chkStatus = false;
        var authTocken = cookies.get("neurojwt");
        var tabName = getTabName(currentTab);
        setShowToast(false);
        if (ValidateData()) {
            if (currentTab >= 0 && currentTab <= 1) {
                var storageID = localStorage.getItem("createStudyId");
                if (!storageID) {
                    var data = {
                        projectTitle: createStudy.projectTitle,
                        client: createStudy.client,
                        isIncludePostSurvey: createStudy.isIncludePostSurvey,
                        redirectLink: createStudy.redirectLink,
                        externalLink: createStudy.externalLink,
                        steper: tabName ? tabName : "",
                    }
                    await Axios.post(
                        appUrl,
                        data,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `Bearer ${authTocken}`
                            }
                        }).then(response => {
                            if (response && response.data && response.data.status) {
                                chkStatus = true;
                                localStorage.setItem("createStudyId", response.data.data._id);
                                setCreateStudy(response.data.data);
                            } else {
                                setErrMsg(response.data.message);
                                setShowToast(true);
                            }
                        }).catch(error => {
                            if (error.response.status === 401) {
                                window.location.href = "/login"
                            }
                            setErrMsg(error.response.data.message);
                            setShowToast(true);
                        });
                } else {
                    var putData = {
                        projectTitle: createStudy.projectTitle,
                        client: createStudy.client,
                        isIncludePostSurvey: createStudy.isIncludePostSurvey,
                        redirectLink: createStudy.redirectLink,
                        externalLink: createStudy.externalLink,
                        studyTypeId: createStudy.studyTypeId,
                        steper: tabName ? tabName : "",
                    }
                    await Axios.put(
                        appUrl + "/" + storageID,
                        putData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `Bearer ${authTocken}`
                            }
                        }).then(response => {
                            if (response && response.data && response.data.status) {
                                chkStatus = true;
                            } else {
                                setErrMsg(response.data.message);
                                setShowToast(true);
                            }
                        }).catch(error => {
                            if (error.response.status === 404) {
                                localStorage.removeItem("createStudyId");
                                localStorage.removeItem("createScreenerId");
                                localStorage.removeItem("createSurvayId");
                                localStorage.removeItem("studyTab");
                                setTimeout(() => {
                                    window.location.reload()
                                }, 2000);
                            } else if (error.response.status === 401) {
                                window.location.href = "/login"
                            }
                            setErrMsg(error.response.data.message);
                            setShowToast(true);
                        });
                }
            } else if (currentTab === 2) {

                updateMediaData();

                let attributeData = [...createStudy.mediaAttr]
                let newAttriData;
                if (createStudy.mediaImgEnable) {
                    newAttriData = attributeData?.filter((item) => item.image !== '')
                } else {
                    newAttriData = attributeData?.filter((item) => item.text !== '')
                }
                var mediaData = {
                    mediaTextEnable: createStudy.mediaTextEnable,
                    mediaBrand: createStudy.mediaBrand,
                    mediaAttr: newAttriData,
                    // mediaAttr: createStudy.mediaAttr,
                    mediaImgEnable: createStudy.mediaImgEnable,
                    steper: tabName ? tabName : "",
                }
                setShowToast(false);
                var locStorageID = localStorage.getItem("createStudyId");
                if (locStorageID) {
                    await Axios.post(
                        appMediaUrl + "/" + locStorageID,
                        mediaData,
                        {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': `Bearer ${authTocken}`
                            }
                        }).then(response => {
                            if (response && response.data && response.data.status) {
                                chkStatus = true;
                            } else {
                                setErrMsg(response.data.message);
                                setShowToast(true);
                            }
                        }).catch(error => {
                            if (error.response.status === 401) {
                                window.location.href = "/login"
                            }
                            setErrMsg(error.response.data.message);
                            setShowToast(true);
                        });
                } else {
                    setErrMsg("Study id not available create new study");
                    setShowToast(true);
                }
            } else if (currentTab >= 4 && currentTab <= 6) {
                var getStorageID = localStorage.getItem("createStudyId");
                if (!localStorage.getItem("createScreenerId")) {
                    var newData = {
                        screenerCustomText: createScreener.screenerCustomText,
                        steper: tabName ? tabName : "",
                    }
                    await Axios.post(
                        appUrl + "/" + getStorageID + "/screener",
                        newData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `Bearer ${authTocken}`
                            }
                        }).then(response => {
                            if (response && response.data && response.data.status) {
                                chkStatus = true;
                                localStorage.setItem("createScreenerId", response.data.data._id)
                            } else {
                                setErrMsg(response.data.message);
                                setShowToast(true);
                            }
                        }).catch(error => {
                            if (error.response.status === 401) {
                                window.location.href = "/login"
                            }
                            setErrMsg(error.response.data.message);
                            setShowToast(true);
                        });
                } else {
                    var updatedData = {
                        screenerCustomText: createScreener.screenerCustomText,
                        screenerQuesTitle: createScreener.screenerQuesTitle,
                        screenerQuesEnableAGR: createScreener.screenerQuesEnableAGR,
                        screenerQuesAge: createScreener.screenerQuesAge,
                        screenerQuesGender: createScreener.screenerQuesGender,
                        screenerQuesRace: createScreener.screenerQuesRace,
                        screenerQuestionBox: createScreener.screenerQuestionBox,
                        screenerConditionsBox: createScreener.screenerConditionsBox,
                        steper: tabName ? tabName : "",
                    }
                    await Axios.put(
                        appUrl + "/" + getStorageID + "/screener",
                        updatedData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': `Bearer ${authTocken}`
                            }
                        }).then(response => {
                            if (response && response.data && response.data.status) {
                                chkStatus = true;
                            } else {
                                setErrMsg(response.data.message);
                                setShowToast(true);
                            }
                        }).catch(error => {
                            if (error.response.status === 404) {
                                localStorage.removeItem("createStudyId");
                                localStorage.removeItem("createScreenerId");
                                localStorage.removeItem("createSurvayId");
                                localStorage.removeItem("studyTab");
                                setTimeout(() => {
                                    window.location.reload()
                                }, 2000);
                            } else if (error.response.status === 401) {
                                window.location.href = "/login"
                            }
                            setErrMsg(error.response.data.message);
                            setShowToast(true);
                        });
                }
            } else if (currentTab >= 8 && currentTab <= 9) {
                var newGetStorageID = localStorage.getItem("createStudyId");
                var survPstData = {
                    // surveyQuesTitle: createSurvay.surveyQuesTitle,
                    surveyQuesTitle: " ",
                    surveyQuestionBox: createSurvay.surveyQuestionBox,
                    surveyConditionsBox: createSurvay.surveyConditionsBox,
                    steper: tabName ? tabName : "",
                }
                await Axios.post(
                    appUrl + "/" + newGetStorageID + "/survey",
                    survPstData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${authTocken}`
                        }
                    }).then(response => {
                        if (response && response.data && response.data.status) {
                            chkStatus = true;
                            localStorage.setItem("createSurvayId", response.data.data._id)
                        } else {
                            setErrMsg(response.data.message);
                            setShowToast(true);
                        }
                    }).catch(error => {
                        if (error.response.status === 401) {
                            window.location.href = "/login"
                        }
                        setErrMsg(error.response.data.message);
                        setShowToast(true);
                    });

            } else if (currentTab === 3 || currentTab === 10) {
                chkStatus = true;
            }
        }
        if (value === "next") {
            if (chkStatus && currentTab < 10) {
                if (currentTab === 6 && createStudy.isIncludePostSurvey === false) {
                    localStorage.setItem("studyTab", currentTab + 4);
                    setCurrentTab((prev) => prev + 4);
                } else {
                    localStorage.setItem("studyTab", currentTab + 1);
                    setCurrentTab((prev) => prev + 1);
                }
            } else if (currentTab === 10) {
                localStorage.removeItem("createStudyId");
                localStorage.removeItem("createScreenerId");
                localStorage.removeItem("createSurvayId");
                localStorage.removeItem("studyTab");
                navigate("/");
            }
        } else {
            if (currentTab > 0) {
                if (currentTab > 6 && createStudy.isIncludePostSurvey === false) {
                    localStorage.setItem("studyTab", currentTab - 4);
                    setCurrentTab((prev) => prev - 4);
                } else {
                    setCurrentTab((prev) => prev - 1)
                    localStorage.setItem("studyTab", currentTab - 1);
                }
            } else {
                localStorage.removeItem("createStudyId");
                localStorage.removeItem("createScreenerId");
                localStorage.removeItem("createSurvayId");
                localStorage.removeItem("studyTab");
                navigate("/");
            }
        }

    }

    function isValidUrl(str) {
        try {
            const pattern = new RegExp(
                '^([a-zA-Z]+:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
                'i'
            );
            return pattern.test(str);
        } catch (err) {
            return false;
        }
    }

    function updateMediaData() {
        var newMediaBrand = createStudy.mediaBrand;
        var newMediaAttr = createStudy.mediaAttr;
        var gt = 0, lgt = createStudy.mediaBrand.length;
        var tp = 0, lngt = createStudy.mediaAttr.length;
        if (createStudy.mediaTextEnable) {
            for (gt; gt < lgt; gt++) {
                newMediaBrand[gt].image = "";
            }
        } else {
            for (gt; gt < lgt; gt++) {
                newMediaBrand[gt].text = "";
            }
        }
        if (createStudy.mediaImgEnable === false) {
            for (tp; tp < lngt; tp++) {
                newMediaAttr[tp].image = "";
            }
        } else {
            for (tp; tp < lngt; tp++) {
                newMediaAttr[tp].text = "";
            }
        }

        setCreateStudy({ ...createStudy, mediaBrand: newMediaBrand, mediaAttr: newMediaAttr });
    }

    const ValidateData = () => {
        if (currentTab === 0) {
            // if (createStudy.redirectLink !== "" && !isValidUrl(createStudy.redirectLink)) {
            //     setErrMsg("Redirect URL is not a valid URL");
            //     setShowToast(true);
            //     return false;
            // }
            // if (createStudy.externalLink !== "" && !isValidUrl(createStudy.externalLink)) {
            //     setErrMsg("External URL is not a valid URL");
            //     setShowToast(true);
            //     return false;
            // }
        }


        if (createStudy.mediaTextEnable === false) {
            var jp = 0, jpLng = createStudy.mediaBrand.length;
            var newStudyMediaBrand = createStudy.mediaBrand;
            for (jp; jp < jpLng; jp++) {
                newStudyMediaBrand[jp].text = "";
            }
            setCreateStudy({ ...createStudy, mediaBrand: newStudyMediaBrand });
        }

        if (createStudy.mediaImgEnable) {
            var at = 0, atLng = createStudy.mediaAttr.length;
            var newStudyMediaAttr = createStudy.mediaAttr;
            for (at; at < atLng; at++) {
                newStudyMediaAttr[at].text = "";
            }
            setCreateStudy({ ...createStudy, mediaAttr: newStudyMediaAttr });
        }


        return true;
    }

    const validateNextBtn = () => {
        if (currentTab === 0) {
            if (createStudy.projectTitle && createStudy.client) {
                nextBtnGo = false;
            }
        } else if (currentTab === 1) {
            if (createStudy.studyTypeId) {
                nextBtnGo = false;
            }
        } else if (currentTab === 2) {
            var chkBtnGoLft = false, chkBtnGoRgt = false;
            if (createStudy.mediaTextEnable) {
                var jp = 0, jpLng = createStudy.mediaBrand.length;
                let count = 0
                for (jp; jp < jpLng; jp++) {
                    if (createStudy.mediaBrand[jp].text && createStudy.mediaBrand[jp].text !== "") {
                        count++
                    }
                }
                if (count === jpLng) {
                    chkBtnGoLft = true
                } else {
                    chkBtnGoLft = false
                }
            } else {
                var jp = 0, jpLng = createStudy.mediaBrand.length;
                for (jp; jp < jpLng; jp++) {
                    chkBtnGoLft = false
                    if (createStudy.mediaBrand[jp].image && createStudy.mediaBrand[jp].image !== "") {
                        chkBtnGoLft = true
                    }
                }
            }

            if (createStudy.mediaImgEnable) {
                var at = 0, atLng = createStudy.mediaAttr.length;
                let count1 = 0
                for (at; at < atLng; at++) {
                    // chkBtnGoRgt = false
                    if (createStudy.mediaAttr[at].image && createStudy.mediaAttr[at].image !== "") {
                        // chkBtnGoRgt = true
                        count1++
                    }
                }
                if (count1 !== 0) {
                    chkBtnGoRgt = true
                } else {
                    chkBtnGoRgt = false
                }
            } else {
                var at = 0, atLng = createStudy.mediaAttr.length;
                let count1 = 0
                for (at; at < atLng; at++) {
                    // chkBtnGoRgt = false
                    if (createStudy.mediaAttr[at].text && createStudy.mediaAttr[at].text !== "") {
                        // chkBtnGoRgt = true
                        count1++
                    }
                }
                if (count1 !== 0) {
                    chkBtnGoRgt = true
                } else {
                    chkBtnGoRgt = false
                }
            }

            if (chkBtnGoLft && chkBtnGoRgt) {
                nextBtnGo = false;
            }
        } else if (currentTab === 4) {
            if (createScreener.screenerCustomText !== "") {
                nextBtnGo = false;
            }
        }
        else if (currentTab === 5) {
            let checkEmptyCount = 0
            for (var jk = 0; jk < createScreener.screenerQuestionBox.length; jk++) {
                if (createScreener.screenerQuestionBox[jk].question
                    && createScreener.screenerQuestionBox[jk].questionType) {
                    if (createScreener.screenerQuestionBox[jk].questionType === "matrix_rating_scale") {
                        if (createScreener.screenerQuestionBox[jk].rows[0].value && createScreener.screenerQuestionBox[jk].columns[0].value) {
                            for (var dk = 0; dk < createScreener.screenerQuestionBox[jk].rows.length; dk++) {
                                if (!createScreener.screenerQuestionBox[jk].rows[dk].value) {
                                    checkEmptyCount++
                                }
                            }
                            for (var mk = 0; mk < createScreener.screenerQuestionBox[jk].columns.length; mk++) {
                                if (!createScreener.screenerQuestionBox[jk].columns[mk].value) {
                                    checkEmptyCount++
                                }
                            }
                        } else {
                            checkEmptyCount++
                        }
                    }
                    else if (createScreener.screenerQuestionBox[jk].questionType !== "text_box") {
                        for (var pk = 0; pk < createScreener.screenerQuestionBox[jk].options.length; pk++) {
                            if (!createScreener.screenerQuestionBox[jk].options[pk].value) {
                                checkEmptyCount++
                            }
                        }
                    }
                } else {
                    checkEmptyCount++
                }
            }
            if (checkEmptyCount === 0) {
                nextBtnGo = false;
            } else {
                nextBtnGo = true;
            }
        }
        else if (currentTab === 6) {
            var chkNxt = false, condLgth = createScreener.screenerConditionsBox.length;
            var innerCondLgth = createScreener.screenerConditionsBox[0].otherCondition.length;
            if (condLgth > 1 || innerCondLgth > 0) {
                for (var q = 0; q < condLgth; q++) {
                    var inrLngth = createScreener.screenerConditionsBox[q].otherCondition.length;
                    chkNxt = false;
                    if (createScreener.screenerConditionsBox[q].question && createScreener.screenerConditionsBox[q].condition
                        && createScreener.screenerConditionsBox[q].answer) {
                        chkNxt = true
                    }
                    if (inrLngth > 0) {
                        for (var t = 0; t < inrLngth; t++) {
                            chkNxt = false;
                            if (createScreener.screenerConditionsBox[q].otherCondition[t].operator && createScreener.screenerConditionsBox[q].otherCondition[t].question
                                && createScreener.screenerConditionsBox[q].otherCondition[t].condition && createScreener.screenerConditionsBox[q].otherCondition[t].answer) {
                                chkNxt = true
                            }
                        }
                    }
                }
            } else {
                chkNxt = true;
            }
            if (chkNxt) {
                nextBtnGo = false;
            }
        }
        else if (currentTab === 8) {
            let checkEmptyCount1 = 0

            for (var kk = 0; kk < createSurvay.surveyQuestionBox.length; kk++) {

                if (createSurvay.surveyQuestionBox[kk].question
                    && createSurvay.surveyQuestionBox[kk].questionType) {
                    if (createSurvay.surveyQuestionBox[kk].questionType === "matrix_rating_scale") {
                        if (createSurvay.surveyQuestionBox[kk].rows[0].value && createSurvay.surveyQuestionBox[kk].columns[0].value) {
                            for (var lk = 0; lk < createSurvay.surveyQuestionBox[kk].rows.length; lk++) {
                                if (!createSurvay.surveyQuestionBox[kk].rows[lk].value) {
                                    checkEmptyCount1++
                                }
                            }
                            for (var pl = 0; pl < createSurvay.surveyQuestionBox[kk].columns.length; pl++) {
                                if (!createSurvay.surveyQuestionBox[kk].columns[pl].value) {
                                    checkEmptyCount1++
                                }
                            }
                        } else {
                            checkEmptyCount1++
                        }
                    } else if (createSurvay.surveyQuestionBox[kk].questionType !== "text_box") {
                        for (var hk = 0; hk < createSurvay.surveyQuestionBox[kk].options.length; hk++) {
                            if (!createSurvay.surveyQuestionBox[kk].options[hk].value) {
                                checkEmptyCount1++
                            }
                        }
                    }
                } else {
                    checkEmptyCount1++
                }
            }
            if (checkEmptyCount1 === 0) {
                nextBtnGo = false;
            } else {
                nextBtnGo = true;
            }

        }
        else if (currentTab === 98) {
            var chkBtnNxt = false;
            for (var kk = 0; kk < createSurvay.surveyQuestionBox.length; kk++) {
                chkBtnNxt = false;
                if (createSurvay.surveyQuestionBox[kk].question
                    && createSurvay.surveyQuestionBox[kk].questionType) {
                    if (createSurvay.surveyQuestionBox[kk].questionType === "text_box") {
                        chkBtnNxt = true;
                    } else if (createSurvay.surveyQuestionBox[kk].questionType === "matrix_rating_scale"
                        && createSurvay.surveyQuestionBox[kk].rows[0].value && createSurvay.surveyQuestionBox[kk].columns[0].value) {
                        for (var lk = 0; lk < createSurvay.surveyQuestionBox[kk].rows.length; lk++) {
                            chkBtnNxt = false;
                            if (createSurvay.surveyQuestionBox[kk].rows[lk].value) {
                                chkBtnNxt = true;
                            }
                        }
                        for (var pl = 0; pl < createSurvay.surveyQuestionBox[kk].columns.length; pl++) {
                            chkBtnNxt = false;
                            if (createSurvay.surveyQuestionBox[kk].columns[pl].value) {
                                chkBtnNxt = true;
                            }
                        }
                    } else if (createSurvay.surveyQuestionBox[kk].options[0].value) {
                        for (var hk = 0; hk < createSurvay.surveyQuestionBox[kk].options.length; hk++) {
                            chkBtnNxt = false;
                            if (createSurvay.surveyQuestionBox[kk].options[hk].value) {
                                chkBtnNxt = true;
                            }
                        }
                    }
                }
            }
            if (chkBtnNxt) {
                nextBtnGo = false;
            }

        }
        else if (currentTab === 9) {
            var chkNxts = false, condsLgth = createSurvay.surveyConditionsBox.length;
            var innerCondsLgth = createSurvay.surveyConditionsBox[0].otherCondition.length;
            if (condsLgth > 1 || innerCondsLgth > 0) {
                for (var q = 0; q < condsLgth; q++) {
                    var inrsLngth = createSurvay.surveyConditionsBox[q].otherCondition.length;
                    chkNxts = false;
                    if (createSurvay.surveyConditionsBox[q].hideQuestion && createSurvay.surveyConditionsBox[q].question && createSurvay.surveyConditionsBox[q].condition
                        && createSurvay.surveyConditionsBox[q].answer) {
                        chkNxts = true
                    }
                    if (inrsLngth > 0) {
                        for (var t = 0; t < inrsLngth; t++) {
                            chkNxts = false;
                            if (createSurvay.surveyConditionsBox[q].otherCondition[t].operator && createSurvay.surveyConditionsBox[q].otherCondition[t].question
                                && createSurvay.surveyConditionsBox[q].otherCondition[t].condition && createSurvay.surveyConditionsBox[q].otherCondition[t].answer) {
                                chkNxts = true
                            }
                        }
                    }
                }
            } else {
                chkNxts = true;
            }
            if (chkNxts) {
                nextBtnGo = false;
            }
        } else if (currentTab === 10) {
            nextBtnGo = false;
        }
    }

    validateNextBtn()

    const generateLinksApi = async () => {
        var dataObj = {
            studyID: createStudy._id,
            number_of_participants_to_create: numOfParticipant
        }
        const newCookies = new Cookies();
        if (numOfParticipant > 0) {
            await Axios.post(
                generateLinksUrl,
                dataObj,
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`,
                        'Content-type': 'application/json'
                    }
                }
            ).then(response => {
                const downloadUrl = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'file.csv'); // Set the desired file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(error => {
                setShowToast(true);
                if (error.response.status === 422) {
                    setErrMsg("Please provide number of participants to create");
                } else {
                    setErrMsg("Error " + error.response.status + ": There is issue with request");
                }
            })
        }
    }
    const generateGroupLinksApi = async () => {
        const newCookies = new Cookies()
        if (numOfParticipantGroup > 0) {
            await Axios.get(
                generateGroupLinksUrl + createStudy?._id + '/' + numOfParticipantGroup,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                    }
                }).then(response => {
                    const downloadUrl = URL.createObjectURL(new Blob([response?.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'file.csv'); // Set the desired file name here
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                }).catch(error => {
                    setErrMsg(error);
                    showToast(true);
                });
        }
    }


    const errAlert = <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
        <p>{errmsg}</p>
    </Alert>;

    return (
        <div className="studies_cstm_main">
            {errmsg === "Study Id is not Valid" ?
                <Container className='cstm_study_nav'>
                    <div className='cstm_loader'>
                        <p>{errmsg}</p>
                    </div>
                </Container>
                :
                <>
                    <Container className='cstm_study_nav'>
                        {(currentTab >= 4 && currentTab <= 6) ? "" : (currentTab >= 8 && currentTab <= 9) ? "" : (currentTab >= 10) ? "" :
                            <main className="questionNumbers">
                                <div className='circle-div'>
                                    <div>
                                        <div className={'circle-con' + ((currentTab === 0 || currentTab === 1) ? " active" : "")}>
                                            <p className='circle-text'>1</p>
                                        </div>
                                    </div>
                                    <p className='circle-bottom-text'>Define Study</p>
                                </div>

                                <div className="questionNumberLine"></div>
                                <div className='circle-div'>
                                    <div className={'circle-con' + (currentTab === 2 ? " active" : "")}>
                                        <p className='circle-text'>2</p>
                                    </div>
                                    <p className='circle-bottom-text'>Add Media</p>
                                </div>
                                <div className="questionNumberLine"></div>
                                <div className='circle-div'>
                                    <div className={'circle-con' + ((currentTab === 3 || currentTab === 6) ? " active" : "")} >
                                        <p className='circle-text'>3</p>
                                    </div>
                                    <p className='circle-bottom-text'>Create Screener</p>
                                </div>
                                <div className="questionNumberLine"></div>

                                <div className='circle-div'>
                                    <div className={'circle-con' + ((currentTab === 7 || currentTab === 9) ? " active" : "")} >
                                        <p className='circle-text'>4</p>
                                    </div>
                                    <p className='circle-bottom-text'>Create Survey</p>
                                </div>
                            </main>
                        }
                        {
                            (currentTab >= 4 && currentTab <= 6) &&
                            <div className='topTabCon'>
                                <div className='topLineCon'>
                                    <div className={'topLine ' + (currentTab === 4 ? "active" : "")} />
                                    <p className='topText'>Custom Text Field</p>
                                </div>

                                <div className='topLineCon' style={{ marginLeft: 20 }}>
                                    <div className={'topLine ' + (currentTab === 5 ? "active" : "")} />
                                    <p className='topText' style={{ color: "#A3AAC2" }}>Screener Questions</p>
                                </div>

                                <div className='topLineCon' style={{ marginLeft: 20 }}>
                                    <div className={'topLine ' + (currentTab === 6 ? "active" : "")} />
                                    <p className='topText' style={{ color: "#A3AAC2" }}>Conditions</p>
                                </div>
                            </div>
                        }

                        {
                            (currentTab >= 8 && currentTab <= 9) &&
                            <div className='topTabCon'>
                                <div className='topLineCon' style={{ marginLeft: 20 }}>
                                    <div className={'topLine ' + (currentTab === 8 ? "active" : "")} />
                                    <p className='topText' style={{ color: "#A3AAC2" }}>Survey Questions</p>
                                </div>
                                <div className='topLineCon' style={{ marginLeft: 20 }}>
                                    <div className={'topLine ' + (currentTab === 9 ? "active" : "")} />
                                    <p className='topText' style={{ color: "#A3AAC2" }}>Display Logic</p>
                                </div>
                            </div>
                        }

                    </Container>
                    <Container className={'study_main_content_cstm ' + getTabName(currentTab)}>
                        {errAlert}
                        <Row>
                            <Col>
                                <Tabs activeKey={currentTab} id="cstm-tab-study_nmbr">
                                    {currentTab === 0 &&
                                        <Tab eventKey={0} title="" disabled={currentTab !== 0} tabClassName="study_cstm_first" >
                                            <div className="studies_cstm_v1">
                                                <div className="study_v1_main">
                                                    <div className="study_v1_title">
                                                        <h1 className="study_v1_tlt">Study Parameters</h1>
                                                        <p className="study_v1_desc">Give your nPlicit™ study a name, client (if applicable), and opt to include a post nPlicit™ survey.</p>
                                                    </div>
                                                    <div className="study_v1_form">
                                                        <div className='study_v1_innr'>
                                                            <Row>
                                                                <Col className='study_v1_form_left'>
                                                                    <Form.Group as={Row} className="mb-3 study_pt" controlId="study_pt">
                                                                        <Form.Label column sm="2">
                                                                            Project Title
                                                                        </Form.Label>
                                                                        <Col sm="10">
                                                                            <Form.Control type="text" required placeholder="Enter project title" maxLength="25" value={createStudy.projectTitle}
                                                                                onChange={e => setCreateStudy({ ...createStudy, projectTitle: e.target.value })} />
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3 study_client" controlId="study_client">
                                                                        <Form.Label column sm="2">
                                                                            Client
                                                                        </Form.Label>
                                                                        <Col sm="10">
                                                                            <Form.Control type="text" required placeholder="Enter client name" maxLength="25" value={createStudy.client}
                                                                                onChange={e => setCreateStudy({ ...createStudy, client: e.target.value })} />
                                                                        </Col>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='study_v1_form_right' >
                                                                    <Form.Group as={Row} className="mb-3 study_post_survay" controlId="study_post_survay">
                                                                        <Form.Label column sm="2">
                                                                            Include Post Survey
                                                                        </Form.Label>
                                                                        <Col sm="10">
                                                                            <Form.Check
                                                                                type="switch"
                                                                                id="custom-switch_study_v1"
                                                                                checked={createStudy.isIncludePostSurvey}
                                                                                onChange={e => setCreateStudy({ ...createStudy, isIncludePostSurvey: e.target.checked })}
                                                                            />
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3 study_red_link" controlId="study_red_link">
                                                                        <Form.Label column sm="2">
                                                                            Screenout Redirect Link
                                                                        </Form.Label>
                                                                        <Col sm="10">
                                                                            <Form.Control type="url" placeholder="Enter Screenout URL" value={createStudy.redirectLink}
                                                                                onChange={e => setCreateStudy({ ...createStudy, redirectLink: e.target.value })}
                                                                                // onBlur={(event) => {
                                                                                //     if (event.target.value.length > 0 && !isValidUrl(event.target.value)) {
                                                                                //         document.querySelector(".err_cstm_redi").innerText = "Redirect URL is not a valid URL";
                                                                                //     } else {
                                                                                //         document.querySelector(".err_cstm_redi").innerText = "";
                                                                                //     }
                                                                                // }} 
                                                                            />
                                                                            <span className="err_cstm_redi"></span>
                                                                        </Col>
                                                                    </Form.Group>
                                                                    <Form.Group as={Row} className="mb-3 study_red_link" controlId="study_red_link">
                                                                        <Form.Label column sm="2">
                                                                            External Link
                                                                        </Form.Label>
                                                                        <Col sm="10">
                                                                            <Form.Control type="url" placeholder="Enter external URL" value={createStudy.externalLink}
                                                                                onChange={e => setCreateStudy({ ...createStudy, externalLink: e.target.value })}
                                                                                onBlur={(event) => {
                                                                                    // if (event.target.value.length > 0 && !isValidUrl(event.target.value)) {
                                                                                    //     document.querySelector(".ext_lnk").innerText = "External URL is not a valid URLm";
                                                                                    // } else {
                                                                                    document.querySelector(".ext_lnk").innerText = "";
                                                                                    // }
                                                                                }} />
                                                                            <span className="err_cstm_redi ext_lnk"></span>
                                                                        </Col>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 1 &&
                                        <Tab eventKey={1} title="" tabClassName="study_cstm_types" disabled={currentTab !== 1}>
                                            <div className="studies_cstm">
                                                <div className="study_v2_main">
                                                    <div className="study_v2_innr">
                                                        <div className="study_v2_title">
                                                            <h1 className="study_v2_tlt">Type of Study</h1>
                                                            <p className="study_v2_desc">Chose the desired type of nPlicit™ study.</p>
                                                        </div>
                                                        <div className="study_v2_type">
                                                            <div className="study_v2_type_inner">
                                                                <div className="stdy_v2_stndrd">
                                                                    <div className='type_study_lst_cstm'>
                                                                        {
                                                                            (typeStudy && typeStudy.length > 0) ?
                                                                                typeStudy?.map((data) => {
                                                                                    return (
                                                                                        <div className='stndrd_v2_img_det' key={data._id}>
                                                                                            <label htmlFor={"stnd_stdy_" + (data._id)} std={createStudy.studyTypeId} className={"std_tp_cstm " + (data._id === createStudy.studyTypeId ? " active" : "")}>
                                                                                                <div className='stnd_std_v2_img'>
                                                                                                    <Image src={line_study} className='stdy_stndrd_v2_img' alt="Study Type Image" />
                                                                                                </div>
                                                                                                <h1 className='stnd_v2_title'>{data.name}</h1>
                                                                                                <p className="study_v2_desc_stndrd">{data.detail}.</p>
                                                                                            </label>
                                                                                            <input type="radio" id={"stnd_stdy_" + (data._id)} name="study_types_cstm" value={data._id} className='hide_me' defaultChecked={(data._id === createStudy.studyTypeId ? true : false)} onChange={(event) => {
                                                                                                if (event.target.checked) {
                                                                                                    setCreateStudy({ ...createStudy, studyTypeId: event.target.value })
                                                                                                }
                                                                                            }}></input>
                                                                                        </div>
                                                                                    )
                                                                                }) : ""
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 2 &&
                                        <Tab eventKey={2} title="" tabClassName="study_cstm_media" disabled={currentTab !== 2}>
                                            <AddMedia setShowToast={setShowToast} setErrMsg={setErrMsg} studyData={createStudy} setStudyData={setCreateStudy} tabName={getTabName(currentTab)} advConfigMin={setAdvanceConfigMin} />
                                        </Tab>
                                    }
                                    {currentTab === 3 &&
                                        <Tab eventKey={3} title="" tabClassName="study_cstm_screener" disabled={currentTab !== 3}>
                                            <div className="study_cstm_screener">
                                                <div className="wrapper">
                                                    <p className="stydyServey">Study Screener Survey</p>
                                                    <p className="loremIpsum">Outline the parameters to qualify for the study.</p>
                                                </div>
                                                <div className="buttonCon">
                                                    <button className="button" onClick={() => {
                                                        setCurrentTab((prev) => prev + 1)
                                                        localStorage.setItem("studyTab", currentTab + 1);
                                                    }}>
                                                        {createScreener.screenerQuestionBox[0].question ? "UPDATE SCREENER" : "CREATE NEW"}
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 4 &&
                                        <Tab eventKey={4} title="" tabClassName="study_cstm_screener_v1" disabled={currentTab !== 4}>
                                            <Screener enblAddCond={nextBtnGo} currentTab={currentTab} setShowToast={setShowToast} setErrMsg={setErrMsg} createScreener={createScreener} setScreener={setScreener} tabName={getTabName(currentTab)} />
                                        </Tab>
                                    }
                                    {currentTab === 5 &&
                                        <Tab eventKey={5} title="" tabClassName="study_cstm_screener_v2" disabled={currentTab !== 5}>
                                            <Screener enblAddCond={nextBtnGo} currentTab={currentTab} setShowToast={setShowToast} setErrMsg={setErrMsg} createScreener={createScreener} setScreener={setScreener} tabName={getTabName(currentTab)} />
                                        </Tab>
                                    }
                                    {currentTab === 6 &&
                                        <Tab eventKey={6} title="" tabClassName="study_cstm_screener_v3" disabled={currentTab !== 6}>
                                            <Screener enblAddCond={nextBtnGo} currentTab={currentTab} setShowToast={setShowToast} setErrMsg={setErrMsg} createScreener={createScreener} setScreener={setScreener} tabName={getTabName(currentTab)} />
                                        </Tab>
                                    }
                                    {currentTab === 7 &&
                                        <Tab eventKey={7} title="" tabClassName="study_cstm_survay" disabled={currentTab !== 7}>
                                            <div className="study_cstm_screener">
                                                <div className="wrapper">
                                                    <p className="stydyServey">Post Survey</p>
                                                    <p className="loremIpsum">Define the conditions for the post survey.</p>
                                                </div>
                                                <div className="buttonCon">
                                                    <button className="button" onClick={() => {
                                                        setCurrentTab((prev) => prev + 1)
                                                        localStorage.setItem("studyTab", currentTab + 1);
                                                    }}>
                                                        {createSurvay.surveyQuestionBox[0].question ? "UPDATE SURVEY" : "CREATE NEW"}
                                                    </button>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {currentTab === 8 &&
                                        <Tab eventKey={8} title="" tabClassName="study_cstm_survay_v1" disabled={currentTab !== 8}>
                                            <Survay enblAddCond={nextBtnGo} currentTab={currentTab} setShowToast={setShowToast} setErrMsg={setErrMsg} createSurvay={createSurvay} setSurvay={setSurvay} tabName={getTabName(currentTab)} />
                                        </Tab>
                                    }
                                    {currentTab === 9 &&
                                        <Tab eventKey={9} title="" tabClassName="study_cstm_survay_v2" disabled={currentTab !== 9}>
                                            <Survay enblAddCond={nextBtnGo} currentTab={currentTab} setShowToast={setShowToast} setErrMsg={setErrMsg} createSurvay={createSurvay} setSurvay={setSurvay} tabName={getTabName(currentTab)} />
                                        </Tab>
                                    }
                                    {currentTab === 10 &&
                                        <Tab eventKey={10} title="" tabClassName="study_cstm_survay_result" disabled={currentTab !== 10}>
                                            <div className="cstm_survay_result">
                                                <div className="study_survey">
                                                    <div className="innerCon">
                                                        <p className="stydyServey">Your nPlicit™ study is ready to review </p>
                                                        <p className="loremIpsum">Make sure to check your work by using the preview link. Go back to make any edits and select finalize to finish creating your study.</p>

                                                        <div className='lineStyle' />
                                                        {/* <div className='statsCon'>
                                                            <p className='titleSty'>Generic Link</p>
                                                            <a className='linkStyle' href={cUrl + "/study/" + (createStudy._id ? createStudy._id : "")} rel="noreferrer" target="_blank" disabled style={{ pointerEvents: "none" }}>{cUrl + "/study/" + (createStudy._id ? createStudy._id : "")}</a>
                                                        </div> */}
                                                        <div className='lineStyle' />
                                                        <div className='statsCon'>
                                                            <p className='titleSty'>Study Link</p>
                                                            {/* <a className='linkStyle' href={cUrl + "/study/" + (createStudy._id ? createStudy._id : "") + "/" + (createStudy.previewCode ? createStudy.previewCode : "")} rel="noreferrer" target="_blank">{cUrl + "/study/" + (createStudy._id ? createStudy._id : "") + "/" + (createStudy.previewCode ? createStudy.previewCode : "")}</a> */}
                                                            <a className='linkStyle' href={cUrl + "/study/" + (createStudy._id ? createStudy._id : "")} rel="noreferrer" target="_blank">{cUrl + "/study/" + (createStudy._id ? createStudy._id : "")}</a>
                                                        </div>
                                                        {/* <div className='lineStyle' />
                                                        <div className='statsCon'>
                                                            <p className='titleSty'>Preview Code</p>
                                                            <p className='preview'>{(createStudy.previewCode ? createStudy.previewCode : "")}</p>
                                                        </div> */}
                                                        <div className='lineStyle' />
                                                        <div className='statsCon'>
                                                            <p className='titleSty'>Expires</p>
                                                            <p className='preview'>{(createStudy.expireDate ? createStudy.expireDate.split("T")[0] : "")}</p>
                                                        </div>
                                                        <div className='num_of_participant_Con'>
                                                            <Stack direction="vertical" gap={3}>
                                                                <div className='gpnlnk_indv'>
                                                                    <p className='grlnk_inst_top instr_cstm mt-3'>“Individual links” are links to the study with a unique participant ID. Generate individual links if you are planning to disseminate your nPLICIT study to a team of individuals by emailing each participant a unique link. Each link can only be used once. Specify the number of participants / links you need and click “generate individual links.”</p>
                                                                    <Stack direction="horizontal" gap={3} >
                                                                        <div className='indv_inp'>
                                                                            <input type="number"
                                                                                className='num_of_participant_input'
                                                                                // placeholder='Number of Participants'
                                                                                value={numOfParticipant}
                                                                                min="1"
                                                                                onChange={(event) => setNumOfParticipant(event.target.value)}
                                                                            />
                                                                            {(numOfParticipant === "0" || numOfParticipant === 0) && <p class="warning" style={{ color: "red", marginBottom: "0px", paddingBottom: "0px", paddingTop: "5px" }}>Number of participants must be greater then 0</p>}
                                                                        </div>
                                                                        <Button className={"generate_Link_Btn "} variant="outline-secondary"
                                                                            onClick={() => generateLinksApi()}>
                                                                            GENERATE INDIVIDUAL LINKS
                                                                        </Button>
                                                                    </Stack>
                                                                </div>

                                                                <div className='gpnlnk_indv'>
                                                                    <p className='grlnk_inst_btm instr_cstm mt-2'>A “group link” is a generic links for a large group of people. Generate and pass along a group link if you are using a third party that will disseminate the nPLICIT study to a set of participants (eg. a recruiter). The third party will generate individual, unique links behind the scenes.</p>
                                                                    <Stack direction="horizontal" gap={3} >
                                                                        <div className='indv_inp'>
                                                                            <input type="number"
                                                                                className='num_of_participant_input'
                                                                                // placeholder='Number of Participants'
                                                                                value={numOfParticipantGroup}
                                                                                min="1"
                                                                                onChange={(event) => setNumOfParticipantGroup(event.target.value)}
                                                                            />
                                                                            {(numOfParticipantGroup === "0" || numOfParticipantGroup === 0) && <p class="warning" style={{ color: "red", marginBottom: "0px", paddingBottom: "0px", paddingTop: "5px" }}>Number must be greater then 0</p>}
                                                                        </div>
                                                                        <Button className={"generate_Link_Btn "} variant="outline-secondary"
                                                                            onClick={() => generateGroupLinksApi()}>
                                                                            GENERATE GROUP LINKS
                                                                        </Button>
                                                                    </Stack>
                                                                </div>

                                                                {/* <div className='gplnk'>
                                                                    <p className='grlnk_inst_btm instr_cstm mt-2'>A “group link” is a generic links for a large group of people. Generate and pass along a group link if you are using a third party that will disseminate the nPLICIT study to a set of participants (eg. a recruiter). The third party will generate individual, unique links behind the scenes.</p>
                                                                    <Button className={"generate_Link_Btn "} variant="outline-secondary"
                                                                        onClick={() => generateGroupLinksApi()}
                                                                    >
                                                                        GENERATE GROUP LINK
                                                                    </Button>
                                                                </div> */}
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                </Tabs>
                            </Col>
                        </Row>

                        <Stack gap={3} direction="horizontal" className="mt-3" style={{justifyContent:"space-between"}}>

                            <Button
                                className={"cstm_stdy_tb cstm_stdy_prev" + (currentTab === 0 ? " start_active" : "success next_active")}
                                onClick={() => handleNextPrevBtn("previous")}>
                                {currentTab === 4 || currentTab === 8 ? "EXIT" : "BACK"}
                            </Button>
                            {currentTab === 10 ?
                                <Stack gap={3} direction="horizontal" className="mt-3">
                                    <Button
                                        className="success ms-auto cstm_stdy_tb cstm_stdy_next"
                                        disabled={nextBtnGo}
                                        onClick={() => 
                                            window.open( cUrl + "/study/" + (createStudy._id ? createStudy._id : "") + "?rid=" + (createStudy.previewCode ? createStudy.previewCode : ""), "_blank")
                                        }
                                    >
                                        {"PREVIEW STUDY"}
                                    </Button>

                                    {(currentTab === 3 || currentTab === 7) ? "" :
                                        <div className='ms-auto cstm_rgt_std_btn'>
                                            <Button
                                                className="success ms-auto cstm_stdy_tb cstm_stdy_next"
                                                disabled={nextBtnGo}
                                                onClick={() => handleNextPrevBtn("next")}
                                            >
                                                {currentTab === 6 ? "COMPLETE" : currentTab === 10 ? "FINALIZE" : "NEXT"}
                                            </Button>
                                        </div>}

                                </Stack> :
                                (currentTab === 3 || currentTab === 7) ? "" :
                                    <div className='ms-auto cstm_rgt_std_btn'>
                                        <Button
                                            className="success ms-auto cstm_stdy_tb cstm_stdy_next"
                                            disabled={nextBtnGo}
                                            onClick={() => handleNextPrevBtn("next")}
                                        >
                                            {currentTab === 6 ? "COMPLETE" : currentTab === 10 ? "FINALIZE" : "NEXT"}
                                        </Button>
                                    </div>}

                        </Stack>
                    </Container>
                </>}
        </div>
    );
}

function getTabName(tabId) {
    var cTabName = "";
    switch (tabId) {
        case 0:
            cTabName = "defineStudy_parameters";
            break;
        case 1:
            cTabName = "defineStudy_typeOfStudy";
            break;
        case 2:
            cTabName = "addMedia";
            break;
        case 4:
            cTabName = "screener_screenerCustomText";
            break;
        case 5:
            cTabName = "screener_screenerQuestion";
            break;
        case 6:
            cTabName = "screener_screenerCondition";
            break;
        case 8:
            cTabName = "survay_survayQuestion";
            break;
        case 9:
            cTabName = "survay_survayLogic";
            break;
        default:
            cTabName = false;
            break;
    }

    return cTabName;
}

