import { useEffect, useState, useContext } from "react";
import { Image, Form, Stack, Container, Alert, Button } from 'react-bootstrap';
import cross from '../../assets/cross.png'
import study_media from '../../assets/study_media.png';
import copy from '../../assets/copy.png'
import deleteIcon from '../../assets/delete.png'
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import { ShowMainNav } from '../../Components/Context';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var getScreenerApi = process.env.REACT_APP_API_URL + apiVersion + "studies";
var appImgUrl = process.env.REACT_APP_API_URL + apiVersion + "upload/file";
var domainUrl = process.env.REACT_APP_API_URL;
var delteImgUrl = process.env.REACT_APP_API_URL + apiVersion + "file/delete";

const cookies = new Cookies();

export default function Screener({ enblAddCond, tabName }) {

  let navigate = useNavigate()
  const { studyID } = useParams()
  const { setShowNav } = useContext(ShowMainNav);

  const [createScreener, setScreener] = useState({})
  const [currentTab, setCurrentTab] = useState(0)
  const [showToast, setShowToast] = useState(false);
  const [errmsg, setErrMsg] = useState('Study Not Saved');
  const [showLoading, setShowLoading] = useState(true);
  const [studyIdError, setStudyIdError] = useState(false);
  const [studyIdErrorMsg, setStudyIdErrorMsg] = useState(false);
  // 646b90937fa0f4ecea9b4657

  useEffect(() => {
    getScreener()
    setShowNav(false)
  }, []);

  const getScreener = async () => {
    if (studyID) {
      await Axios.get(
        getScreenerApi + "/" + studyID + "/screener",
        // getScreenerApi + "/" + "646b90937fa0f4ecea9b4657" + "/screener",
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${cookies.get("neurojwt")}`
          }
        }).then(response => {
          console.log("data====>", response);
          if (response && response.data && response.data.status) {
            setScreener(response.data.data);
          } else {
            setErrMsg(response.data.message);
            setShowToast(true);
          }
        }).catch(error => {
          if (error.response.status === 401) {
            window.location.href = "/login";
          } else {
            setStudyIdErrorMsg(error?.response?.data?.message)
            setStudyIdError(true)
          }
        }).finally(() => {
          setShowLoading(false)
        })
    } else {
      setShowLoading(false)
      setStudyIdErrorMsg("Study Not Found")
      setStudyIdError(true)
    }

  }

  const CloneScreenerBox = (index) => {
    var lstBox = createScreener.screenerQuestionBox[index];
    var cloneBox = JSON.parse(JSON.stringify(lstBox));
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox.push(cloneBox);
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const RemoveScreenerBox = (name, index) => {
    if (name === "question") {
      var newCreateScreenerBox = createScreener.screenerQuestionBox;
      if (newCreateScreenerBox.length > 1) {
        newCreateScreenerBox.splice(index, 1);
        setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox });
      } else {
        setErrMsg("You cannot delete the default Question");
        setShowToast(true);
      }
    } else {
      var newScreenerConditionsBox = createScreener.screenerConditionsBox;
      if (newScreenerConditionsBox.length > 1) {
        newScreenerConditionsBox.splice(index, 1);
        setScreener({ ...createScreener, screenerConditionsBox: newScreenerConditionsBox });
      } else {
        setErrMsg("You cannot delete the default Condition");
        setShowToast(true);
      }
    }
  }

  const AddScreenerBox = () => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox.push({ quesMediaChk: false, screnerQuesMedia: "", question: "", questionType: "multiple_choice", options: [{ value: "" }], rows: [{ value: "" }], columns: [{ value: "" }] });
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const handleScreenerBoxQues = (event, index) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[index].question = event.target.value;
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const handleScreenerBoxMultiChoice = (event, index) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[index].questionType = event.target.value;
    var lngth = newCreateScreenerBox[index].options.length;
    if (lngth > 1) {
      newCreateScreenerBox[index].options.splice(1, lngth);
    }
    newCreateScreenerBox[index].options[0].value = "";
    var rlngth = newCreateScreenerBox[index].rows.length;
    var clngth = newCreateScreenerBox[index].columns.length;
    if (rlngth > 1) {
      newCreateScreenerBox[index].rows.splice(1, rlngth);
    }
    if (clngth > 1) {
      newCreateScreenerBox[index].columns.splice(1, clngth);
    }
    newCreateScreenerBox[index].rows[0].value = "";
    newCreateScreenerBox[index].columns[0].value = "";
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const handleScreenerBoxOptions = (event, boxIndx, optIndx) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[boxIndx].options[optIndx].value = event.target.value;
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const AddScreenerBoxQueOpt = (index) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[index].options.push({ value: "" });
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const RemoveScreenerBoxOption = (boxInd, optInd) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    if (newCreateScreenerBox[boxInd] && newCreateScreenerBox[boxInd].options.length > 1) {
      newCreateScreenerBox[boxInd].options.splice(optInd, 1);
      setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox });
    } else {
      setErrMsg("You cannot delete the default options");
      setShowToast(true);
    }
  }

  const handleScreenerBoxRow = (event, boxIndx, optIndx) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[boxIndx].rows[optIndx].value = event.target.value;
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }
  const handleScreenerBoxCol = (event, boxIndx, optIndx) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[boxIndx].columns[optIndx].value = event.target.value;
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const RemoveScreenerBoxRow = (boxInd, optInd) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    if (newCreateScreenerBox[boxInd] && newCreateScreenerBox[boxInd].rows.length > 1) {
      newCreateScreenerBox[boxInd].rows.splice(optInd, 1);
      setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox });
    } else {
      setErrMsg("You cannot delete the default Row");
      setShowToast(true);
    }
  }

  const RemoveScreenerBoxCol = (boxInd, optInd) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    if (newCreateScreenerBox[boxInd] && newCreateScreenerBox[boxInd].columns.length > 1) {
      newCreateScreenerBox[boxInd].columns.splice(optInd, 1);
      setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox });
    } else {
      setErrMsg("You cannot delete the default column");
      setShowToast(true);
    }
  }

  const AddScreenerBoxQueRow = (boxInd) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[boxInd].rows.push({ value: "" });
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const AddScreenerBoxQueCol = (boxInd) => {
    var newCreateScreenerBox = createScreener.screenerQuestionBox;
    newCreateScreenerBox[boxInd].columns.push({ value: "" });
    setScreener({ ...createScreener, screenerQuestionBox: newCreateScreenerBox })
  }

  const AddNewConditionScreenOpt = (index) => {
    var newCreateScreenerBoxCond = createScreener.screenerConditionsBox;
    newCreateScreenerBoxCond[index].otherCondition.push({ "operator": "and", "question": "", "condition": "", "answer": "" });
    setScreener({ ...createScreener, screenerConditionsBox: newCreateScreenerBoxCond })
  }

  const handleChangeQuesCond = (event, index) => {
    var newScreenerBoxCondition = createScreener.screenerConditionsBox;
    if (event.target.name === "condQuestion") {
      newScreenerBoxCondition[index].question = event.target.value;
    } else if (event.target.name === "condQuestionCondition") {
      newScreenerBoxCondition[index].condition = event.target.value;
    } else if (event.target.name === "condAnswer") {
      newScreenerBoxCondition[index].answer = event.target.value;
    }
    setScreener({ ...createScreener, screenerConditionsBox: newScreenerBoxCondition })
  }

  const handleOtherCondData = (event, boxIndex, othrCondIndx) => {
    var newScreenerBoxCondition = createScreener.screenerConditionsBox;
    if (event.target.name === "otherCondOperator") {
      newScreenerBoxCondition[boxIndex].otherCondition[othrCondIndx].operator = event.target.value;
    } else if (event.target.name === "otherCondQuestion") {
      newScreenerBoxCondition[boxIndex].otherCondition[othrCondIndx].question = event.target.value;
    } else if (event.target.name === "otherCondCondition") {
      newScreenerBoxCondition[boxIndex].otherCondition[othrCondIndx].condition = event.target.value;
    } else if (event.target.name === "otherCondAnswer") {
      newScreenerBoxCondition[boxIndex].otherCondition[othrCondIndx].answer = event.target.value;
    }
    setScreener({ ...createScreener, screenerConditionsBox: newScreenerBoxCondition })
  }

  const cloneCondTermBox = (boxIndex) => {
    var lstBox = createScreener.screenerConditionsBox[boxIndex];
    var cloneBox = JSON.parse(JSON.stringify(lstBox));
    var newScreenerBoxCondition = createScreener.screenerConditionsBox;
    newScreenerBoxCondition.push(cloneBox);
    setScreener({ ...createScreener, screenerConditionsBox: newScreenerBoxCondition })
  }

  const AddNewCondBox = () => {
    var newScreenerBoxCondition = createScreener.screenerConditionsBox;
    newScreenerBoxCondition.push({
      "question": "",
      "condition": "",
      "answer": "",
      "otherCondition": []
    })
    setScreener({ ...createScreener, screenerConditionsBox: newScreenerBoxCondition })
  }

  const handleImage = async (event, index) => {
    const cookies = new Cookies();
    await Axios.post(
      appImgUrl,
      {
        file: event.target.files[0]
      },
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': `Bearer ${cookies.get("neurojwt")}`
        }
      }).then(response => {
        if (response && response.data && response.data.status) {
          var newScreenerQuestionBox = createScreener.screenerQuestionBox;
          newScreenerQuestionBox[index].quesMediaImg = response.data.filePath;
          setScreener({ ...createScreener, screenerQuestionBox: newScreenerQuestionBox });
        } else {
          setErrMsg(response.data.message);
          setShowToast(true);
        }
      }).catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
        setErrMsg(error.response.data.message);
        setShowToast(true);
      });

  }

  const updateScreenerData = async () => {

    var screenerData = {
      screenerCustomText: createScreener.screenerCustomText,
      screenerQuesTitle: createScreener.screenerQuesTitle,
      screenerQuesEnableAGR: createScreener.screenerQuesEnableAGR,
      screenerQuesAge: createScreener.screenerQuesAge,
      screenerQuesGender: createScreener.screenerQuesGender,
      screenerQuesRace: createScreener.screenerQuesRace,
      screenerQuestionBox: createScreener.screenerQuestionBox,
      screenerConditionsBox: createScreener.screenerConditionsBox,
      tabName: tabName
    }
    setShowToast(false);
    if (studyID) {
      await Axios.put(
        getScreenerApi + "/" + studyID + "/screener",
        screenerData,
        {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${cookies.get("neurojwt")}`
          }
        }).then(response => {
          if (response && response.data && response.data.status) {
          } else {
            setErrMsg(response.data.message);
            setShowToast(true);
          }
        }).catch(error => {
          console.log("error=>", error);
          if (error.response.status === 401) {
            window.location.href = "/login"
          }
          setErrMsg(error.response.data.message);
          setShowToast(true);
        });
    } else {
      setErrMsg("Study id not available create new study");
      setShowToast(true);
    }
  }

  const handleRemoveImage = async (event, imagePath, index) => {
    await Axios.post(
      delteImgUrl,
      { filePath: imagePath },
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${cookies.get("neurojwt")}`
        }
      }).then(response => {
        if (response && response.data && response.data.status) {
          var newScreenerQuestionBox = createScreener.screenerQuestionBox;
          newScreenerQuestionBox[index].quesMediaImg = "";
          setScreener({ ...createScreener, screenerQuestionBox: newScreenerQuestionBox });
          updateScreenerData();
        } else {
          setErrMsg(response.data.message);
          setShowToast(true);
        }
      }).catch(error => {
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
        setErrMsg(error.response.data.message);
        setShowToast(true);
      });
  }

  const handleBtnChange = (event, index) => {
    var newScreenerBox = createScreener.screenerQuestionBox;
    if (event.target.name === "forceRanking") {
      newScreenerBox[index].forceRanking = event.target.checked;
    } else if (event.target.name === "singleRowRating") {
      newScreenerBox[index].singleRowRating = event.target.checked;
    } else if (event.target.name === "qualtrics_add_answers_in_url") {
      newScreenerBox[index].qualtrics = event.target.checked;
    } else {
      newScreenerBox[index].quesMediaChk = event.target.checked;
    }

    setScreener({ ...createScreener, screenerQuestionBox: newScreenerBox })
  }

  const handleNextPress = () => {
    updateScreenerData()
    setCurrentTab(1)
  }
  const completePress = () => {
    updateScreenerData()
    navigate("/studies")
    setShowNav(true)
  }

  const exitBtnClick = () => {
    navigate("/studies")
    setShowNav(true)
  }

  const errAlert = <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
    <p>{errmsg}</p>
  </Alert>;

  var currentData = '';
  if (currentTab === 0) {
    var scqAge = [];
    for (var k = 15; k <= 100; k++) {
      scqAge.push(k)
    }
    currentData = <div className="main_screener_v2">
      {/* <div className={"wrapper " + ((createScreener?.screenerQuestionBox?.length > 1 || createScreener?.screenerQuestionBox[0]?.options?.length > 2 || createScreener?.screenerQuestionBox[0]?.rows?.length > 2 || createScreener?.screenerQuestionBox[0]?.columns?.length > 2) ? "screner_scroll_active" : "")}> */}
      <div className={"wrapper " + "screner_scroll_active"}>

        <div className='titleBox'>
          <input className='titleInput' value={createScreener?.screenerCustomText} onChange={(event) =>
            setScreener({ ...createScreener, screenerCustomText: event.target.value })
          } type="text" placeholder='Title...' />
        </div>

        {createScreener?.screenerQuestionBox?.map((item, index) =>
          <div className='QuestionBox' key={"inx_" + index}>
            <div className="cstm_screener_media_op">
              <div className='cstm_witch_scenr_mdia'>
                <Stack direction="horizontal" gap={3}>
                  <label>Add Media</label>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={item.quesMediaChk}
                    name="quesMediaChk"
                    label=""
                    onChange={(event) => handleBtnChange(event, index)}
                  />
                  {item.questionType === "multiple_choice"?
                    <div className="quota-custom-switch">
                      <label>Quota</label>
                      <Form.Check
                      type="switch"
                      id="custom-switch-qualtrics"
                      checked={item.qualtrics}
                      name="qualtrics_add_answers_in_url"
                      label=""
                      onChange={(event) => handleBtnChange(event, index)}
                    />
                    </div> : null}
                </Stack>
              </div>
              <div className='media_cstm_imges_study screnrMedia'>
                {item.quesMediaChk ?
                  <div className='stdy_md_img'>
                    {item.quesMediaImg ?
                      <div className='arr_img_media_brnd'>
                        <div className='cross_img_std_med'>
                          <Image src={cross} name="quesMediaImg" thumbnail={true} className='cross_sign_std'
                            onClick={(event) => handleRemoveImage(event, item.quesMediaImg, index)}
                          />
                        </div>
                        <img src={item.quesMediaImg} className="std_med_img_brnd" alt='med_image_1' />
                      </div> :
                      <div className="cstm_std_img_wrpr">
                        <div className={'media_study_cstm_img ' + (item.quesMediaImg ? "active" : "")}>
                          <label htmlFor={'std_media_v' + index}>
                            <Image src={study_media} className="study_med_img" />
                          </label>
                          <input type="file" accept="image/*" id={'std_media_v' + index} name="quesMediaImg"
                            onChange={(event) => handleImage(event, index)}
                            className='study_med_file_v1 hide_me' />
                        </div>
                      </div>
                    }
                  </div>
                  : ""}
              </div>
            </div>
            <div className='bottom-inner-con'>
              <input className='titleInput' type="text" name="question" value={item.question}
                onChange={(event) => handleScreenerBoxQues(event, index)} placeholder='Question...' />
              <select className='dropdown' name="questionType" value={item.questionType}
                onChange={(event) => handleScreenerBoxMultiChoice(event, index)}
              >
                <option value="multiple_choice">Multiple Choice</option>
                <option value="check_boxes">Check Boxes</option>
                <option value="matrix_rating_scale">Matrix/Rating Scale</option>
                <option value="text_box">Text Box</option>
              </select>
            </div>
            {item?.questionType !== "text_box" ?
              <div className='ansDiv'>
                <div className='lineStyle'></div>
                {item.questionType === "matrix_rating_scale" ?
                  <div className="mtxrx_hder">
                    <h3 className="matr_row">Row</h3>
                  </div> : ""}
                <div className={"screener_bx_option " + item.questionType}>
                  {item.questionType === "matrix_rating_scale" ?
                    item.rows ? item.rows.map((option, idx) => {
                      return (
                        <div className='radioCon' key={"id_" + idx}>
                          <div className='radioCon1'>
                            <input type="text" value={option.value} onChange={(event) => handleScreenerBoxRow(event, index, idx)} />
                            {idx > 0 ?
                              <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                                onClick={() => RemoveScreenerBoxRow(index, idx)}
                              />
                              : ""}
                          </div>
                        </div>
                      )
                    }) : "" :
                    item.options ? item.options.map((option, idx) => (
                      <div className='radioCon' key={"id_" + idx}>
                        <div className='radioCon1'>
                          <input type="text" value={option.value} onChange={(event) => handleScreenerBoxOptions(event, index, idx)} />
                          {idx > 0 ?
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveScreenerBoxOption(index, idx)}
                            />
                            : ""}
                        </div>
                      </div>
                    )) : ""}
                  <button className="add_screener_bx_option" type="btn" onClick={item.questionType === "matrix_rating_scale" ? () => AddScreenerBoxQueRow(index) : () => AddScreenerBoxQueOpt(index)}>{item.questionType === "matrix_rating_scale" ? "+ Add Row" : "+ Add Answer Choice"}</button>
                </div>
                {item.questionType === "matrix_rating_scale" ?
                  <div className="mtx_col">
                    <div className="mtxrx_hder">
                      <h3 className="matr_col">Column</h3>
                    </div>
                    {item.columns ? item.columns.map((option, idx) => (
                      <div className='radioCon' key={"id_" + idx}>
                        <div className='radioCon1'>
                          <input type="text" value={option.value} onChange={(event) => handleScreenerBoxCol(event, index, idx)} />
                          {idx > 0 ?
                            <Image src={cross} thumbnail={true} className='cross_sign_std screener_crs'
                              onClick={() => RemoveScreenerBoxCol(index, idx)}
                            />
                            : ""}
                        </div>
                      </div>
                    ))
                      : ""
                    }
                    <button className="add_screener_bx_option" type="btn" onClick={() => AddScreenerBoxQueCol(index)} >{item.questionType === "matrix_rating_scale" ? "+ Add Column" : "+ Add Answer Choice"}</button>
                  </div> : ""
                }
              </div>
              : ""}
            <div className={'copyDeleteCon mt-5 ' + (item.questionType)}>
              {item.questionType === "matrix_rating_scale" ?
                <div className="btn_st">
                  <Stack direction="vertical" gap={3}>
                    {item.singleRowRating === false &&
                      <Form.Check
                        type="switch"
                        id="forceRanking"
                        checked={item.forceRanking}
                        name="forceRanking"
                        label="Force Response for Each Row"
                        onChange={(event) => handleBtnChange(event, index)}
                      />
                    }
                    {item.forceRanking === false &&
                      <Form.Check
                        type="switch"
                        id="singleRowRating"
                        checked={item.singleRowRating}
                        name="singleRowRating"
                        label="Make This a Single-Row Rating Scale"
                        onChange={(event) => handleBtnChange(event, index)}
                      />
                    }
                  </Stack>
                </div>
                : ""
              }
              <div className="cp_dlt">
                <Image src={copy} className="copy-img" alt="logo"
                  onClick={() => CloneScreenerBox(index)} />
                <Image src={deleteIcon} className="copy-img" alt="logo"
                  onClick={() => RemoveScreenerBox("question", index)} />
              </div>
            </div>
          </div>
        )}

        <div className="buttonCon">
          <button onClick={AddScreenerBox} className={"button " + (enblAddCond ? " disable_active_btn " : "")} disabled={enblAddCond}>
            ADD QUESTION
          </button>
        </div>
      </div>
    </div>
  } else if (currentTab === 1) {
    currentData = <div className="main_screener_v3">
      <div className={"wrapper " + ((createScreener?.screenerConditionsBox?.length > 0) ? "screner_scroll_active" : "")}>
        {createScreener?.screenerConditionsBox?.map((item, index) =>
          <div className='QuestionBox' key={"ixd_" + index}>
            <p className='hideText' style={{ marginTop: '4%' }}>{"Terminating Condition"}</p>
            <div className='whenCon'>
              <select className='dropdown1' name="condQuestion" value={item.question} onChange={(event) => handleChangeQuesCond(event, index)} >
                <option value="">Question</option>
                {createScreener?.screenerQuestionBox ? createScreener?.screenerQuestionBox?.map((data, indx) => {
                  if (data.questionType !== "text_box") {
                    return (<option value={data.question} data_index={indx} key={"qts_" + indx}>{data.question}</option>)
                  } else {
                    return null;
                  }
                }
                ) : ""
                }
              </select>
              <select className='dropdown1' name="condQuestionCondition" value={item.condition} onChange={(event) => handleChangeQuesCond(event, index)}>
                <option value="">Condition</option>
                <option value="euqal">Equal</option>
                <option value="not_equal">Does Not Equal</option>
              </select>
              <select className='dropdown1' name="condAnswer" value={item.answer} onChange={(event) => handleChangeQuesCond(event, index)}>
                <option value="">Answer</option>
                {
                  item.question ?
                    createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === item.question)] ?
                      createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === item.question)].questionType === "matrix_rating_scale" ?
                        createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === item.question)].rows.map((data, ind) => {
                          return (createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === item.question)].columns.map((innerData, indxx) =>
                            <option value={data.value + "_" + innerData.value} key={"qsd_" + indxx}>{data.value + "_" + innerData.value}</option>
                          ))
                        })
                        :
                        createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === item.question)].options.map((data, ind) =>
                          <option value={data.value} key={"qsd_" + ind}>{data.value}</option>
                        )
                      : ""
                    : ""
                }
              </select>
            </div>
            {item.otherCondition ? item.otherCondition.map((data, ind) =>
              <div className='whenCon' ds={item.otherCondition.length} style={{ marginTop: '3%' }} key={"dxs_" + ind}>
                <select className='dropdown1' style={{ width: '81px' }} name="otherCondOperator" value={data.operator} onChange={event => handleOtherCondData(event, index, ind)}>
                  <option value="and">AND</option>
                  <option value="or">OR</option>
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondQuestion" value={data.question} onChange={event => handleOtherCondData(event, index, ind)}>
                  <option value="">Question</option>
                  {createScreener.screenerQuestionBox.map((ques, idp) => {
                    if (ques.questionType !== "text_box") {
                      return (<option value={ques.question} key={"qse_" + idp}>{ques.question}</option>)
                    } else {
                      return null;
                    }
                  }
                  )}
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondCondition" value={data.condition} onChange={event => handleOtherCondData(event, index, ind)}>
                  <option value="">Condition</option>
                  <option value="euqal">Equal</option>
                  <option value="not_equal">Does Not Equal</option>
                </select>
                <select className='dropdown1' style={{ width: '192px' }} name="otherCondAnswer" value={data.answer} onChange={event => handleOtherCondData(event, index, ind)}>
                  <option value="">Answer</option>
                  {data.question ?
                    createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === data.question)] ?
                      createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === data.question)].questionType === "matrix_rating_scale" ?
                        createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === data.question)].rows.map((rowData, indst) => {
                          return (createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === data.question)].columns.map((innerData, indstu) =>
                            <option value={rowData.value + "_" + innerData.value} key={"qswsd_" + indstu}>{rowData.value + "_" + innerData.value}</option>
                          ))
                        })
                        :
                        createScreener.screenerQuestionBox[createScreener.screenerQuestionBox.findIndex((x) => x.question === data.question)].options.map((ans, ind) =>
                          <option value={ans.value} key={"qsp_" + ind}>{ans.value}</option>
                        )
                      : ""
                    : ""}
                </select>
              </div>
            ) : ""}

            <div className="answer_del_flex">
              <label onClick={() => AddNewConditionScreenOpt(index)} className='addAnswer'>+ Add Statement</label>
              <div className='copyDeleteCon'>
                <Image src={copy} className="copy-img" alt="logo" onClick={() => cloneCondTermBox(index)} />
                <Image src={deleteIcon} className="copy-img" alt="logo"
                  onClick={() => RemoveScreenerBox("condition", index)} />
              </div>
            </div>
          </div>
        )}
        <div className="buttonCon">
          <button onClick={AddNewCondBox} className={"button " + (enblAddCond ? " disable_active_btn " : "")} disabled={enblAddCond} >
            ADD CONDITION
          </button>
        </div>

      </div>
    </div>
  }
  
  return (
    <Container className='participant_fac_main_content'>
      {errAlert}

      {createScreener?.screenerQuestionBox?.length > 0 &&
        <div className='topTabCon' style={{ marginTop: 40 }}>

          <div className='topLineCon' style={{ marginLeft: 20 }}>
            <div className={'topLine ' + (currentTab === 0 ? "active" : "")} />
            <p className='topText' style={{ color: "#A3AAC2" }}>Screener Questions</p>
          </div>

          <div className='topLineCon' style={{ marginLeft: 20 }}>
            <div className={'topLine ' + (currentTab === 1 ? "active" : "")} />
            <p className='topText' style={{ color: "#A3AAC2" }}>Conditions</p>
          </div>
        </div>}

      <div className="studies_cstm_main">
        {(!showLoading && createScreener?.screenerQuestionBox?.length > 0) &&
          currentData
        }
        {showLoading &&
          <div className='cstm_loader'>
            <div className='lds-dual-ring'></div>
          </div>
        }
        {studyIdError &&
          <div className='cstm_loader'>
            <p>{studyIdErrorMsg}</p>
          </div>}
      </div>

      {createScreener?.screenerQuestionBox?.length > 0 &&
        <Stack gap={3} direction="horizontal" className="mt-3">
          <Button className={"cstm_stdy_tb cstm_stdy_prev" + "success next_active"}
            onClick={() => currentTab === 0 ? exitBtnClick() : setCurrentTab(0)}>
            {currentTab === 0 ? "EXIT" : "BACK"}
          </Button>
          <Button className="success ms-auto cstm_stdy_tb cstm_stdy_next"
            onClick={() => currentTab === 0 ? handleNextPress() : completePress()}>
            {currentTab === 0 ? "NEXT" : "COMPLETE"}
          </Button>
        </Stack>}
    </Container >
  );

}