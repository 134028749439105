import React, { useState, useEffect } from 'react';

import searchIcon from '../../assets/searchIcon.png'
import Image from 'react-bootstrap/Image'
import StudyInfo from './StudyInfo'
import ScreenerData from './ScreenerData'
import ImplicitResult from './ImplicitResult'
import ServeyResult from './ServeyResult'
import StudyDataGraph from './StudyDataGraph'
import Report from './Report'
import FilterTab from './FilterTab'
import Axios from 'axios';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Alert, Button, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var appUrl = process.env.REACT_APP_API_URL + apiVersion + "studies";
var generateLinksUrl = process.env.REACT_APP_API_URL + apiVersion + "participants/generate"
var generateGroupLinksUrl = process.env.REACT_APP_API_URL + apiVersion + "group/studies/"
var groupAppUrl = process.env.REACT_APP_CLIENT_URL + "/thirdparty/"
var screenerReportApi = process.env.REACT_APP_API_URL + apiVersion + "report/screener-study/";
var surveyReportApi = process.env.REACT_APP_API_URL + apiVersion + "report/survey-study/";
var implicitReportApi = process.env.REACT_APP_API_URL + apiVersion + "report-implicit/study/";
var studyDataApi = process.env.REACT_APP_API_URL + apiVersion + "report/study/";

var leftUserSreener = process.env.REACT_APP_API_URL + apiVersion + "report/screener-left-user/";
var leftUserStudy = process.env.REACT_APP_API_URL + apiVersion + "report/study-left-user/";
var leftUserSurvey = process.env.REACT_APP_API_URL + apiVersion + "report/survey-left-user/";


const TabTitles = [
  { id: 1, title: "Study info", selected: true },
  { id: 2, title: "Screener data", selected: false },
  { id: 3, title: "Study Data", selected: false },
  { id: 4, title: "implicit results", selected: false },
  { id: 5, title: "Survey results", selected: false },
  { id: 6, title: "Report", selected: false },
  // { id: 5, title: "filters", selected: false },
]

const myComponent = {
  width: '100%',
  height: '50vh',
  overflowX: 'hidden',
  overflowY: 'scroll',
}

let noStudyFound = false

let screenerDataUpdated = false
let studyDataUpdated = false
let surveyDataUpdated = false

export default function ResultsDashboard() {

  let navigate = useNavigate();

  const [TabTitlesData, setTabTitlesData] = useState(TabTitles)
  const [selectedTab, setSelectedTab] = useState(1)
  const [showToast, setShowToast] = useState(false);
  const [errmsg, setErrMsg] = useState('There is issue with request')
  const [StudyData, setStudyData] = useState([])
  const [studyDetails, setStudyDetails] = useState({})
  const [selectedStudyId, setSelectedStudyId] = useState('')
  const [pageNo, setPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [showSeeMore, setShowSeeMore] = useState(true);
  const [timer, setTimer] = useState(null)
  const [screenerData, setScreenerData] = useState({})
  const [surveyData, setSurveyData] = useState({})
  const [implicitData, setImplicitData] = useState({})
  const [studyDataInfo, setStudyDataInfo] = useState({})
  const [invalidStudyID, setInvalidStudyID] = useState(false)
  const [seemoreLoading, setSeemoreLoading] = useState(false)
  const [numOfParticipant, setNumOfParticipant] = useState(1)
  const [numOfParticipantGroup, setNumOfParticipantGroup] = useState(1)
  const [screenerLeftUsers, setScreenerLeftUsers] = useState({});
  const [studyLeftUsers, setStudyLeftUsers] = useState({});
  const [surveyLeftUsers, setSurveyLeftUsers] = useState({});

  const { urlStudyID } = useParams("studyID");

  const ChangeSelectedTab = (idx) => {
    const TabData = [...TabTitlesData]
    setSelectedTab(idx + 1)
    let newarr = TabData?.map((item, index) => {
      if (index === idx) {
        return {
          ...item,
          selected: true,
        }
      } else {
        return {
          ...item,
          selected: false,
        }
      }
    })
    setTabTitlesData(newarr)
  }

  useEffect(() => {
    getStudyList(1)
  }, [])


  // {{neuro_url}}/studies?search=abc&pageSize=10&page=1
  const getStudyList = async (CurrentpageNo) => {
    setPageNo(CurrentpageNo)
    setSeemoreLoading(true)
    const newCookies = new Cookies();
    if (urlStudyID && urlStudyID !== undefined) {
      await Axios.get(
        appUrl + `/${urlStudyID}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
          setStudyDetails(response?.data?.data)
          setSelectedStudyId(urlStudyID)
          getScreenerReport(urlStudyID)
          getLeftUsersData(urlStudyID)
          setSeemoreLoading(false)
        }).catch(error => {

          setInvalidStudyID(true);
          console.log("api error: ", error)
          setSeemoreLoading(false)
        });
    } else {
      await Axios.get(
        appUrl + `${"?pageSize=15&page="}${CurrentpageNo}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
          if (response?.data?.pagination?.count === 0) {
            noStudyFound = true
          } else {
            noStudyFound = false
          }
          // console.log("res1==>", response.data.pagination.count);
          setStudyData([...StudyData, ...response?.data?.data])
          setShowSeeMore(response?.data.pagination.currentPage < response?.data?.pagination?.totalPages ? true : false)
          if (response?.data?.data?.length > 0 && CurrentpageNo === 1) {
            setStudyDetails(response?.data?.data[0])
            setSelectedStudyId(response?.data?.data[0]?._id)
            getScreenerReport(response?.data?.data[0]?._id)
            getLeftUsersData(response?.data?.data[0]?._id)
          }
          setSeemoreLoading(false)
        }).catch(error => {
          setSeemoreLoading(false)
          console.log("api error: ", error)
        });
    }
  }

  const getStudyDetails = (idx) => {
    setStudyDetails(StudyData[idx])
    setSelectedStudyId(StudyData[idx]?._id)
    getScreenerReport(StudyData[idx]?._id)
    getLeftUsersData(StudyData[idx]?._id)
  }

  const getSearchStudyList = async (txt, CurrentpageNo) => {
    // setSearch(txt)
    console.log(" text ", txt, " CurrentpageNo ", CurrentpageNo)
    setPageNo(CurrentpageNo)
    const newCookies = new Cookies();
    if (txt !== '') {
      await Axios.get(
        appUrl + `${"?search="}${txt}${"&pageSize=15&page="}${CurrentpageNo}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
          if (response?.data?.data?.length > 0) {
            setShowSeeMore(response?.data.pagination?.count < response?.data?.pagination?.pageSize ? false : true)
            setStudyData(response?.data?.data)
            setStudyDetails(response?.data?.data[0])
            setSelectedStudyId(response?.data?.data[0]?._id)
            setShowToast(false)
            setErrMsg("");
          } else {
            // setShowToast(true)
            setErrMsg("There is no matching study found")
            setStudyData([])
            setSelectedStudyId("")
          }
        }).catch(error => {
          console.log("api error: ", error)
        })
    } else {
      await Axios.get(
        appUrl + `${"?pageSize=15&page=1"}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
          setStudyData(response?.data?.data)
          if (response?.data?.data?.length > 0) {
            setStudyDetails(response?.data?.data[0])
            setSelectedStudyId(response?.data?.data[0]?._id)
            setShowToast(false);
            setErrMsg("");
            setShowSeeMore(response?.data.pagination?.count < response?.data?.pagination?.pageSize ? false : true);
          }
        }).catch(error => {
          console.log("api error: ", error)
        });
    }
  }

  const inputChanged = e => {
    setSearch(e.target.value)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      getSearchStudyList(e.target.value, 1)
    }, 500)

    setTimer(newTimer)
  }

  const getSearchSeeMoreStudyList = async (CurrentpageNo) => {
    setPageNo(CurrentpageNo)
    setSeemoreLoading(true)
    const newCookies = new Cookies();
    await Axios.get(
      appUrl + `${"?search="}${search}${"&pageSize=15&page="}${CurrentpageNo}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        if (response?.data?.data.length > 0) {
          setStudyData([...StudyData, ...response?.data?.data])
        }
        setShowSeeMore(response?.data.pagination?.count < response?.data?.pagination?.pageSize ? false : true)
        setSeemoreLoading(false)
      }).catch(error => {
        console.log("api error: ", error)
        setSeemoreLoading(false)
      })
  }

  const generateGroupLinksApi = async () => {
    const newCookies = new Cookies();
    if (numOfParticipantGroup > 0) {
      await Axios.get(
        generateGroupLinksUrl + selectedStudyId + '/' + numOfParticipantGroup,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
          const downloadUrl = URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'file.csv'); // Set the desired file name here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

        }).catch(error => {
          setErrMsg("generate group link api error==>" + error);
          showToast(true);
        });
    }
  }

  const generateLinksApi = async () => {
    var dataObj = {
      studyID: selectedStudyId,
      number_of_participants_to_create: numOfParticipant
    }
    const newCookies = new Cookies();
    if (numOfParticipant > 0) {
      await Axios.post(
        generateLinksUrl,
        dataObj,
        {
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`,
            'Content-type': 'application/json'
          }
        }
      ).then(response => {
        const downloadUrl = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.csv'); // Set the desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(error => {
        setShowToast(true);
        if (error.response.status === 422) {
          setErrMsg("Please provide number of participants to create");
        } else {
          setErrMsg("Error " + error.response.status + ": There is issue with request");
        }
      })
    }
  }

  const getScreenerReport = async (studyId) => {
    screenerDataUpdated = false
    studyDataUpdated = false
    surveyDataUpdated = false
    const newCookies = new Cookies();
    await Axios.get(
      screenerReportApi + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        screenerDataUpdated = true
        setScreenerData(response?.data?.data)
      }).catch(error => {
        screenerDataUpdated = true
        console.log("api error: ", error)
      });

    await Axios.get(
      implicitReportApi + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setImplicitData(response?.data?.data)
      }).catch(error => {
        console.log("api error: ", error)
      });

    await Axios.get(
      studyDataApi + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setStudyDataInfo(response?.data?.data)
        studyDataUpdated = true
      }).catch(error => {
        studyDataUpdated = true
        console.log("api error: ", error)
      });

    await Axios.get(
      surveyReportApi + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setSurveyData(response?.data?.data)
        surveyDataUpdated = true
      }).catch(error => {
        surveyDataUpdated = true
        console.log("api error: ", error)
      });
  }

  const getLeftUsersData = async (studyId) => {
    const newCookies = new Cookies();
    await Axios.get(
      leftUserSreener + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setScreenerLeftUsers(response?.data?.data)
      }).catch(error => {
        console.log("api error: ", error)
      });

    await Axios.get(
      leftUserStudy + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setStudyLeftUsers(response?.data?.data)
      }).catch(error => {
        console.log("api error: ", error)
      });

    await Axios.get(
      leftUserSurvey + `${studyId}`,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setSurveyLeftUsers(response?.data?.data)
      }).catch(error => {
        console.log("api error: ", error)
      });
  }

  const errAlert = <Alert className='cstm_sudy_alrt' variant={"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
    <p>{errmsg}</p>
  </Alert>;

  // console.log("showSeeMore ", showSeeMore)

  return (
    <div className='mainResultTabStudyCon cstm_main_result'>
      {errAlert}
      {invalidStudyID ? <div className='cstm_loader'><p>Study ID Is Not Valid</p></div> :
        noStudyFound ?
          <div className='cstm_loader'>
            <p>{"There has been no study created yet."}</p>
          </div>
          :
          selectedStudyId !== '' ?
            <>
              <div className='BoxConResultMain cstm_main_result_inner'>
                {urlStudyID && urlStudyID !== undefined ? "" :
                  <div className={'leftConResultMain'}>
                    <div className='searchBarCon'>
                      <Image src={searchIcon} className="searchIconSty" alt="logo" />
                      <input type="text" className="searchInputSty" placeholder='Search...'
                        value={search} onChange={
                          // (event) => getSearchStudyList(event.target.value, 1)
                          inputChanged
                        } />
                    </div>

                    <div style={myComponent}>
                      {
                        StudyData?.map((item, index) =>
                          <div key={item._id + "ky" + crypto.randomUUID()}>
                            <button onClick={() => getStudyDetails(index)} studyid={item._id} className={item._id === selectedStudyId ? 'studyNameConResultSelected' : 'studyNameConResult'}>
                              <p className='studyNameStyResult'>{item?.projectTitle}</p>
                            </button>
                            {showSeeMore ?
                              index === StudyData?.length - 1 &&
                              <>
                                <button onClick={() => { search === '' ? getStudyList(pageNo + 1) : getSearchSeeMoreStudyList(pageNo + 1) }} className='ResultSeemorebtnSty'>
                                  {seemoreLoading ?
                                    <div className='cstm_loader cstm_seeMore'>
                                      <div className='lds-dual-ring'></div>
                                    </div>
                                    :
                                    <p className='ResultSeemoreTxtSty'>See More</p>}

                                </button>
                              </>
                              : null}
                          </div>
                        )
                      }
                      {(search !== "" && errmsg === "There is no matching study found") && <p className='warnings'>There is no matching study found</p>}
                    </div>

                  </div>}

                <div className='RightConResultMain'>
                  <div className='ResultTabCon'>
                    {TabTitlesData?.map((item, index) =>
                      (studyDetails.isIncludePostSurvey === false && item.id === 5) ? null :
                        <button onClick={() => ChangeSelectedTab(index)} key={item.id} className='ResultTabTxtCon' style={{ backgroundColor: item.selected ? "#4273F0" : "#ffffff" }}>
                          <p className='ResultTabText' style={{ color: item.selected ? "#FFFFFF" : "#1D1C1D" }}>{item.title}</p>
                        </button>
                    )}
                  </div>

                  <div className='ResultTabRenderCon'>
                    {selectedTab === 1 ?
                      <StudyInfo studyDetails={studyDetails} allStudyData={StudyData} setAllStudyData={setStudyData}
                        setShowToast={setShowToast} setErrMsg={setErrMsg} />
                      : selectedTab === 2 ?
                        <ScreenerData stdyId={studyDetails?._id} screenerData={screenerData} updateScreenerPress={() => navigate("/screener/" + selectedStudyId)}
                          setShowToast={setShowToast} setErrMsg={setErrMsg} screenerDataUpdated={screenerDataUpdated}  />
                        : selectedTab === 3 ?
                          <StudyDataGraph studyDataInfo={studyDataInfo} studyDetails={studyDetails} allStudyData={StudyData} setAllStudyData={setStudyData} studyDataUpdated={studyDataUpdated} />
                          : selectedTab === 4 ?
                            <ImplicitResult implicitData={implicitData} />
                            : selectedTab === 5 ?
                              <ServeyResult surveyData={surveyData} stdyId={studyDetails?._id} setShowToast={setShowToast} setErrMsg={setErrMsg} surveyDataUpdated={surveyDataUpdated} />
                              : selectedTab === 6 &&
                              <Report screenerLeftUsers={screenerLeftUsers} studyLeftUsers={studyLeftUsers}
                                surveyLeftUsers={surveyLeftUsers} studyDetails={studyDetails}
                              />
                    }

                  </div>
                </div>
              </div>

              <div className="resultReateNewStudyMainCon">

                <div className="resultReateGenerateBtnCon">

                  <div className="resultReateGenerateLinkeBtnCon">
                    <input type="number"
                      className='num_of_participant_inputDashboard'
                      value={numOfParticipant}
                      min="1"
                      onChange={(event) => setNumOfParticipant(event.target.value)}
                    />
                    <Button onClick={() => generateLinksApi()} className='resultGenerateLinksBtnSty'>GENERATE INDIVIDUAL LINKS</Button>
                  </div>


                  <div className="resultReateGenerateLinkeBtnCon1">
                    <input type="number"
                      className='num_of_participant_inputDashboard'
                      value={numOfParticipantGroup}
                      min="1"
                      onChange={(event) => setNumOfParticipantGroup(event.target.value)}
                    />
                    <Button onClick={() => generateGroupLinksApi()}
                      style={{ marginLeft: 15 }} className='resultGenerateLinksBtnSty'>GENERATE GROUP LINKS</Button>
                  </div>


                </div>
                <Button className='createNewStudyBtnSty'
                  onClick={() => {
                    localStorage.removeItem("createStudyId");
                    localStorage.removeItem("createScreenerId");
                    localStorage.removeItem("createSurvayId");
                    localStorage.removeItem("studyTab");
                    navigate("/create-study")
                  }}
                >+ CREATE NEW STUDY</Button>

              </div>
              {(numOfParticipant === "0" || numOfParticipant === 0) && <p class="warning" style={{ color: "red", marginBottom: "0px", paddingBottom: "0px", paddingTop: "5px", textAlign: "center" }}>Number of participants must be greater then 0</p>}
              {(numOfParticipantGroup === "0" || numOfParticipantGroup === 0) && <p class="warning" style={{ color: "red", marginBottom: "0px", paddingBottom: "0px", paddingTop: "5px", textAlign: "center" }}>Number must be greater then 0</p>}

            </>
            :
            <div className='cstm_loader'>
              <div className='lds-dual-ring'></div>
            </div>
      }
    </div>
  )
}


