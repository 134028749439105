import { Button, Form, Alert, Card, Image } from 'react-bootstrap';
import {  useState } from 'react';
import Axios from 'axios';
import Cookies from 'universal-cookie';

var apiVersion = process.env.REACT_APP_API_VERSION;
if(apiVersion===undefined || apiVersion === ""){
    apiVersion = "/api/v1/";
}
var appUrl = process.env.REACT_APP_API_URL+apiVersion+"superadmin/login";

function Login() {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showToast , setShowToast] = useState(false);
  const [isLoggedin,setIsLoggedin] = useState(false);
  const [alertShow,setAlertShow] = useState(true);
  const [errmsg,setErrMsg] = useState('');

  
  var loginBtnControl = false;
  var chkErr = "";
  const cookies = new Cookies();
  if(cookies.get("neurojwt") !== undefined){
    window.location.href="/";
  }


  try {
    if(window.location.href.indexOf("token_error")>-1 && window.location.href.split("token_error=")[1] !== ""){
      var tknerr = window.location.href.split("token_error=")[1];
      chkErr = <Alert variant="danger" show={alertShow} onClose={() => setAlertShow(false)} dismissible>
      <p>{tknerr.replaceAll("-"," ")}</p>
    </Alert>;
    }
  } catch (error) {
    console.log(error);
  }

  const googleAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/`,"_self"
    );
  }


  const handleSubmitForm = async(event) => {
    event.preventDefault();
    loginBtnControl = true;
    if(validate()){
      try {
        var data = {
          email: email,
          password : password 
        }
        await Axios.post(
        appUrl, 
        data, 
        {
          headers:{"Content-Type": "application/json",
          "Accept": "application/json"}
        }).then(response => {
          if(response && response.data){
            if(response.data.status){
              const cookies = new Cookies();
              cookies.set('neurojwt', response.data.jwt, {
                path:"/", 
                expires:  (new Date(Date.now()+ 86400*1000))
              });
              setIsLoggedin(true);
              setShowToast(true);
              setTimeout(() => {
                window.location.href = "/";
              }, 1000);
            }
          }else{
            setErrMsg(response?.data?.message);
            setShowToast(true);
          }
        }).catch(error => {
          setErrMsg(error?.response?.data?.message);
          setShowToast(true);
        }).finally(function () {
          loginBtnControl = false;
        });
      } catch (error) {
        loginBtnControl = false;
        console.log(error);
      }
    }
  }

 

  const validate = () => {
    if(email==="" || email===undefined ){
      setErrMsg("Please Enter Email");
      setShowToast(true);
      return false;
    }
    if(password === "" || password === undefined ){
      setErrMsg("Please Enter Password");
      setShowToast(true);
      return false;
    }
    return true;
  }



const ErrMsg = <Alert variant={isLoggedin?"success":"danger"} show={showToast} onClose={() => setShowToast(false)} dismissible>
<p>{isLoggedin?"Logged in successfully":errmsg}</p>
</Alert>


  return (
    <>
    <div className='cstm_login'>
    {chkErr}
    {ErrMsg}
    <Form onSubmit={handleSubmitForm}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    <br></br>
    <Card style={{ width: '18rem', margin: 'auto' }}>
        <Button variant="white" disabled={loginBtnControl} type="submit" size="lg" onClick={googleAuth}>
          <Image thumbnail={false}  src="https://img.icons8.com/color/16/000000/google-logo.png"></Image>
            <span className="cstm_gogle_btn">  Sign in with Google</span>
        </Button>
      </Card>
    </div>
    </>
  );
}

export default Login;