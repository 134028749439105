
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts"

import Axios from 'axios';
import Cookies from 'universal-cookie';

export const data = [
  ["Task", "stats"],
  ["Completed", 8],
  ["In Complete", 2],
]
export const options = {
  'width': '80%',
  'height': 275,
  'chartArea': { 'width': '100%', 'height': '80%' },
  // 'legend': { position: 'left', alignment: 'center' },
  pieSliceText: 'value',
  legend: {
    position: 'labeled',
    labeledValueText: 'both',
    textStyle: {
      color: '#1D1C1D',
      fontSize: 10
    }
  },
  colors: ['#4273F0', '#ACB9FF', "darkblue"],
  sliceVisibilityThreshold: 0.00005
}
const groupData = [
  // { id: 1, name: "ALL" },
  // { id: 2, name: "GROUP 1" },
  // { id: 3, name: "GROUP 2" },
  // { id: 4, name: "GROUP 3" },
]

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var closeStudyUrl = process.env.REACT_APP_API_URL + apiVersion + "closed-study/";

function StudyDataGraph({ studyDataInfo, studyDetails, allStudyData, setAllStudyData, studyDataUpdated }) {
  const [activeFilter, setActiveFilter] = useState(1)

  const [isStudyClosed, setIsStudyClosed] = useState(studyDetails.isClosed)

  useEffect(() => {
    setIsStudyClosed(studyDetails.isClosed)
  }, [studyDetails.isClosed])


  const closeStudyFun = async () => {
    const newCookies = new Cookies();
    await Axios.get(
      closeStudyUrl + studyDetails._id,
      {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`,
          'Content-type': 'application/json'
        }
      }
    ).then(async response => {
      // console.log("response", response);
      if (response.status === 200) {
        var newStudyDataArr = allStudyData
        let index = newStudyDataArr.findIndex((item) => item._id === studyDetails._id)
        if (index !== -1) {
          setIsStudyClosed(true)
          newStudyDataArr[index].isClosed = true
          setAllStudyData(newStudyDataArr)
        }
      }

    }).catch(error => {
      console.log("error", error);
    })
  }

 


  return (
    // <>
    //   {studyDataUpdated ?
        <>
          <div className='resultstudyDataMainCon'>

            <div className='resultstudyDataLeftFilterCon'>
              {(groupData?.length > 0) && groupData?.map((item, index) =>
                <button onClick={() => setActiveFilter(item.id)} key={index + "studyDataInfo"}
                  className={activeFilter === item.id ? 'studyDataScreenFilterBtnActive' : 'studyDataScreenFilterBtn'}>{item.name}</button>
              )}
            </div>

            <div className='resultstudyDataRightCon'>
              <div className='resultScreenerCompletionStatus'>
                <p className='ScreenerCompletionTxtStyResult'>Study Completion Rate</p>
                <div className='resultScreenerCompletionGraphStatsCon'>
                {studyDataUpdated ?
                ( studyDataInfo?.chart[1][1] === 0 && studyDataInfo?.chart[2][1] === 0 && studyDataInfo?.chart[3][1] === 0 ) ?
                  <div className='noGraphTextCon'>
                    <p className='ScreenerCompletionTxtStyResult'>No link generated against this study</p>
                  </div>
                  :
                  <Chart
                    chartType="PieChart"
                    data={studyDataInfo?.chart}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                  />
                  : null}
                </div>
                {/* {studyDataInfo?.chart?.slice(1)?.map((item, index) => (
                <p key={index} className='StaticTextSty'>{item[0]} : {item[1]}</p>
              ))} */}
              </div>
            </div>

          </div>

          <div className="resultUpdateScreenerBtnCon">
            <div>
              <button onClick={() => closeStudyFun()}
                disabled={isStudyClosed ? true : false} className="createNewStudyBtnSty"
                style={{ background: "#F6F6FC", color: "#1D1C1D", border: "1px solid #DADADA" }}
              // style={{ background: isStudyClosed ? "gray" : "#283182" }}
              >
                {isStudyClosed ? "STUDY CLOSED" : "CLOSE STUDY"}
              </button>
            </div>
          </div>
        </>

    //     :
    //     <div className='cstm_loader'>
    //       <div className='lds-dual-ring'></div>
    //     </div>
    //   }
    // </>
  )
}
export default StudyDataGraph

