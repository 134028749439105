
import React, { useEffect, useState } from 'react';

import Image from 'react-bootstrap/Image'
import { Row, Col, Alert } from "react-bootstrap"

import Axios from 'axios';
import Cookies from 'universal-cookie';

var domainUrl = process.env.REACT_APP_API_URL;

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var closeStudyUrl = process.env.REACT_APP_API_URL + apiVersion + "closed-study/";
var downloadResult = process.env.REACT_APP_API_URL + apiVersion + "export/study-results/";
var downloadRawResult = process.env.REACT_APP_API_URL + apiVersion + "export/study-times/";

function StudyInfo({ studyDetails, allStudyData, setAllStudyData, setErrMsg, setShowToast, }) {

    const { projectTitle, client, fieldWork, redirectLink,
        mediaTextEnable, mediaBrand, mediaImgEnable, mediaAttr,
        createdDate, studyTypeName, previewCode, _id, isClosed, externalLink, totalClickedUserExitButton } = studyDetails


    const [isStudyClosed, setIsStudyClosed] = useState(isClosed)

    useEffect(() => {
        setIsStudyClosed(isClosed)
    }, [isClosed])


    const closeStudyFun = async () => {
        const newCookies = new Cookies();
        await Axios.get(
            closeStudyUrl + _id,
            {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`,
                    'Content-type': 'application/json'
                }
            }
        ).then(async response => {
            // console.log("response", response);
            if (response.status === 200) {
                var newStudyDataArr = allStudyData
                let index = newStudyDataArr.findIndex((item) => item._id === _id)
                if (index !== -1) {
                    setIsStudyClosed(true)
                    newStudyDataArr[index].isClosed = true
                    setAllStudyData(newStudyDataArr)
                }
            }

        }).catch(error => {
            console.log("error", error);
        })
    }

    const generateAggrigateResult = async () => {
        const newCookies = new Cookies()
        await Axios.get(
            downloadResult + _id,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                const csvContent = response?.data;
                const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }).catch(error => {
                setErrMsg(error?.response?.data?.message)
                setShowToast(true)
            })
    }

    const generateRawResultApi = async () => {
        const newCookies = new Cookies()
        await Axios.get(
            downloadRawResult + _id,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                const csvContent = response?.data;
                const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }).catch(error => {
                setErrMsg(error?.response?.data?.message)
                setShowToast(true)
            })
    }

    return (
        <div className='resultStudyInfoDataCon'>
            <div className='resultStudyStatsCon'>
                <div className='resultStudyStatsCard'>
                    <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Project Name</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'>{projectTitle}</p>
                        </div>
                    </div>

                    <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Client</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'>{client}</p>
                        </div>
                    </div>

                    <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Created</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'>{createdDate ? new Date(createdDate)?.toDateString()?.slice(3) : ""}</p>
                            {/* <p className='resultStudyStatsValue'>Juy 23, 2022</p> */}
                        </div>
                    </div>

                    {/* <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Fieldwork</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'>{fieldWork ? new Date(fieldWork)?.toDateString()?.slice(3) : ""}</p>
                        </div>
                    </div> */}

                    {/* <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Study Type</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'>{studyTypeName}</p>
                        </div>
                    </div>

                    <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon'>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'></p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsValue'></p>
                        </div>
                    </div> */}

                    <div className='resultStudyStatsline' />
                    <div className='resultStudyStatsDataCon' style={{ paddingBottom: externalLink ? 0 : 25 }}>
                        <div className='resultStudyStatsHeadingCon'>
                            <p className='resultStudyStatsHeading'>Preview Link</p>
                        </div>
                        <div className='resultStudyStatsHeadingCon'>
                            <a className='resultStudyStatsValue' style={{ color: "#2A40D4" }} href={window.location.origin + '/study/' + _id + "?rid=" +previewCode }>{(window.location.origin + '/study/' + _id + "?rid=" + previewCode ).substring(0, 16) + "..."}</a>
                          {/*   <a className='resultStudyStatsValue' style={{ color: "#2A40D4" }} href={window.location.origin + '/study/' + _id + "/" + previewCode}>{(window.location.origin + '/study/' + _id + "/" + previewCode).substring(0, 16) + "..."}</a> */}
                        </div>
                    </div>
                    {externalLink ?
                        <>
                            <div className='resultStudyStatsline' />
                                <div className='resultStudyStatsDataCon' >
                                    <div className='resultStudyStatsHeadingCon'>
                                        <p className='resultStudyStatsHeading'>External Link</p>
                                    </div>
                                    <div className='resultStudyStatsHeadingCon'>
                                        <a className='resultStudyStatsValue' style={{ color: "#2A40D4" }} target="_blank" href={externalLink}>{(externalLink).substring(0, 16) + "..."}</a>
                                    </div>
                                </div>
                        </>
                        : null}
                     {redirectLink ?
                        <>
                            <div className='resultStudyStatsline' />
                            <div className='resultStudyStatsDataCon' style={{ paddingBottom: 25 }}>
                                <div className='resultStudyStatsHeadingCon'>
                                    <p className='resultStudyStatsHeading'>Screenout Redirect</p>
                                </div>
                                <div className='resultStudyStatsHeadingCon'>
                                    <a className='resultStudyStatsValue' style={{ color: "#2A40D4" }} target="_blank" href={redirectLink}>{(redirectLink).substring(0, 16) + "..."}</a>
                                </div>
                            </div>
                        </>
                        : null}   


                </div>
            </div>
            <div className='parentResAttrCon'>
                <div className={'resultStudyAttributeCon' + (mediaImgEnable ? " mediaAttriImg " : " mediaAttriText ") + (mediaTextEnable ? " mediaBrandText " : " mediaBrandImg ")}>
                    {mediaAttr?.length > 0 ?
                        <div className='resultStudyAttributeInnerCon'>
                            <div className='resultStudyAttributeDataCon'>
                                <p className='StimulusTxtStyle'>Primes</p>
                                <Row>
                                    {mediaAttr?.map((item, index) => (
                                        <Col xs="6" key={index}>
                                            {mediaImgEnable ?
                                                item.image !== '' ?
                                                    <div className='resultPrimedBrandPictureCon' style={{ marginLeft: index !== 0 && "4%" }}>
                                                        <Image src={`${item.image}`} className="pictureIconStyleResult" alt="logo" />
                                                    </div> : null
                                                :
                                                item.text !== '' ?
                                                    <div className='attributeValueConSty'>
                                                        <p className='attributeValueTxtSty'>{item.text}</p>
                                                    </div> : null
                                            }
                                        </Col>
                                    ))}
                                </Row>

                            </div>
                        </div>
                        : ""}

                    <div className={'resultStudyAttributeInnerCon'}>
                        <div>
                            {mediaBrand?.length > 0 ?
                                <div className='resultStudyPrimedBrandCon'>
                                    <p className='StimulusTxtStyle'>Brand(s)</p>
                                    <div className='resultPrimedBrandCon'>
                                        {mediaTextEnable ?
                                            mediaBrand?.map((item, index) =>
                                                <div key={index} className='attributeValueConSty' style={{ marginLeft: index !== 0 && "4%" }}>
                                                    <p className='attributeValueTxtSty'>{item.text}</p>
                                                </div>
                                            )
                                            :
                                            mediaBrand?.map((item, index) =>
                                                <div key={index} className='resultPrimedBrandPictureMainUpperCon'>
                                                    <p className='attributeValueTxtSty'>{`${"Stimulus "}${index + 1}`}</p>
                                                    <div className='resultPrimedBrandPictureCon12' key={item._id} style={{ marginLeft: index !== 0 && "4%" }}>
                                                        <Image src={`${item.image}`} className="pictureIconStyleResult" alt="logo" />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                : ""}
                            <div className='resultStudyInfoPercentageCon'>
                                <p className='resultStudyInfoSuccessTxt'>Clicked on exit button</p>
                                <div className='resultStudyInfoPercentageTxtCon'>
                                    <p className='resultStudyInfoPercentageTxt'>{totalClickedUserExitButton?totalClickedUserExitButton:0}</p>
                                </div>
                            </div>
                            <div className='resultStudyInfoPercentageCon'>
                                <p className='resultStudyInfoSuccessTxt'>Success Percentage</p>
                                <div className='resultStudyInfoPercentageTxtCon'>
                                    <p className='resultStudyInfoPercentageTxt'>80%</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="resultUpdateScreenerBtnCon">
                    <div>

                        <button
                            onClick={() => generateRawResultApi()} className="RawResultsStudyBtnSty"
                            style={{ border: "1px solid #DADADA" }}>
                            {"RAW RESULTS"}
                        </button>

                        <button
                            onClick={() => generateAggrigateResult()} className="RawResultsStudyBtnSty"
                            style={{ border: "1px solid #DADADA" }} >
                            {"AGGREGATE RESULTS"}
                        </button>


                        <button onClick={() => closeStudyFun()}
                            disabled={isStudyClosed ? true : false} className="createNewStudyBtnSty"
                            style={{ background: "#F6F6FC", color: "#1D1C1D", border: "1px solid #DADADA" }}>
                            {isStudyClosed ? "STUDY CLOSED" : "CLOSE STUDY"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StudyInfo

