import React, { useState, useEffect, useRef } from 'react'
import Axios from 'axios'
import Cookies from 'universal-cookie';
import { Image } from 'react-bootstrap'

var domainUrl = process.env.REACT_APP_API_URL;

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var saveAnswerUrl = process.env.REACT_APP_API_URL + apiVersion + "study/answer/";

var isIndexMinus = false

function ParticpantSurvey({ fullStudyData, currentTab, setCurrentTab, studyID, userID }) {

  const [surveyAnswers, setSurveyAnswers] = useState([])
  const [currentQuestionTab, setCurrentQuestionTab] = useState(0)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [updatedQuestions, setUpdatedQuestions] = useState(fullStudyData?.surveyQuestionBox)

  const updatedQuestionsRef = useRef(updatedQuestions);
  const _setUpdatedQuestions = data => {
    updatedQuestionsRef.current = data;
    setUpdatedQuestions(data);
  }

  useEffect(() => {
    getSavedAnswers()
  }, [])

  const getSavedAnswers = async () => {
    const newCookies = new Cookies();
    await Axios.get(
      saveAnswerUrl + studyID + "/" + userID,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        setSurveyAnswers(response?.data?.data?.survey)
        if (response?.data?.data?.survey?.length > 0) {
          setIsBtnDisabled(false)
        }
        // validateNextBtn(false, response?.data?.data?.survey)
      }).catch(error => {
        console.log("get save answer api error==>", error);
      });
  }

  useEffect(() => {
    if (surveyAnswers[currentQuestionTab]?.answers?.length > 0) {
      // setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [currentQuestionTab, surveyAnswers])


  const saveUserAnswers = async () => {
    if (surveyAnswers?.length > 0) {
      var surveyAnswersObj = {
        survey: surveyAnswers,
        userId: userID
      }

      if (currentQuestionTab === updatedQuestionsRef.current?.length - 1) {
        surveyAnswersObj["tabScreen"] = 5 + "_survey_" + 0
        surveyAnswersObj["surveyStatus"] = "Completed"
        surveyAnswersObj["status"] = "completed"
      } else {
        surveyAnswersObj["tabScreen"] = currentTab + "_survey_" + currentQuestionTab
      }

      const newCookies = new Cookies();
      await Axios.post(
        saveAnswerUrl + studyID,
        surveyAnswersObj,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${newCookies.get("neurojwt")}`
          }
        }).then(response => {
        }).catch(error => {
          console.log("api error==>", error)
        })
    }
  }

  const handleGetAnserwer = (event, index, type, inx, matrixQuestion, forceRanking, isSingleRow) => {
    let newSurveyAnswer = [...surveyAnswers]
    if (surveyAnswers && surveyAnswers[index] === undefined) {
      newSurveyAnswer.push({ "question": "", "type": "", "answers": [] });
    }
    if (type === "check_boxes") {
      newSurveyAnswer[index].question = event.target.name;
      newSurveyAnswer[index].type = type;
      let ansIdx = newSurveyAnswer[index]?.answers?.findIndex(ans => ans === event.target.value)
      if (ansIdx !== -1) {
        newSurveyAnswer[index]?.answers?.splice(ansIdx, 1);
      } else {
        newSurveyAnswer[index].answers.push(event.target.value);
      }
    } else if (type === "matrix_rating_scale") {
      newSurveyAnswer[index].question = matrixQuestion;
      newSurveyAnswer[index].type = type
      if (newSurveyAnswer[index] && newSurveyAnswer[index]?.answers?.length > 0) {
        if (isSingleRow) {
          let ansIdx = newSurveyAnswer[index]?.answers?.findIndex(ans => ans === event.target.value)
          if (ansIdx !== -1) {
            newSurveyAnswer[index]?.answers?.splice(ansIdx, 1)
          } else {
            let ansIdx = newSurveyAnswer[index]?.answers?.findIndex(ans => ans.split("_")[0] === event.target.value.split("_")[0])
            if (ansIdx !== -1) {
              newSurveyAnswer[index].answers[ansIdx] = event.target.value;
            } else {
              newSurveyAnswer[index].answers.push(event.target.value);
            }
          }
        } else {
          let ansIdx = newSurveyAnswer[index]?.answers?.findIndex(ans => ans.split("_")[0] === event.target.value.split("_")[0])
          if (ansIdx !== -1) {
            newSurveyAnswer[index].answers[ansIdx] = event.target.value;
          } else {
            newSurveyAnswer[index].answers.push(event.target.value);
          }
        }
      } else {
        newSurveyAnswer[index].answers.push(event.target.value);
      }
    } else {
      newSurveyAnswer[index].question = event.target.name;
      newSurveyAnswer[index].type = type;
      newSurveyAnswer[index].answers[0] = event.target.value;
    }
    setSurveyAnswers(newSurveyAnswer);
    validateNextBtn(forceRanking, newSurveyAnswer)
  }

  const HideQuestionFunc = (screenerAnswer, buttonType) => {
    let check = false
    let Doublecheck = true
    let needToHideQuestion = ""
    fullStudyData?.surveyConditionsBox?.forEach((conditionObj, index) => {
      if (Doublecheck) {
        let indexOf1stQues = screenerAnswer.findIndex((ans1stObj) => ans1stObj.question.trim() === conditionObj.question.trim())
        if (indexOf1stQues > -1) {
          if (conditionObj.condition === "euqal") {
            if (screenerAnswer[indexOf1stQues]?.answers?.includes(conditionObj.answer)) {
              check = true
            }
          } else {
            if (!screenerAnswer[indexOf1stQues]?.answers?.includes(conditionObj.answer)) {
              check = true
            }
          }
        }
        if (conditionObj?.otherCondition?.length > 0) {
          conditionObj?.otherCondition?.forEach((conditionSubObj, idx) => {

            let indexOfQues = screenerAnswer.findIndex((ansObj) => ansObj.question.trim() === conditionSubObj.question.trim())
            if (indexOfQues > -1) {
              // check = false
              if (conditionSubObj.condition === "euqal") {
                if (screenerAnswer[indexOfQues]?.answers?.includes(conditionSubObj.answer)) {
                  if (conditionSubObj.operator === "and" && check === true) {
                    check = true
                  } else if (conditionSubObj.operator === "or") {
                    check = true
                  } else {
                    check = false
                  }
                } else {
                  if (conditionSubObj.operator === "or" && check === true) {
                    check = true
                  } else {
                    check = false
                  }
                }
              } else {

                if (!screenerAnswer[indexOfQues]?.answers?.includes(conditionSubObj.answer)) {
                  if (conditionSubObj.operator === "and" && check === true) {
                    check = true
                  } else if (conditionSubObj.operator === "or") {
                    check = true
                  } else {
                    check = false
                  }
                } else {
                  if (conditionSubObj.operator === "or" && check === true) {
                    check = true
                  } else {
                    check = false
                  }
                }
              }
            } else {
              if (conditionSubObj.operator === "or" && check === true) {
                check = true
              } else {
                check = false
              }
            }

          })
        }
        if (check) {
          // return
          Doublecheck = false
          needToHideQuestion = conditionObj?.hideQuestion

          let questionData = [...fullStudyData?.surveyQuestionBox]
          let index = fullStudyData?.surveyQuestionBox?.findIndex(x => x.question === needToHideQuestion)
          if (index !== -1) {
            questionData.splice(index, 1)
            _setUpdatedQuestions(questionData)
          }

        } else {
          needToHideQuestion = conditionObj?.hideQuestion

          let questionData = [...updatedQuestionsRef.current]
          let index = fullStudyData?.surveyQuestionBox?.findIndex(x => x.question === needToHideQuestion)
          if (index !== -1) {
            let idx = questionData?.findIndex(x => x.question === needToHideQuestion)
            if (idx === -1) {
              questionData.splice(index, 0, fullStudyData?.surveyQuestionBox[index])
              _setUpdatedQuestions(questionData)
            }
          }

        }
      }
    })

    return check;
  }

  const NextQuestion = () => {
    isIndexMinus = false
    let lngth = updatedQuestionsRef.current?.length - 1
    saveUserAnswers();
    let resp = HideQuestionFunc(surveyAnswers, "next");
    if (currentQuestionTab < lngth) {
      setCurrentQuestionTab((prev) => prev + 1)
    } else {
      setCurrentTab((prev) => prev + 1);
    }
  }

  const PreviousQuestion = () => {
    saveUserAnswers();
    setIsBtnDisabled(false);
    let resp = HideQuestionFunc(surveyAnswers, "pre")
    if (currentQuestionTab > 0) {
      setCurrentQuestionTab((prev) => prev - 1);
    } else {
      setCurrentTab((prev) => prev - 1);
    }
  }

  const validateNextBtn = (forceRanking, newScreenerAnswer) => {
    if (newScreenerAnswer[currentQuestionTab]?.type === "matrix_rating_scale" && forceRanking) {
      if (newScreenerAnswer[currentQuestionTab]?.answers?.length === fullStudyData?.surveyQuestionBox[currentQuestionTab]?.rows?.length) {
        setIsBtnDisabled(false) // if true then make button false;
      } else {
        setIsBtnDisabled(true)
      }
    }
    else if (newScreenerAnswer[currentQuestionTab]?.answers?.length > 0) {
      if (isBtnDisabled) {
        setIsBtnDisabled(false) // if true then make button false;
      }
    } else {
      setIsBtnDisabled(true)
    }
  }

  // console.log(surveyAnswers, " fullStudyData.surveyQuestionData ", fullStudyData)

  return (
    <div className='pf_cstm_screener_main pf_survey_ques'>

      <div className='topBarCon'>
        {updatedQuestionsRef.current?.map((item, index) =>
          <div key={"topBar" + index} className={(index === currentQuestionTab) ? "topBarQuestionActive" : 'topBarQuestion'} />
        )}
      </div>

      <div className='BoxCon pf_screener_ques'>
        {updatedQuestionsRef.current?.map((data, indx) =>
          // checkIsHide(data) ? null :
          currentQuestionTab === indx &&
          <>
            <div className='participant_question_image_Con'>
              {data?.quesMediaImg && <Image src={`${data.quesMediaImg}`} className='participant_question_image' alt="Image" />}
            </div>
            <div className='BoxScreenerQuestion' key={"idxg" + indx}>
              <div className='questionCon'>
                <p className='questionStyle'>{data.question}</p>
              </div>
              <div className={'answerCon ' + (data.questionType)}>
                {data.questionType === "multiple_choices" || data.questionType === "multiple_choice" ?
                  data.options?.map((opt, inx) =>
                    <div className='radioCon1' key={"inxs" + inx}>
                      <input
                        className={surveyAnswers[indx]?.answers?.includes(opt.value) ? 'active' : undefined}
                        type="radio" name={data.question}
                        id={indx + "qesId" + inx} value={opt.value}
                        defaultChecked={surveyAnswers[indx]?.answers?.includes(opt.value) || ""}
                        onChange={(event) => handleGetAnserwer(event, indx, data.questionType)} />
                      <label htmlFor={indx + "qesId" + inx} className='answerTxt'>{opt.value}</label>
                    </div>
                  )
                  : ""
                }
                {data.questionType === "check_boxes" ?
                  data.options?.map((opt, inx) =>
                    <div className='radioCon1' key={"inxs" + inx}>
                      <input
                        className={surveyAnswers[indx]?.answers?.includes(opt.value) ? 'active' : undefined}
                        type="checkbox" name={data.question} id={indx + "qesId" + inx}
                        value={opt.value}
                        checked={surveyAnswers[indx]?.answers?.includes(opt.value) || ""}
                        onChange={(event) => handleGetAnserwer(event, indx, data.questionType, inx)} />
                      <label htmlFor={indx + "qesId" + inx} className='answerTxt'>{opt.value}</label>
                    </div>
                  )
                  : ""
                }
                {data.questionType === "text_box" ?
                  // data.options?.map((opt, inx) =>
                  <div className='radioCon1' key={"inxs" + indx}>
                    <input type="text" name={data.question} id={indx + "qesId" + indx}
                      defaultValue={surveyAnswers[indx]?.answers[0] || ""}
                      onChange={(event) => handleGetAnserwer(event, indx, data.questionType)} />
                  </div>
                  // )
                  : ""
                }
                {data.questionType === "matrix_rating_scale" ?
                  <div className='rows_colmns_screnr_pf'>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          {data.columns?.map((col, colInd) =>
                            <th key={"colInd" + colInd}>{col.value}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.rows?.map((opt, inx) =>
                          <tr key={"optS" + inx}>
                            <th>{opt.value}</th>
                            {
                              data.columns?.map((clm, dhm) =>
                                <td key={"clm" + dhm}>
                                  <input
                                    className={surveyAnswers[indx]?.answers?.includes(opt.value + "_" + clm.value) ? 'active' : ""}
                                    type="radio"
                                    name={`matrix-${inx}`}
                                    value={opt.value + "_" + clm.value}
                                    checked={surveyAnswers[indx]?.answers?.includes(opt.value + "_" + clm.value) || ''}
                                    onClick={(event) => handleGetAnserwer(event, indx, data.questionType, inx, data.question, data.forceRanking, data.singleRowRating)}
                                    onChange={e => { }} />
                                </td>
                              )
                            }
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  : ""
                }
              </div>
            </div>
          </>

        )}
      </div>

      <div className='bottomConScreenerQuestion'>
        <button onClick={() => PreviousQuestion()} className="Exitbutton">{"BACK"}</button>
        <button onClick={() => NextQuestion()}
          className={"NextbuttonActive" + (isBtnDisabled === true ? " disabled" : "")}
          disabled={isBtnDisabled}>{"NEXT"}</button>
      </div>
    </div>
  )
}



export default ParticpantSurvey
