import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Axios from 'axios';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var getStudyUrl = process.env.REACT_APP_API_URL + apiVersion + "studies/";

function InviteStudy() {

    const [validstudy, setValidStudy] = useState(false);
    const [fullStudyData, setFullStudyData] = useState("");
    const [errmsg, setErrMsg] = useState('');
    const {studyID} = useParams();

    useEffect(() => {
        const newCookies = new Cookies();
        Axios.get(
            getStudyUrl + studyID,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${newCookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    setFullStudyData(response.data.data);
                    setValidStudy(true);
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    setErrMsg("Invalid Study ID");
                }else {
                    setErrMsg(error.response.data.message);
                }
            });
    }, []);

    return (
        
        <Container>
            <div className='cstm_main_inv'>
                {validstudy?
                    <div className='participant_fac_main_cstm'>
                        <p className='welcomeText'>Welcome to Study {fullStudyData.projectTitle}</p>
                        <p className='welcomeDesp'>Thanks for clicking. nPlicit™ is an online survey methodology that uses timed responses, so you’ll need to be able to focus without distraction for the next 15-20 mins.</p>
                        <p className='welcomeDesp'>You need to be invited to this study</p>
                    </div>
                    :
                    <div className='cstm_loader'>
                        <p className='welcomeDesp'>{errmsg}</p>
                    </div>
                }
            </div>
        </Container>
        
    )
}

export default InviteStudy