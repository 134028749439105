
import React, { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { Stack } from 'react-bootstrap';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
  apiVersion = "/api/v1/";
}
var downloadSurveyResult = process.env.REACT_APP_API_URL + apiVersion + "export/survey-results/";


export const data = [
  ["Task", "stats"],
  ["Incomplete", 11],
  ["Completed", 2],
  ["Not Started", 2],
]
export const options = {
  'width': '80%',
  'height': 275,
  'chartArea': { 'width': '100%', 'height': '80%' },
  // 'legend': { position: 'left', alignment: 'center' },
  pieSliceText: 'value',
  legend: {
    position: 'labeled',
    labeledValueText: 'both',
    textStyle: {
      color: '#1D1C1D',
      fontSize: 10
    }
  },
  colors: ['#E8E9F3', '#ACB9FF', '#4273F0'],
  colors: ['#4273F0', '#ACB9FF', 'darkblue'],
  sliceVisibilityThreshold: 0.00005

}
export const data1 = [
  ['Screener Question', 'links'],
  ['0', 200],
  ['1', 180],
  ['2', 170],
  ['3', 160],
  ['4', 80],
  ['5', 80],
  ['6', 70],
  ['7', 60],
  ['8', 50],
]
export const options1 = {
  // title: 'House Prices vs Size',
  // hAxis: { title: 'Square Meters' },
  // vAxis: { title: 'Price in Millions' },
  'legend': { position: 'none' },
  vAxis: {
    viewWindow: {
      min: 0 // This sets the minimum value of the y-axis to 0
    },
    title: 'Number Of Participants'
  },
  hAxis: { title: 'Survey Question' }
}

function ServeyResult({ surveyData, stdyId, setErrMsg, setShowToast, surveyDataUpdated }) {

  // const calculateSharedLinks = () => {
  //   var sharedLinks = 0;
  //   for (var i = 1; i < surveyData?.rightChartData?.length; i++) {
  //     sharedLinks += surveyData?.rightChartData[i][1];
  //   }
  //   return sharedLinks
  // }

  const SurveyResults = async () => {
    let newCookies = new Cookies();
    await Axios.get(
      downloadSurveyResult + stdyId,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${newCookies.get("neurojwt")}`
        }
      }).then(response => {
        const csvContent = response?.data;
        const downloadUrl = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }).catch(error => {
        setErrMsg(error?.response?.data?.message)
        setShowToast(true)
      })
  }


  return (
    // <>
    //   {surveyDataUpdated ?
        <>
          <div className='resultStudyInfoDataCon'>
            <div className='resultStudyStatsCon'>
              <div className='resultScreenerCompletionStatus'>
                <p className='ScreenerCompletionTxtStyResult'>Survey Completion Status</p>
                <div className='resultScreenerCompletionGraphStatsCon'>
                {surveyDataUpdated ?
                ( surveyData?.leftChartData[1][1] === 0 && surveyData?.leftChartData[2][1] === 0 && surveyData?.leftChartData[3][1] === 0 ) ?
                  <div className='noGraphTextCon'>
                    <p className='ScreenerCompletionTxtStyResult'>No link generated against this study</p>
                  </div>
                  :
                  <Chart
                    chartType="PieChart"
                    data={surveyData?.leftChartData}
                    options={options}
                    width={"100%"}
                    height={"400px"}
                  />
                  : null}
                </div>
                {/* {surveyData?.leftChartData?.slice(1)?.map((item, index) => (
                <p key={index} className='StaticTextSty'>{item[0]} : {item[1]}</p>
              ))} */}
              </div>
            </div>
            <div className='resultStudyAttributeCon'>
              <div className='resultScreenerDataLineGraphCon'>
                <p className='ScreenerCompletionTxtStyResult'>Survey Drop-Off</p>
              {surveyDataUpdated ?  <p className='linkedSharedTxtStyResult'>{`${surveyData?.rightChartData[1][1]}${" Total Participants Started Survey"}`}</p> : null }
                {/* <Chart
              chartType="Line"
              width="100%"
              height="85%"
              data={surveyLineGraph}
              options={options1}
            /> */}
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="85%"
                  data={surveyData?.rightChartData}
                  options={options1}
                />
              </div>
            </div>
          </div>
          <div className="resultUpdateScreenerBtnCon">
            <Stack direction="horizontal" gap={3}>
              {/* <button className="UpdateScreenerBtnStyResult">
            {"UPDATE SCREENER"}
          </button> */}
              <button onClick={SurveyResults} className="RawResultsStudyBtnSty screenerResults">
                SURVEY RESULTS
              </button>
            </Stack>
          </div>

        </>
      //   :
      //   <div className='cstm_loader'>
      //     <div className='lds-dual-ring'></div>
      //   </div>
      // }
    // </>
  )
}
export default ServeyResult

