import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';

export default function Layout({children}) {

    const location = useLocation();
    var pgName = "pg"+location.pathname.replaceAll("/","_");
    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
            >
            <Container className={'main_cstm '+ pgName} >
                <div className="layout_cstm">{children}</div>
            </Container>
        </ThemeProvider>
        
    )
}