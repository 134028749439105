import { useState } from 'react'
import { Col, Form, Row, Stack, Image } from 'react-bootstrap';
import study_media from '../../assets/study_media.png';
import cross from '../../assets/cross.png';
import modalCross from '../../assets/modalCross.png';
import Cookies from 'universal-cookie';
import Axios from 'axios';

var apiVersion = process.env.REACT_APP_API_VERSION;
if (apiVersion === undefined || apiVersion === "") {
    apiVersion = "/api/v1/";
}
var appImgUrl = process.env.REACT_APP_API_URL + apiVersion + "upload/file";
var delteImgUrl = process.env.REACT_APP_API_URL + apiVersion + "file/delete";
var domainUrl = process.env.REACT_APP_API_URL;
var appMediaUrl = process.env.REACT_APP_API_URL + apiVersion + "media/studies";

export default function AddMedia({ studyData, setStudyData, setErrMsg, setShowToast, tabName, advConfigMin }) {

    const cookies = new Cookies();

    const [showAdvanceStudyModal, setShowAdvanceStudyModal] = useState(false)
    const [studyTime, setStudyTime] = useState({
        PrimeAttribute: 0.5,
        TimeBetweenAttributes: 0.5,
        Target: 0.5,
        DelayBeforeNextPairing: 0.5,
        NumberOfExposures: 6
    })

    // console.log("studyData.mediaBrand", studyData.mediaBrand);


    const updateMediaData = async () => {
        var mediaData = {
            mediaTextEnable: !studyData.mediaTextEnable,
            mediaBrand: studyData.mediaBrand,
            mediaAttr: studyData.mediaAttr,
            mediaImgEnable: studyData.mediaImgEnable,
            steper: tabName ? tabName : "",
        }
        setShowToast(false);
        var locStorageID = localStorage.getItem("createStudyId");
        if (locStorageID) {
            await Axios.post(
                appMediaUrl + "/" + locStorageID,
                mediaData,
                {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${cookies.get("neurojwt")}`
                    }
                }).then(response => {
                    if (response && response.data && response.data.status) {
                    } else {
                        setErrMsg(response.data.message);
                        setShowToast(true);
                    }
                }).catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/login"
                    }
                    setErrMsg(error.response.data.message);
                    setShowToast(true);
                });
        } else {
            setErrMsg("Study id not available create new study");
            setShowToast(true);
        }
    }

    const handleImage = async (event, number) => {
        if (localStorage.getItem("createStudyId")) {
            await Axios.post(
                appImgUrl,
                {
                    file: event.target.files[0]
                },
                {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${cookies.get("neurojwt")}`
                    }
                }).then(response => {
                    if (response && response.data && response.data.status) {
                        if (event.target.name === "mediaBrand_img") {
                            var newStudyMediaBrand = studyData.mediaBrand;
                            newStudyMediaBrand[number].image = response.data.filePath;
                            setStudyData({ ...studyData, mediaBrand: newStudyMediaBrand });
                            updateMediaData()
                        } else {
                            var newStudyMediaAttr = studyData.mediaAttr;
                            newStudyMediaAttr[number].image = response.data.filePath;
                            setStudyData({ ...studyData, mediaAttr: newStudyMediaAttr });
                            updateMediaData();
                        }
                    } else {
                        setErrMsg(response.data.message);
                        setShowToast(true);
                    }
                }).catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/login";
                    }
                    setErrMsg(error.response.data.message);
                    setShowToast(true);
                });
        } else {
            setErrMsg("Study id not available create new study");
            setShowToast(true);
        }
    }

    const handleRemoveImage = async (event, imagePath, index) => {
        await Axios.post(
            delteImgUrl,
            { filePath: imagePath },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${cookies.get("neurojwt")}`
                }
            }).then(response => {
                if (response && response.data && response.data.status) {
                    if (event.target.name === "mediaBrand_img") {
                        var newStudyMediaBrand = studyData.mediaBrand;
                        newStudyMediaBrand[index].image = "";
                        setStudyData({ ...studyData, mediaBrand: newStudyMediaBrand })
                         // updateMediaData()
                    } else {
                        var newStudyMediaAttr = studyData.mediaAttr;
                        newStudyMediaAttr[index].image = "";
                        setStudyData({ ...studyData, mediaAttr: newStudyMediaAttr });
                        updateMediaData()
                    }
                } else {
                    setErrMsg(response.data.message);
                    setShowToast(true);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/login";
                }
                setErrMsg(error.response.data.message);
                setShowToast(true);
            })
    }

    const handleBtnChange = (event) => {
        if (event.target.name === "mediaTextEnable") {
            setStudyData({ ...studyData, [event.target.name]: !event.target.checked, mediaBrandv1: "", mediaBrandv2: "" });
        } else {
            setStudyData({
                ...studyData, [event.target.name]: event.target.checked,
                mediaAttrv1: "", mediaAttrv2: "", mediaAttrv3: "", mediaAttrv4: "",
                mediaAttrv5: "", mediaAttrv6: ""
            });
        }
    }

    const handleMediaText = (event, index) => {
        if (event.target.name === "mediaText") {
            var newStudyMediaBrand = studyData.mediaBrand;
            newStudyMediaBrand[index].text = event.target.value;
            setStudyData({ ...studyData, mediaBrand: newStudyMediaBrand });
        } else {
            var newStudyMediaAttr = studyData.mediaAttr;
            newStudyMediaAttr[index].text = event.target.value;
            setStudyData({ ...studyData, mediaAttr: newStudyMediaAttr });
        }
    }

    const handleAddChoice = (value) => {
        if (value === "addMedia") {
            var newStudyMediaBrand = studyData.mediaBrand;
            newStudyMediaBrand.push({ text: "", image: "" });
            setStudyData({ ...studyData, mediaBrand: newStudyMediaBrand });
        } else {
            var newStudyMediaAttr = studyData.mediaAttr;
            newStudyMediaAttr.push({ text: "", image: "" });
            setStudyData({ ...studyData, mediaAttr: newStudyMediaAttr });
        }
    }

    const handleRemoveBox = (event, index) => {
        if (event.target.name === "mediaBrand_img") {
            var newStudyMediaBrand = studyData.mediaBrand;
            if (newStudyMediaBrand.length > 2) {
                newStudyMediaBrand.splice(index, 1);
                setStudyData({ ...studyData, mediaBrand: newStudyMediaBrand });
            } else {
                setErrMsg("Minimum remove limit reached");
                setShowToast(true);
            }
        } else {
            var newStudyMediaAttr = studyData.mediaAttr;
            if (newStudyMediaAttr.length > 6) {
                newStudyMediaAttr.splice(index, 1);
                setStudyData({ ...studyData, mediaAttr: newStudyMediaAttr });
            } else {
                setErrMsg("Minimum remove limit reached");
                setShowToast(true);
            }
        }
    }

    const crossPress = () => {
        setShowAdvanceStudyModal(false)
        let TotalPairings = studyData?.mediaBrand?.length * studyData?.mediaAttr?.length
        let Exposure = studyTime.NumberOfExposures
        let TotalScreens = TotalPairings * Exposure
        let Screens = Number(studyTime.PrimeAttribute) + Number(studyTime.TimeBetweenAttributes) + Number(studyTime.Target) + Number(studyTime.DelayBeforeNextPairing)
        let Total = (TotalScreens * Screens) / 60
        advConfigMin(Total)
    }

    return (
        <div className='cstm_study_tab_media'>
            <div className='study_cstm_media_inner'>
                <div className='study_md_titl'>
                    {/* <h1 className='study_media_titl_p2'>
                        Media and Primed Brand
                    </h1> */}
                    <div className='Advance_config_btn_Con'>
                        <h1 className='study_media_titl_p2'>Media and Primed Brand</h1>
                        {/* <button className="advance_config_btn" type="btn"
                            onClick={() => setShowAdvanceStudyModal(true)}
                        >Advacnce config</button> */}
                    </div>
                    <p className='stdy_media_detail_p2'>Define your brands or upload media. Then type or upload prime objects.</p>
                </div>
                <div className='stdy_media_lr_cstm_p2 hstack gap-3 minBreakpoint-xxs'>
                    <div className='stdy_mdia_lft_p2_innr'>
                        <Stack direction="horizontal" gap={3}>
                            <div className='cstm_witch_std_mdia'>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={!studyData.mediaTextEnable}
                                    name="mediaTextEnable"
                                    // label={!studyData.mediaTextEnable ? "Image" : "Text"}
                                    label={"Media"}
                                    onChange={handleBtnChange}
                                />
                            </div>
                            <div className={'media_cstm_imges_study ' + (studyData.mediaBrand.length > 2 ? " active " : "")}>
                                <p className='media_attr_title'>Choose Your Brand Names or Upload Media</p>
                                {!studyData.mediaTextEnable === false ?
                                    <Row className="mb-3 cstm_media_brnd_words">

                                        {studyData.mediaBrand?.map((mediaData, indx) =>
                                            <div className='brnd_txt_wrpr'>
                                                {indx >= 2 ?
                                                    <div className='cross_img_std_med'>
                                                        <Image src={cross} name="mediaBrand_img" thumbnail={true} className='cross_sign_std'
                                                            onClick={(event) => handleRemoveBox(event, indx)}
                                                        />
                                                    </div>
                                                    : ""}
                                                <Form.Group as={Col} key={"sps_" + indx}>
                                                    <Form.Control type="text" name="mediaText" placeholder={"Brand " + (indx + 1)} value={mediaData.text}
                                                        onChange={event => handleMediaText(event, indx)}
                                                    />
                                                </Form.Group>
                                            </div>
                                        )}
                                    </Row>
                                    :
                                    <Stack direction="horizontal" gap={3} className="cstm_media_gpt">
                                        {studyData.mediaBrand?.map((data, index) =>
                                            <div className='stdy_md_img' ind={index} key={"sp1_" + index}>
                                                {data.image ?
                                                    <div className='arr_img_media_brnd'>
                                                        <div className='cross_img_std_med'>
                                                            <Image src={cross} name="mediaBrand_img" thumbnail={true} className='cross_sign_std'
                                                                onClick={(event) => handleRemoveImage(event, data.image, index)}
                                                            />
                                                        </div>
                                                        <img src={data.image} className="std_med_img_brnd" alt='med_image_1' />
                                                    </div> :
                                                    <div className="cstm_std_img_wrpr">
                                                        {index >= 2 ?
                                                            <div className='cross_img_std_med'>
                                                                <Image src={cross} name="mediaBrand_img" thumbnail={true} className='cross_sign_std'
                                                                    onClick={(event) => handleRemoveBox(event, index)}
                                                                />
                                                            </div>
                                                            : ""}
                                                        <p>Brand {index + 1}</p>
                                                        <div className={'media_study_cstm_img ' + (data.image ? "active" : "")}>
                                                            <label htmlFor={'std_media_v' + index}>
                                                                <Image src={study_media} className="study_med_img" />
                                                            </label>
                                                            <input type="file" accept="image/*" id={'std_media_v' + index} name="mediaBrand_img"
                                                                onChange={(event) => handleImage(event, index)}
                                                                className='study_med_file_v1 hide_me' />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                    </Stack>

                                }
                                <div className='add_more_btn_media'>
                                    <button className="add_screener_bx_option" type="btn" onClick={() => handleAddChoice("addMedia")} >+ Add an additional object</button>
                                </div>
                            </div>
                        </Stack>
                    </div>
                    <div className='stdy_mdia_rgt_p2_innr'>
                        <Stack direction="horizontal" gap={3}>
                            <div className='cstm_witch_std_mdia'>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch_sec"
                                    // label="Images"
                                    // label={studyData.mediaImgEnable ? "Image" : "Text"}
                                    label={"Media"}
                                    name="mediaImgEnable"
                                    checked={studyData.mediaImgEnable}
                                    onChange={handleBtnChange}
                                />
                            </div>
                            <div className={'media_cstm_attr_study ' + (studyData.mediaAttr.length > 6 ? " active " : "")}>
                                <p className='media_attr_title'>Choose Your Primes</p>
                                {studyData.mediaImgEnable === false ?
                                    <div className='cstm_media_rgt'>
                                        <Row className="mb-3">
                                            {studyData.mediaAttr?.map((attrData, idgx) =>
                                                <div className='mediaAtt_wrpr'>
                                                    {idgx >= 6 ?
                                                        <div className='cross_img_std_med'>
                                                            <Image src={cross} name="mediaAttr" thumbnail={true} className='cross_sign_std'
                                                                onClick={(event) => handleRemoveBox(event, idgx)}
                                                            />
                                                        </div>
                                                        : ""}
                                                    <Form.Group as={Col} key={idgx}>
                                                        <Form.Control type="text" name="mediaAttr" placeholder={"Prime " + (idgx + 1)} value={attrData.text}
                                                            onChange={event => handleMediaText(event, idgx)} />
                                                    </Form.Group>
                                                </div>
                                            )}
                                        </Row>
                                    </div>
                                    :
                                    <div className='media_wrd_attr_rght'>
                                        <Row>
                                            {studyData.mediaAttr?.map((item, ixd) =>
                                                <Col key={"sgs_" + ixd}>
                                                    <div className='stdy_md_img'>
                                                        {item.image ?
                                                            <div className='arr_img_media'>
                                                                <div className='cross_img_std_med'>
                                                                    <Image src={cross} thumbnail={true} name="mediaAttr_img" className='cross_sign_std'
                                                                        onClick={(event) => handleRemoveImage(event, item.image, ixd)}
                                                                    />
                                                                </div>
                                                                <img src={item.image} className="std_med_img" alt='med_attr_image_1' />
                                                            </div> :
                                                            <div className='attr_wrp'>
                                                                {ixd >= 6 ?
                                                                    <div className='cross_img_std_med'>
                                                                        <Image src={cross} name="mediaAttr" thumbnail={true} className='cross_sign_std'
                                                                            onClick={(event) => handleRemoveBox(event, ixd)}
                                                                        />
                                                                    </div>
                                                                    : ""}
                                                                <div className={'media_study_cstm_img ' + (item.image ? "active" : "")}>
                                                                    <label htmlFor={'media_attr_v' + ixd}>
                                                                        <Image src={study_media} className="study_med_img" />
                                                                    </label>
                                                                    <input type="file" accept="image/*" id={'media_attr_v' + ixd}
                                                                        name="mediaAttr_img"
                                                                        onChange={(event) => handleImage(event, ixd)}
                                                                        className='study_med_file_v1 hide_me' />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                }
                                <div className='add_more_btn_media'>
                                    <button className="add_screener_bx_option" type="btn" onClick={() => handleAddChoice("addAttribute")}>+ Add an additional prime</button>
                                </div>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>

            {showAdvanceStudyModal ?
                <div className='MediaPopUpMainCon'>
                    <div className='MediaModalCon'>
                        <div onClick={() => crossPress()} className='modalCrossIconCon'>
                            <Image src={modalCross} className="modalCrossIconSty" alt="logo" />
                        </div>
                        <p className='mediaModalTitleSty'>Advance Study Parameters</p>
                        <div className='MediaInputFieldModalCon'>
                            <p className='mediaModalInputFieldTitleSty'>Prime/Attribute</p>
                            <input type="text" className="mediaModalInputFieldSty"
                                value={studyTime.PrimeAttribute} onChange={(event) => {
                                    setStudyTime({ ...studyTime, PrimeAttribute: event.target.value })
                                }} />
                        </div>
                        <div className='MediaInputFieldModalCon'>
                            <p className='mediaModalInputFieldTitleSty'>Time between Attributes</p>
                            <input type="text" className="mediaModalInputFieldSty"
                                value={studyTime.TimeBetweenAttributes} onChange={(event) => {
                                    setStudyTime({ ...studyTime, TimeBetweenAttributes: event.target.value })
                                }} />
                        </div>
                        <div className='MediaInputFieldModalCon'>
                            <p className='mediaModalInputFieldTitleSty'>Target</p>
                            <input type="text" className="mediaModalInputFieldSty"
                                value={studyTime.Target} onChange={(event) => {
                                    setStudyTime({ ...studyTime, Target: event.target.value })
                                }} />
                        </div>
                        <div className='MediaInputFieldModalCon'>
                            <p className='mediaModalInputFieldTitleSty'>Delay before next Pairing</p>
                            <input type="text" className="mediaModalInputFieldSty"
                                value={studyTime.DelayBeforeNextPairing} onChange={(event) => {
                                    setStudyTime({ ...studyTime, DelayBeforeNextPairing: event.target.value })
                                }} />
                        </div>
                        <div className='MediaInputFieldModalCon'>
                            <p className='mediaModalInputFieldTitleSty'>Number of Exposures</p>
                            <input type="text" className="mediaModalInputFieldSty"
                                value={studyTime.NumberOfExposures} onChange={(event) => {
                                    setStudyTime({ ...studyTime, NumberOfExposures: event.target.value })
                                }} />
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}