import React, { useState } from 'react';

const ImageLoader = ({ src, className, alt, onImageLoad }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
    // Execute the provided onImageLoad callback if it exists
    if (onImageLoad) {
      onImageLoad();
    }
  };

  return (
    <img
      src={src}
      className={className}
      alt={alt}
      onLoad={handleImageLoad}
      style={{ display: loaded ? 'block' : 'none' }} // Hide the image until it's loaded
    />
  );
};
export default ImageLoader;